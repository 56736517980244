import { round } from 'lodash-es'

import { pagesPerSecond } from '#views/rdata/constants'

import { ConfigFileData, MemoryLayout, RdataMeasurement, RdataParameter, SchemaValueOption } from '#types'

function getSlotNumber(slot: number, sensor: string, schema: any) {
  const slotSchema = schema?.definitions?.measurement_slot_id

  if (!['ECG', 'BIOZ'].includes(sensor)) {
    return slot
  }

  const slotValues = slotSchema?.properties?.value?.oneOf?.filter(
    (item: SchemaValueOption) => !item.rules || item.rules.includes(sensor),
  )

  return slotValues[0]?.const
}

export function getFieldItems(sensor: string, schema: any, field: string): any[] {
  const dataSchema = schema?.definitions?.[field]

  const dataValues: SchemaValueOption[] = dataSchema?.properties?.value?.anyOf || dataSchema?.properties?.value?.oneOf

  return (
    dataValues
      ?.filter((item: SchemaValueOption) => !item.rules || item.rules.includes(sensor))
      ?.map((item: SchemaValueOption) => ({
        value: item.const,
        text: item.title.replace(`${sensor}: `, ''),
        title: item.title,
        type: sensor,
      })) || []
  )
}

export function getFieldValue(slot: number, sensor: string, schema: any, config: ConfigFileData, field: string): any {
  const dataSchema = schema?.definitions?.[field]

  const slotSchema = schema?.definitions?.measurement_slot_id

  const typeSchema = schema?.definitions?.measurement_channel_description_id

  const slotNumber = getSlotNumber(slot, sensor, schema)

  const measurementData: RdataMeasurement | undefined =
    sensor === 'System'
      ? findMatchingMeasurement(slotNumber, sensor, slotSchema, dataSchema, config)
      : findMatchingMeasurement(slotNumber, sensor, slotSchema, typeSchema, config)

  if (measurementData) {
    const fieldData = measurementData.parameters.find(
      (p: RdataParameter) =>
        p.length === dataSchema?.properties?.length.default &&
        p.class_id === dataSchema?.properties?.class_id.default &&
        p.param_id === dataSchema?.properties?.param_id.default,
    )

    const schemaValues = dataSchema?.properties?.value?.anyOf || dataSchema?.properties?.value?.oneOf

    if (fieldData) {
      if (dataSchema.properties.value.type === 'integer' && !schemaValues) {
        return fieldData.value
      } else if (dataSchema.properties.value.type === 'integer' && schemaValues) {
        const option = schemaValues.find((o: SchemaValueOption) => o.const === fieldData.value)

        if (option) {
          return option.const
        }
      }
    } else if (
      dataSchema?.properties &&
      field !== 'measurement_channel_description_id' &&
      getFieldValue(slot, sensor, schema, config, 'measurement_channel_description_id') !== ''
    ) {
      // Set default field data if channel is set to enabled
      const value =
        field !== 'measurement_data_interval'
          ? dataSchema?.properties?.value?.default
          : schemaValues.findLast((o: SchemaValueOption, i: number) => i === 0 || o.rules?.includes(sensor))?.const

      setFieldValue(slot, sensor, schema, config, field, value)
    }
  }
  return ''
}

export function setFieldValue(slot: number, sensor: string, schema: any, config: any, field: string, value: any) {
  if (value !== null) {
    let prevValue = null

    const dataSchema = schema?.definitions?.[field]

    const slotSchema = schema?.definitions?.measurement_slot_id

    const typeSchema = schema?.definitions?.measurement_channel_description_id

    const slotNumber = getSlotNumber(slot, sensor, schema)

    let measurementDatas = config?.data_collections[0]?.measurements?.filter((m: RdataMeasurement) => {
      // Check if the measurement data has a parameter matching the slot schema
      const hasParamMatchingSlotSchema: boolean =
        sensor === 'System' ||
        m.parameters.some(
          (p: RdataParameter) =>
            p.length === slotSchema?.properties?.length.default &&
            p.class_id === slotSchema?.properties?.class_id.default &&
            p.param_id === slotSchema?.properties?.param_id.default &&
            p.value === slotNumber,
        )

      // Check if the measurement data has a parameter matching the type schema
      const hasValidParamMatchingTypeSchema: boolean =
        !m.parameters.some(
          (p: RdataParameter) =>
            p.length === typeSchema?.properties?.length.default &&
            p.class_id === typeSchema?.properties?.class_id.default &&
            p.param_id === typeSchema?.properties?.param_id.default,
        ) ||
        m.parameters.some(
          (p: RdataParameter) =>
            p.length === typeSchema?.properties?.length.default &&
            p.class_id === typeSchema?.properties?.class_id.default &&
            p.param_id === typeSchema?.properties?.param_id.default &&
            typeSchema?.properties?.value?.oneOf
              .filter((i: SchemaValueOption) => i.title.startsWith(`${sensor}: `))
              .map((i: SchemaValueOption) => i.const)
              .includes(p.value),
        )

      return hasParamMatchingSlotSchema && hasValidParamMatchingTypeSchema
    })

    if (!measurementDatas.length) {
      measurementDatas = [
        {
          name: sensor + ': Measurement Slot ' + slotNumber,
          parameters: [
            {
              name: [slotSchema?.properties?.value?.title, slotNumber].join(' - '),
              length: slotSchema?.properties?.length?.default,
              class_id: slotSchema?.properties?.class_id?.default,
              param_id: slotSchema?.properties?.param_id?.default,
              value: slotNumber,
            },
          ],
        },
      ]

      config.data_collections[0].measurements.push(...measurementDatas)
    }

    for (const measurementData of measurementDatas) {
      let fieldData = measurementData.parameters.find(
        (p: RdataParameter) =>
          p.length === dataSchema?.properties?.length.default &&
          p.class_id === dataSchema?.properties?.class_id.default &&
          p.param_id === dataSchema?.properties?.param_id.default,
      )

      if (!fieldData) {
        fieldData = {
          length: dataSchema?.properties?.length?.default,
          class_id: dataSchema?.properties?.class_id?.default,
          param_id: dataSchema?.properties?.param_id?.default,
        }

        measurementData.parameters.push(fieldData)
      }

      prevValue = fieldData.value

      if (field === 'ppg_led_current_steps') {
        const range = ((getFieldValue(slotNumber, sensor, schema, config, 'ppg_led_current_range') ?? 3) + 1) * 32

        fieldData.value = isNaN(value) ? 0 : Math.min(255, Math.max(0, Math.round(+value / (range / 256))))

        fieldData.name = [
          dataSchema.properties.value.title,
          fieldData.value + ' (' + fieldData.value * (range / 256) + 'mA' + ')',
        ].join(' - ')
      } else if (
        dataSchema?.properties?.value?.minimum !== undefined ||
        dataSchema?.properties?.value?.maximum !== undefined
      ) {
        fieldData.value = isNaN(value)
          ? 0
          : Math.min(
              dataSchema?.properties?.value?.maximum || 100,
              Math.max(dataSchema?.properties?.value?.minimum || 0, parseInt(value)),
            )

        fieldData.name = dataSchema?.properties?.value?.title + ' ' + value
      } else {
        fieldData.value = value

        const schemaValues = dataSchema?.properties?.value?.anyOf || dataSchema?.properties?.value?.oneOf
        const title = schemaValues.find((o: SchemaValueOption) => o.const === value)?.title ?? value

        fieldData.name = [dataSchema?.properties?.value?.title, title].join(' - ')
      }
    }

    if (field === 'ppg_led_settling_time') {
      if (getFieldValue(slotNumber, 'PPG', schema, config, 'ppg_pd_settling_time') < value) {
        setFieldValue(slotNumber, 'PPG', schema, config, 'ppg_pd_settling_time', value)
      }
    } else if (field === 'ppg_pd_settling_time') {
      if (getFieldValue(slotNumber, 'PPG', schema, config, 'ppg_led_settling_time') > value) {
        setFieldValue(slotNumber, 'PPG', schema, config, 'ppg_led_settling_time', value)
      }
    } else if (field === 'ppg_led_current_range') {
      if (getFieldValue(slotNumber, 'PPG', schema, config, 'ppg_led_current_steps') !== '') {
        setFieldValue(
          slotNumber,
          'PPG',
          schema,
          config,
          'ppg_led_current_steps',

          getFieldValue(slotNumber, 'PPG', schema, config, 'ppg_led_current_steps') *
            ((((prevValue || 0) + 1) * 32) / 256),
        )
      }
    }

    config.data_collections = [...config.data_collections]
  }

  return true
}

export function getChannelEnabled(slot: number, sensor: string, schema: any, config: ConfigFileData, field: string) {
  const dataSchema = schema.definitions[field]

  const slotSchema = schema.definitions.measurement_slot_id

  const measurementData = findMatchingMeasurement(slot, sensor, slotSchema, dataSchema, config)

  return !!measurementData
}

export function setChannelEnabled(
  slot: number,
  sensor: string,
  schema: any,
  config: ConfigFileData,
  enabled: boolean | null,
  field?: string,
) {
  const dataSchema = field ? schema?.definitions?.[field] : schema?.definitions['measurement_channel_description_id']

  const slotSchema = schema?.definitions?.measurement_slot_id

  const slotNumber = getSlotNumber(slot, sensor, schema)

  if (!enabled) {
    config.data_collections[0].measurements = config?.data_collections[0]?.measurements?.filter(
      (m: RdataMeasurement) =>
        !(
          (sensor === 'System' ||
            !!m.parameters.find(
              (p: RdataParameter) =>
                p.length === slotSchema?.properties?.length?.default &&
                p.class_id === slotSchema?.properties?.class_id?.default &&
                p.param_id === slotSchema?.properties?.param_id?.default &&
                p.value === slotNumber,
            )) &&
          !!m.parameters.find(
            (p: RdataParameter) =>
              p.length === dataSchema?.properties?.length.default &&
              p.class_id === dataSchema?.properties?.class_id.default &&
              p.param_id === dataSchema?.properties?.param_id.default &&
              (!dataSchema?.properties?.value?.oneOf ||
                dataSchema?.properties?.value?.oneOf
                  .filter((i: SchemaValueOption) => i.title.startsWith(`${sensor}: `))
                  .map((i: SchemaValueOption) => i.const)
                  .includes(p.value)),
          )
        ),
    )
  } else if (!dataSchema?.properties?.value?.oneOf) {
    const measurementData = {
      name: [
        sensor + ': Measurement Slot ' + slotNumber,
        dataSchema?.properties?.value?.title.replace(`${sensor}: `, ''),
      ].join(' - '),
      parameters: [
        {
          name: dataSchema?.properties?.value?.title,
          value: dataSchema?.properties?.value.default,
          length: dataSchema?.properties?.length.default,
          class_id: dataSchema?.properties?.class_id.default,
          param_id: dataSchema?.properties?.param_id.default,
        },
      ],
    }

    config.data_collections[0].measurements.push(measurementData)
  } else {
    for (const value of (dataSchema?.properties?.value?.oneOf || []).filter((i: SchemaValueOption) =>
      i.title.startsWith(sensor),
    )) {
      const measurementData: RdataMeasurement = {
        name: [sensor + ': Measurement Slot ' + slotNumber, value.title.replace(`${sensor}: `, '')].join(' - '),
        parameters: [
          {
            name: slotSchema?.properties?.value?.title,
            length: slotSchema?.properties?.length.default,
            class_id: slotSchema?.properties?.class_id.default,
            param_id: slotSchema?.properties?.param_id.default,
            value: slotNumber,
          },
          {
            name: dataSchema?.properties?.value?.title,
            length: dataSchema?.properties?.length?.default,
            class_id: dataSchema?.properties?.class_id?.default,
            param_id: dataSchema?.properties?.param_id?.default,
            value: value.const,
          },
        ],
      }

      config.data_collections[0].measurements.push(measurementData)
    }
  }
}

export function getMonitoringEnabled(
  sensor: string,
  schema: any,
  config: ConfigFileData,
  field: string,
  value?: number,
) {
  const dataSchema = schema.definitions[field]

  const measurementData = config.data_collections[0].measurements.find(
    (m: any) =>
      !!m.parameters.find(
        (p: any) =>
          p.length === dataSchema?.properties?.length.default &&
          p.class_id === dataSchema?.properties?.class_id.default &&
          p.param_id === dataSchema?.properties?.param_id.default,
      ),
  )

  const parameterData = measurementData?.parameters?.find(
    (p: any) =>
      p.length === dataSchema?.properties?.length.default &&
      p.class_id === dataSchema?.properties?.class_id.default &&
      p.param_id === dataSchema?.properties?.param_id.default,
  )

  return sensor === 'PPG'
    ? ((parameterData?.value || 0) | value!) === (parameterData?.value || 0)
    : parameterData?.value || 0
}

export function setMonitoringEnabled(
  sensor: string,
  schema: any,
  config: ConfigFileData,
  field: string,
  value: number,
) {
  const dataSchema = schema.definitions[field]

  let measurementData = config.data_collections[0].measurements.find(
    (m: any) =>
      !!m.parameters.find(
        (p: any) =>
          p.length === dataSchema?.properties?.length.default &&
          p.class_id === dataSchema?.properties?.class_id.default &&
          p.param_id === dataSchema?.properties?.param_id.default,
      ),
  )

  if (!measurementData) {
    measurementData = {
      name: 'Rdata monitor mode',
      parameters: [],
    }

    config.data_collections[0].measurements.push(measurementData)
  }

  let parameterData = measurementData?.parameters?.find(
    (p: any) =>
      p.length === dataSchema?.properties?.length.default &&
      p.class_id === dataSchema?.properties?.class_id.default &&
      p.param_id === dataSchema?.properties?.param_id.default,
  )

  if (!parameterData) {
    parameterData = {
      name: dataSchema?.properties?.value?.title,
      length: dataSchema?.properties?.length.default,
      class_id: dataSchema?.properties?.class_id.default,
      param_id: dataSchema?.properties?.param_id.default,
      value: 0,
    }

    measurementData.parameters.push(parameterData)
  }

  parameterData.value = sensor === 'PPG' ? (parameterData?.value || 0) + value : value
}

export function recordingTimeEstimate(schema: any, config: ConfigFileData, memoryLayout?: MemoryLayout) {
  let pagesPerSecondTotal = 0

  if (typeof schema === 'string') {
    schema = JSON.parse(schema)
  }

  const channelSchema = schema.definitions['measurement_channel_description_id']
  const intervalSchema = schema.definitions['measurement_data_interval']

  config.data_collections[0].measurements.forEach((m: RdataMeasurement) => {
    const channelParam = m.parameters.find(
      (p: RdataParameter) =>
        p.length === channelSchema?.properties?.length?.default &&
        p.class_id === channelSchema?.properties?.class_id?.default &&
        p.param_id === channelSchema?.properties?.param_id?.default,
    )

    const intervalParam = m.parameters.find(
      (p: RdataParameter) =>
        p.length === intervalSchema?.properties?.length?.default &&
        p.class_id === intervalSchema?.properties?.class_id?.default &&
        p.param_id === intervalSchema?.properties?.param_id?.default,
    )

    if (channelParam) {
      const valueOption = channelSchema?.properties.value.oneOf.find(
        (o: SchemaValueOption) => o.const === channelParam.value,
      )

      if (intervalParam && valueOption) {
        pagesPerSecondTotal += pagesPerSecond[valueOption.title.split(':')[0]][intervalParam.value]
      }
    }
  })

  return memoryLayout
    ? '~' + `${round((4 * memoryLayout.rdataSize) / (pagesPerSecondTotal || 1) / 60 / 60, 1)}h (${memoryLayout.title})`
    : '~' +
        round((4 * 11264) / (pagesPerSecondTotal || 1) / 60 / 60, 1) +
        'h (customer) / ~' +
        round((4 * 15360) / (pagesPerSecondTotal || 1) / 60 / 60, 1) +
        'h (dev)'
}

export const findMatchingMeasurement = (
  slot: number,
  sensor: string,
  slotSchema: any,
  dataSchema: any,
  config: ConfigFileData,
) => {
  const measurementData = config.data_collections[0].measurements.find((m: RdataMeasurement) => {
    const hasParamMatchingSlotSchema = m.parameters.some((p: RdataParameter) => {
      return (
        p.length === slotSchema?.properties?.length.default &&
        p.class_id === slotSchema?.properties?.class_id.default &&
        p.param_id === slotSchema?.properties?.param_id.default &&
        p.value === slot
      )
    })

    const hasValidParamMatchingDataSchema = m.parameters.some((p: RdataParameter) => {
      return (
        p.length === dataSchema?.properties?.length.default &&
        p.class_id === dataSchema?.properties?.class_id.default &&
        p.param_id === dataSchema?.properties?.param_id.default &&
        (!dataSchema?.properties?.value?.oneOf ||
          dataSchema?.properties?.value?.oneOf
            .filter((i: SchemaValueOption) => i.title.startsWith(`${sensor}: `))
            .map((i: SchemaValueOption) => i.const)
            .includes(p.value))
      )
    })

    return (sensor === 'System' || hasParamMatchingSlotSchema) && hasValidParamMatchingDataSchema
  })

  return measurementData
}
