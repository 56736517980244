<template>
  <!-- eslint-disable vue/v-on-handler-style -->

  <div class="px-4">
    <v-row class="mx-0 px-3" style="display: block">
      <div class="d-flex flex-row align-baseline">
        <span class="text-h6 pt-4 mb-2">Task results</span>

        <v-spacer />

        <span v-if="summary.successful_runs != null" class="text-button">
          Successful runs: {{ summary.successful_runs }} /
          {{ summary.successful_runs + summary.failed_runs }}
        </span>
      </div>
    </v-row>

    <v-row v-if="summary.fw_versions && summary.os_versions" class="mx-0">
      <v-col cols="3" sm="3">
        <v-select v-model="fw" :items="summary.fw_versions" label="Firmware versions" />
      </v-col>

      <v-col cols="3" sm="3">
        <v-select v-model="os" :items="summary.os_versions" label="Operating systems" />
      </v-col>

      <template v-if="osSummary">
        <v-col cols="3" sm="3" class="d-flex align-center justify-end">Nights tested: {{ osSummary.nights }}</v-col>

        <v-col cols="3" sm="3" class="d-flex align-center justify-end">
          Rings tested: {{ osSummary.rings }} / {{ fwCount || 'NA' }}
        </v-col>
      </template>
    </v-row>

    <v-row v-if="osSummaryItems.length" class="mx-0 px-3" style="display: block">
      <div class="d-flex flex-row align-baseline">
        <span class="text-h6 pt-4 mb-2">Events summary</span>

        <v-spacer />

        <span class="text-button">Unique events count: {{ osSummaryItems.length }}</span>
      </div>

      <div v-if="summary.fw_versions.length > 1">
        Select firmware version and event to display the difference percentage

        <v-row class="mx-0 my-2">
          <v-col cols="4" class="pl-0">
            <v-select
              v-model="fwDiff"
              hide-details
              label="Firmware versions"
              :items="summary.fw_versions.filter((fws) => fws !== fw)"
            />
          </v-col>

          <v-col cols="4">
            <v-select
              v-model="eventDiff"
              hide-details
              label="Events"
              item-title="text"
              item-value="value"
              :items="eventsDiff"
            />
          </v-col>

          <v-col cols="4" class="d-flex flex-row align-center">
            <v-spacer />

            <v-btn text="Clear" class="mr-2" :disabled="!fwDiff || !eventDiff" @click="clearDiff()" />

            <v-btn text="Display" color="primary" :disabled="!fwDiff || !eventDiff" @click="displayDiff()" />
          </v-col>
        </v-row>
      </div>

      <v-data-table
        v-model:expanded="expandedRows"
        show-expand
        expand-on-click
        item-value="name"
        hide-default-footer
        class="events mt-2"
        density="comfortable"
        style="width: 100%"
        :headers="osSummaryHeaders"
        :items="osSummaryItems"
        :items-per-page="1000"
        :group-by="[{ key: 'order', order: true }]"
      >
        <template #group-header="{ item, toggleGroup, isGroupOpen }">
          <tr>
            <td class="bg-grey-lighten-5 px-1" :colspan="osSummaryHeaders.length + 1">
              <v-btn
                size="large"
                rounded="0"
                variant="text"
                class="d-flex justify-space-between"
                style="width: 100%"
                @click="toggleGroup(item)"
              >
                {{ summaryEvents[item.value] }}s ({{ item.items.length }})

                <template #append>
                  <v-icon class="ml-2">
                    {{ !isGroupOpen(item) ? 'mdi-plus' : 'mdi-minus' }}
                  </v-icon>
                </template>
              </v-btn>
            </td>
          </tr>
        </template>

        <template #item.count_per_ring="{ item }">
          {{ Math.round(item.count_per_ring * 1000) / 1000 }}
        </template>

        <template #item.count_per_night="{ item }">
          {{ Math.round(item.count_per_night * 1000) / 1000 }}
        </template>

        <template #item.count_per_distinct_ring="{ item }">
          {{ Math.round(item.count_per_distinct_ring * 1000) / 1000 }}
        </template>

        <template #item.data-table-expand="{ item }">
          <v-icon
            v-if="['FAILURE', 'NOTE'].includes(item.event || '')"
            class="ml-2"
            :color="['WARNING', 'INFO'].includes(item.event || '') ? 'grey' : ''"
          >
            {{ getExpandIcon(item) }}
          </v-icon>
        </template>

        <template #expanded-row="{ item }">
          <td class="bg-grey-lighten-4 py-2 pl-16" :colspan="osSummaryHeaders.length + 1">
            <v-data-table
              class="bg-grey-lighten-4 pr-4"
              density="comfortable"
              no-data-text="Top occurrences is not calculated for this firmware and event combination"
              :headers="topOccurrenceHeaders"
              :items="item.top_occurrences"
              item-key="name"
              :items-per-page="5"
              :sort-by="[{ key: 'occurrence_count', order: 'desc' }]"
              @click:row="(_event: any, row: any) => goToDarwinUser(source, row.item.user_uid)"
            />
          </td>
        </template>
      </v-data-table>
    </v-row>

    <v-row v-if="batterySlopeItems.length" class="mx-0 my-4 px-3" style="display: block">
      <div class="d-flex flex-row align-baseline">
        <span class="text-h6 pt-4 mb-2">Battery slope summary</span>

        <v-spacer />

        <span v-if="osSummary" class="text-button">
          Average:
          {{ osSummary.battery_slope_summary ? osSummary.battery_slope_summary.slope_average : '' }}% | No results
          count:
          {{ osSummary.rings - batterySlopeCount }}
        </span>
      </div>

      <v-data-table
        class="pr-4"
        density="comfortable"
        hide-default-footer
        :headers="batterySlopeHeaders"
        :items="batterySlopeItems"
        :items-per-page="1000"
      />
    </v-row>

    <v-row v-if="batterySlopeWorstCases?.length" class="mx-0 my-4 px-3" style="display: block">
      <p class="text-h6 mb-2">Battery slope worst cases</p>

      <v-data-table
        class="pr-4"
        density="comfortable"
        hide-default-footer
        :headers="batterySlopeWorstCaseHeaders"
        :items="batterySlopeWorstCases"
        @click:row="(_event: any, row: any) => goToDarwinUser(source, row.item.user_uid)"
      />
    </v-row>

    <v-row v-if="tempAnalysisItems && tempAnalysisItems.USE" class="mx-0 my-4 px-3" style="display: block">
      <div class="d-flex flex-row align-baseline">
        <span class="text-h6 pt-4 mb-2">Temp analysis summary</span>

        <v-spacer />

        <span class="text-button">In Use</span>
      </div>

      <v-data-table
        class="pr-4"
        hide-default-footer
        :headers="tempSummaryHeaders"
        :items="tempAnalysisItems.USE"
        :items-per-page="1000"
      />
    </v-row>

    <v-row v-if="tempAnalysisItems && tempAnalysisItems.NFI" class="mx-0 my-4 px-3" style="display: block">
      <div class="d-flex flex-row align-baseline">
        <span class="text-h6 pt-4 mb-2">Temp analysis summary</span>

        <v-spacer />

        <span class="text-button">Not in Finger</span>
      </div>

      <v-data-table
        class="pr-4"
        hide-default-footer
        :headers="tempSummaryHeaders"
        :items="tempAnalysisItems.NFI"
        :items-per-page="1000"
      />
    </v-row>

    <v-row v-if="tempAnalysisItems && tempAnalysisItems.CHG" class="mx-0 my-4 px-3" style="display: block">
      <div class="d-flex flex-row align-baseline">
        <span class="text-h6 pt-4 mb-2">Temp analysis summary</span>

        <v-spacer />

        <span class="text-button">In Charger</span>
      </div>

      <v-data-table
        class="pr-4"
        hide-default-footer
        :headers="tempSummaryHeaders"
        :items="tempAnalysisItems.CHG"
        :items-per-page="1000"
      />
    </v-row>

    <v-row v-if="dumpSummary && dumpSummaryItems.length" class="mx-0 my-4 px-3" style="display: block">
      <div class="d-flex flex-row align-baseline">
        <span class="text-h6 pt-4 mb-4">Firmware coredumps summary</span>

        <v-spacer />

        <span class="text-button">
          Incomplete: {{ dumpSummary.incomplete_count }} | Individual: {{ dumpSummary.individual_count }} | Total count:
          {{ dumpSummary.total_count }}
        </span>
      </div>

      <v-data-table
        class="pr-4"
        hide-default-footer
        :headers="dumpSummaryHeaders"
        :items="dumpSummaryItems"
        :items-per-page="1000"
      >
        <template #item.failed_in="{ item }">
          <div v-if="item.failed_in">
            <div v-if="item.failed_in.error">
              {{ item.failed_in.error }}
            </div>
            <div v-else>
              Function: {{ item.failed_in.function }}
              <br />
              File: {{ item.failed_in.file }}
              <br />
              Line: {{ item.failed_in.line }}
              <br />
            </div>
          </div>
        </template>

        <template #item.link_register="{ item }">
          <div v-if="item.link_register">
            <div v-if="item.link_register.error">
              {{ item.link_register.error }}
            </div>
            <div v-else>
              Function: {{ item.link_register.function }}
              <br />
              File: {{ item.link_register.file }}
              <br />
              Line: {{ item.link_register.line }}
              <br />
            </div>
          </div>
        </template>
      </v-data-table>
    </v-row>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { DarwinUrl, Debounce } from '@jouzen/outo-apps-toolkit'

  import {
    batterySlopeHeaders,
    batterySlopeWorstCaseHeaders,
    dumpSummaryHeaders,
    osSummaryHeaders,
    tempSummaryHeaders,
    topOccurrenceHeaders,
  } from '#views/tasks/constants'

  import {
    BatterySlopeStatus,
    BatterySlopeWorstCase,
    CoredumpSummary,
    CoredumpsSummary,
    EventSummary,
    EventSummaryData,
    FWSummary,
    OSSummary,
    SummaryJson,
    TaskParameters,
  } from '#types'

  @Component
  class Summary extends Vue {
    @Prop() public source!: string

    @Prop() public summary!: SummaryJson

    @Prop() public taskParameters!: TaskParameters

    public fw = ''
    public os = ''

    public fwDiff = ''
    public eventDiff = ''

    public expandedRows: any[] = []

    public summaryEvents: string[] = []

    public fwCount: number | undefined = undefined

    public osSummary: OSSummary | undefined = undefined
    public dumpSummary: CoredumpSummary | undefined = undefined

    public tempAnalysisItems: any = {}

    public osSummaryItems: EventSummaryData[] | [] = []
    public dumpSummaryItems: CoredumpsSummary[] | [] = []
    public batterySlopeItems: BatterySlopeStatus[] | [] = []
    public batterySlopeWorstCases: BatterySlopeWorstCase[] | [] = []

    public eventsDiff: { text: string; value: string }[] = [
      {
        text: 'Events per rings %',
        value: 'count_per_ring',
      },
      {
        text: 'Events per night %',
        value: 'count_per_night',
      },
    ]

    public osSummaryHeaders = osSummaryHeaders
    public dumpSummaryHeaders = dumpSummaryHeaders
    public tempSummaryHeaders = tempSummaryHeaders
    public batterySlopeHeaders = batterySlopeHeaders
    public topOccurrenceHeaders = topOccurrenceHeaders
    public batterySlopeWorstCaseHeaders = batterySlopeWorstCaseHeaders

    public getExpandIcon(item: any) {
      if (this.expandedRows.includes(item.key)) {
        return 'mdi-chevron-up'
      } else {
        return 'mdi-chevron-down'
      }
    }

    public get batterySlopeCount() {
      return this.batterySlopeItems.map((item: BatterySlopeStatus) => item.count).reduce((acc, count) => acc + count)
    }

    @Watch('fw')
    protected fWChanged() {
      this.updateData()
    }

    @Watch('os')
    protected oSChanged() {
      if (this.fw) {
        this.updateData()
      }
    }

    public beforeMount() {
      if (this.summary?.fw_summaries) {
        this.fw = this.taskParameters?.fwVersions || ''

        this.fwCount = this.summary.fw_summaries[0]?.rings_with_fw

        this.os = this.summary.fw_summaries[0]?.os_summaries[0]?.os

        this.osSummary = this.summary.fw_summaries[0]?.os_summaries[0]

        this.dumpSummary = this.summary.fw_summaries[0]?.dump_summary
      }
    }

    public clearDiff() {
      this.fwDiff = ''
      this.eventDiff = ''

      this.updateData()
    }

    public displayDiff() {
      if (!this.osSummaryHeaders.some((header: any) => header.key === 'changes_per_night')) {
        this.osSummaryHeaders.push({
          key: 'changes_per_night',
          title: `Changes from ${this.fwDiff} events per ${this.eventDiff === 'count_per_night' ? 'night' : 'ring'}%`,
          align: 'center',
        })
      } else {
        this.osSummaryHeaders = this.osSummaryHeaders.map((header: any) => {
          if (header.key === 'changes_per_night') {
            header.title = `Changes from ${this.fwDiff} event per ${
              this.eventDiff === 'count_per_night' ? 'night' : 'ring'
            }%`
          }

          return header
        })
      }

      const fwFind = this.getFWSummary(this.fwDiff)

      const eventFind = this.getOSSummary(fwFind)?.event_summaries

      this.osSummaryItems = this.osSummaryItems.map((item: any) => {
        let eventCompareData: { [key: string]: any } | undefined

        if (item.event && eventFind) {
          eventCompareData = eventFind[item.event]?.find((event: EventSummaryData) => event.name === item.name)
        }

        item['changes_per_night'] = eventCompareData
          ? (((eventCompareData[this.eventDiff] - item[this.eventDiff]) / item[this.eventDiff]) * 100).toFixed(2) + '%'
          : 'None'

        return item
      })
    }

    public getFWSummary(fw?: string): FWSummary | undefined {
      const fwFind: FWSummary | undefined = this.summary.fw_summaries.find(
        (summary: FWSummary) => summary.fw === (fw ?? this.fw),
      )

      this.fwCount = fwFind ? fwFind?.rings_with_fw : undefined

      return fwFind
    }

    public getOSSummary(fw?: FWSummary): OSSummary | undefined {
      const fwFind = fw ?? this.getFWSummary()

      if (fwFind) {
        const osFind = fwFind?.os_summaries?.find((e: OSSummary) => e.os === this.os)

        this.osSummary = osFind

        return osFind
      }

      this.osSummary = undefined

      return undefined
    }

    @Debounce(500)
    private updateData() {
      this.fwDiff = ''

      this.eventDiff = ''

      this.osSummaryItems = []

      const fwFind = this.getFWSummary()
      const osFind = this.getOSSummary()

      this.osSummaryHeaders = this.osSummaryHeaders.filter((header: any) => header.key !== 'changes_per_night')

      if (osFind?.event_summaries) {
        const eventKeys = this.getSummaryEvents(osFind.event_summaries)

        this.summaryEvents = eventKeys

        this.osSummaryItems = this.getOSSummaryItems(osFind.event_summaries, eventKeys)
      }

      this.dumpSummaryItems = fwFind?.dump_summary?.dumps || []

      const batterySlope = osFind?.battery_slope_summary

      this.batterySlopeItems = this.batterySlopeToArray(batterySlope ?? {}) || []

      this.batterySlopeWorstCases = batterySlope?.worst_cases || []

      if (this.summary?.temperature_summary) {
        this.tempAnalysisItems.USE = [
          { source: 'NTC', ...this.summary.temperature_summary.USE.NTC },
          { source: 'CPU', ...this.summary.temperature_summary.USE.CPU },
          { source: 'ACM', ...this.summary.temperature_summary.USE.ACM },
        ]

        this.tempAnalysisItems.NFI = [
          { source: 'NTC', ...this.summary.temperature_summary.NFI.NTC },
          { source: 'CPU', ...this.summary.temperature_summary.NFI.CPU },
          { source: 'ACM', ...this.summary.temperature_summary.NFI.ACM },
        ]

        this.tempAnalysisItems.CHG = [
          { source: 'NTC', ...this.summary.temperature_summary.CHG.NTC },
          { source: 'CPU', ...this.summary.temperature_summary.CHG.CPU },
          { source: 'ACM', ...this.summary.temperature_summary.CHG.ACM },
        ]
      }
    }

    private getSummaryEvents(eventSummaries: EventSummary): string[] {
      const keys = Object.keys(eventSummaries) || []

      const eventKeys = keys.reduce((arr: string[], cur: string) => {
        if (cur === 'FAILURE') {
          arr.splice(0, 0, cur)
        } else if (cur === 'WARNING') {
          const index = keys.includes('FAILURE') ? 1 : 0
          arr.splice(index, 0, cur)
        } else if (!['NIGHTS_TESTED', 'DATESPAN'].includes(cur)) {
          arr.push(cur)
        }
        return arr
      }, [])

      return eventKeys
    }

    private getOSSummaryItems(events: EventSummary, eventKeys: string[]) {
      let summaries: EventSummaryData[] = []

      eventKeys.forEach((key: string, index: number) => {
        if (key in events) {
          const data: EventSummaryData[] = [...events[key]].map((event: EventSummaryData) => {
            event.order = index
            event.event = key

            return event
          })

          summaries = summaries.concat(data)
        }
      })

      return summaries
    }

    private batterySlopeToArray(batterySlope: any) {
      const toArray = Object.keys(batterySlope).map((status) => {
        if (['failure', 'ok', 'warning'].includes(status)) {
          return { ...batterySlope[status], status: status }
        }
      })

      return toArray.filter((e: BatterySlopeStatus) => !!e)
    }

    @DarwinUrl()
    public goToDarwinUser(_source: string, userUrl: string) {
      window.open(userUrl, '_blank')
    }
  }

  export default toNative(Summary)
</script>

<style lang="scss" scoped>
  :deep(.events) {
    tr.v-data-table__tr {
      td:first-child {
        max-width: 0 !important;
      }
    }
  }
</style>
