<template>
  <v-dialog v-model="open" persistent width="800">
    <v-card>
      <v-card-title>Add study members</v-card-title>

      <v-card-text>
        <v-combobox
          v-model="selectedUsers"
          chips
          multiple
          autofocus
          closable-chips
          prepend-icon="mdi-account-multiple-plus"
          label="Type user UID's to add"
          hint="Press enter after each uuid"
          :rules="[validateUserUids]"
        />

        <div class="text-center ma-6"><b>OR</b></div>

        <v-file-input
          label="Select CSV file to import from"
          hint="You need to have one uuid per row and nothing else"
          @update:model-value="importStudyUsers($event as any)"
        />

        <template v-if="usersInOtherTeams.length > 0">
          <v-alert class="mt-6" type="error" variant="text">
            Following users already belong to another study. Select users to move to this study, otherwise they will be
            skipped.
          </v-alert>

          <v-data-table
            v-model="selectedUsersForMove"
            hide-default-footer
            item-value="userId"
            item-selectable="selectable"
            :headers="teamUsersHeaders"
            :items="usersInOtherTeams"
          >
            <template #header.actions>
              <v-checkbox
                @update:model-value="
                  $event
                    ? (selectedUsersForMove = usersInOtherTeams
                        .filter((u) => u.selectable === true)
                        .map((u) => u.userId))
                    : (selectedUsersForMove = [])
                "
              />
            </template>

            <template #item.info="{ item }">
              <v-tooltip location="top">
                <template #activator="{ props }">
                  <v-icon v-if="!item.selectable" v-bind="props" color="warning">mdi-alert-circle</v-icon>
                </template>
                <span>You do not have permissions to move this user. Contact the study managers for more info.</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </template>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn text="Cancel" class="mr-2" @click="cancel()" />

        <v-btn text="Add" color="primary" :disabled="!canAddUsers" @click="confirm()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { validate, version } from 'uuid'

  import { Component, Emit, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { teamUsersHeaders } from '#views/rdata/constants'

  import { AppStore, TeamsStore } from '#stores'

  import { Study, StudyUser } from '#types'

  @Component
  class SelectMembers extends Vue {
    @Prop() public open!: boolean

    @Prop() public team!: any

    @Emit('cancel')
    public cancel() {
      this.selectedUsers = []

      return null
    }

    @Emit('confirm')
    public confirm() {
      const users = this.selectedUsers
        .filter((userId: string) => !this.otherStudyUsers.includes(userId))
        .concat(this.selectedUsersForMove)

      this.selectedUsers = []

      return users
    }

    public selectedUsers: string[] = []

    public selectedUsersForMove: string[] = []

    public readonly teamUsersHeaders = teamUsersHeaders

    protected readonly appStore = new AppStore()
    protected readonly teamsStore = new TeamsStore()

    public get canAddUsers() {
      const hasUniqueUserIds = this.selectedUsers.filter((userId: string) => !this.otherStudyUsers.includes(userId))

      return (
        (this.validateUserUids(this.selectedUsers) === true && hasUniqueUserIds.length) ||
        this.selectedUsersForMove.length
      )
    }

    public get otherStudyUsers() {
      return this.selectedUsers.filter((u: string) =>
        this.teamsStore.teams.find(
          (t: Study) => t.id !== this.team.id && t.studyUsers.find((su: StudyUser) => su.id === u),
        ),
      )
    }

    public get usersInOtherTeams() {
      return this.selectedUsers
        .filter((u: string) =>
          this.teamsStore.teams.find(
            (t: Study) => t.id !== this.team.id && t.studyUsers.find((su: StudyUser) => su.id === u),
          ),
        )
        .map((u: string) => {
          const team = this.teamsStore.teams.find(
            (t) => t.id !== this.team.id && t.studyUsers.find((su: StudyUser) => su.id === u),
          )

          // check if user has permissions to move user to this study
          const selectable =
            team?.managerEmails?.includes(this.appStore.user.email) || team?.createdBy === this.appStore.user.email

          return {
            selectable: selectable,
            userId: u,
            ...team,
          }
        })
    }

    public importStudyUsers(file: File) {
      if (!file) {
        this.selectedUsers = []
      } else {
        const reader = new FileReader()

        reader.readAsText(file, 'UTF-8')

        reader.onload = async (event: any) => {
          this.selectedUsers = event.target.result
            .split('\n')
            .map((u: string) => u.trim())
            .filter((u: string) => validate(u))
            .filter((u: string) => !this.team.studyUsers.find((su: StudyUser) => su.id === u))
        }
      }
    }

    public validateUserUids(uuids: string[]): string | true {
      const invalid = uuids.find((uuid: string) => !validate(uuid) || version(uuid) !== 4)

      return invalid ? `Not a valid uuid: ${invalid}` : true
    }
  }

  export default toNative(SelectMembers)
</script>
