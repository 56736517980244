<template>
  <v-dialog v-if="open" v-model="open" width="800">
    <v-card>
      <v-card-title>Add protocols</v-card-title>

      <v-card-text>
        <v-autocomplete
          v-model="selectedProtoIds"
          chips
          closable-chips
          multiple
          autofocus
          label="Protocol file to add"
          :items="protoFiles.filter((f: any) => !team.protocols?.find((p: any) => p.id === f.value))"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text="Cancel" class="mr-2" @click="emitCancel()" />
        <v-btn text="Add" color="primary" :disabled="!selectedProtoIds.length" @click="emitConfirm()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
  import { Component, Emit, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { ProtoStore } from '#stores'

  import { ProtoFile, Study } from '#types'

  @Component
  class SelectProtos extends Vue {
    @Prop() public open!: boolean

    @Prop() public team!: Study

    public selectedProtoIds: any[] = []

    private readonly protoStore = new ProtoStore()

    @Emit('cancel')
    public emitCancel() {
      this.selectedProtoIds = []

      return null
    }

    @Emit('confirm')
    public emitConfirm() {
      const protocols = this.selectedProtoIds

      this.selectedProtoIds = []

      return protocols
    }

    public get protoFiles() {
      return this.protoStore.files.map((f: ProtoFile) => ({
        title: f.name,
        value: f.id,
      }))
    }
  }

  export default toNative(SelectProtos)
</script>
