import { uniq } from 'lodash-es'

import { Pinia, Store } from 'pinia-class-component'

type PrefKey = 'fwVersions' | 'tlogBranch' | 'tlogOption' | 'tlogOptions'

@Store
export class PrefsStore extends Pinia {
  public daysLimit = '1'

  public runCommand = 'tlog'

  public tlogOption = ''
  public tlogBranch = 'master'
  public tlogOptions = '-run_official'

  public fwVersions: any = {}

  public taskFilters: string[] = []
  public tlogBranches: string[] = []

  public get runOptions() {
    return this.runCommand === 'tlog' ? this.tlogOptions : ''
  }

  public init() {
    this.daysLimit = localStorage.getItem('OuraDaysLimitPrefs') ?? '3'

    this.runCommand = localStorage.getItem('OuraRunCommandPrefs') ?? 'tlog'

    this.tlogBranch = localStorage.getItem('OuraTlogBranchPrefs') ?? 'master'
    this.tlogOption = localStorage.getItem('OuraTlogOptionPrefs') ?? 'all'
    this.tlogOptions = localStorage.getItem('OuraTlogOptionsPrefs') ?? '-run_official'

    this.fwVersions = JSON.parse(localStorage.getItem('OuraFWVersionsPrefs') ?? '{}') ?? {}

    this.taskFilters = JSON.parse(localStorage.getItem('OuraTaskFiltersPrefs') ?? '[]') ?? []
  }

  public updatePrefs(prefs: any) {
    if (prefs.key === 'taskFilters') {
      this.taskFilters = prefs.value || []
    } else if (prefs.key === 'fwVersions') {
      this.fwVersions[prefs.flavor] = prefs.value ?? ''
    } else {
      this[prefs.key as PrefKey] = prefs.value.toString()
    }

    localStorage.setItem('OuraDaysLimitPrefs', this.daysLimit.toString())
    localStorage.setItem('OuraRunCommandPrefs', this.runCommand.toString())
    localStorage.setItem('OuraTlogBranchPrefs', this.tlogBranch.toString())
    localStorage.setItem('OuraTlogOptionPrefs', this.tlogOption.toString())
    localStorage.setItem('OuraTlogOptionsPrefs', this.tlogOptions.toString())

    localStorage.setItem('OuraFWVersionsPrefs', JSON.stringify(this.fwVersions))
    localStorage.setItem('OuraTaskFiltersPrefs', JSON.stringify(this.taskFilters))
  }

  public async listBranches(command: string): Promise<void> {
    const path = `/api/v1/files?search_prefix=builds/${command}&bucket=task-binaries`

    try {
      const response = await this.$axios.get(`${path}`)

      const branches = uniq(
        (response?.data?.contents || [])
          .map((b: any) => b.key.replace(/.*branch=/, '').replace(/\/.*/, ''))
          .filter(Boolean),
      ) as string[]

      if (command === 'tlog') {
        this.tlogBranches = branches
      }
    } catch (error) {
      console.error(`Error while fetching ${command} branches: `, error)
    }
  }
}
