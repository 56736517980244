<template>
  <v-sheet>
    <v-data-table
      v-if="items"
      disable-pagination
      no-data-text="There are no sample lists to display"
      :loading="isLoading"
      :headers="headers"
      :items="items"
      :items-per-page="100"
      :items-per-page-options="[
        { title: '100', value: 100 },
        { title: '500', value: 500 },
        { title: '1000', value: 1000 },
      ]"
      :sort-by="[{ key: 'createdAt', order: 'desc' }]"
    >
      <template #item.itemCount="{ item }">
        {{ item.itemCount }}
      </template>

      <template #item.createdAt="{ item }">
        {{ $dayjs(item.createdAt).format('D.M.YYYY') }}
      </template>

      <template #item.actions="{ item }">
        <div @click.stop="">
          <v-menu>
            <template #activator="{ props }">
              <v-btn v-bind="props" icon="mdi-dots-vertical" />
            </template>

            <v-list>
              <v-list-item
                class="text-success"
                title="Edit filter"
                prepend-icon="mdi-pencil"
                :disabled="isLoading"
                @click="modifyFilter(item)"
              />

              <v-list-item
                class="text-warning"
                title="Archive filter"
                prepend-icon="mdi-archive"
                :disabled="isLoading"
                @click="archiveFilter(item)"
              />

              <v-list-item
                class="text-error"
                prepend-icon="mdi-delete"
                title="Delete filter"
                :disabled="isLoading"
                @click="deleteFilter(item)"
              />
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
  </v-sheet>

  <EditFilter :env="env" :data="data" :open="editDialog" @close="editDialog = false" />
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { sampleHeaders } from '#views/setup/constants'

  import { SetupStore } from '#stores'

  import { SampleFilter } from '#types'

  @Component
  class FiltersList extends Vue {
    @Prop() public env!: string
    @Prop() public type!: string

    @Prop() public items!: SampleFilter[]

    public editDialog = false

    public data: SampleFilter | null = null

    public readonly headers = sampleHeaders

    private readonly setupStore = new SetupStore()

    public get isLoading() {
      return this.setupStore.loading
    }

    public deleteFilter(filter: SampleFilter) {
      this.$confirm('Confirm deletion of this sample list?', filter.name, {
        buttonTrueColor: 'error',
      }).then((confirmed) => {
        if (confirmed) {
          this.setupStore.deleteSampleFilter(this.env, filter.uid!)
        }
      })
    }

    public async modifyFilter(filter: SampleFilter) {
      const sampleFilterDetails = await this.setupStore.fetchSampleFilter(this.env, filter.uid!)

      if (sampleFilterDetails) {
        this.data = { ...filter, ...sampleFilterDetails, tags: sampleFilterDetails.tags || '' }

        this.editDialog = true
      }
    }

    public async archiveFilter(filter: SampleFilter) {
      const sampleFilterDetails = await this.setupStore.fetchSampleFilter(this.env, filter.uid!)

      const payload = { ...sampleFilterDetails, isArchived: true, tags: sampleFilterDetails.tags || '' }

      this.$confirm('Confirm archiving of this sample list?', filter.name, {
        buttonTrueColor: 'warning',
      }).then((confirmed) => {
        if (confirmed) {
          this.setupStore.modifySampleFilter(this.env, payload)
        }
      })
    }
  }

  export default toNative(FiltersList)
</script>
