<template>
  <v-app-bar v-if="edited">
    <v-btn icon="mdi-arrow-left" @click="$router.push('/rdata/protocols')" />

    <v-tooltip
      location="bottom start"
      :text="
        edited.name.length === 0 ? 'Name cannot be empty' : !hasUniqueName ? 'File with this name already exists' : ''
      "
      :content-class="{ 'bg-error': true, 'd-none': edited.name.length > 0 && hasUniqueName }"
      open-on-focus
    >
      <template #activator="{ props }">
        <v-text-field
          v-bind="props"
          v-model.trim="edited.name"
          style="max-width: 500px"
          class="text-primary font-weight-bold"
          :readonly="!hasWriteRights"
          :append-inner-icon="edited.name.length === 0 || !hasUniqueName ? 'mdi-alert-outline' : ''"
          @update:focused="
            (edited.name.length === 0 || !hasUniqueName) && !$event ? (edited.name = original!.name) : null
          "
        />
      </template>
    </v-tooltip>

    <v-spacer />

    <v-btn
      rounded
      class="ml-4"
      variant="flat"
      text="Save changes"
      :disabled="!hasDataChanges || !hasWriteRights || !edited.name || !hasUniqueName"
      @click="saveChanges()"
    />
  </v-app-bar>

  <v-container v-if="edited">
    <v-row>
      <v-col cols="12">
        <v-alert
          v-if="edited.updatedAt && edited.updatedBy"
          class="flex-shrink-0"
          :color="hasWriteRights ? 'info' : 'warning'"
        >
          <v-row align="center">
            <v-col class="grow">
              Last edited by {{ edited.updatedBy }} at
              {{ $dayjs(edited.updatedAt.toDate()).format('HH:mm:ss on DD MMM YYYY') }}
            </v-col>

            <v-col class="flex-shrink-1 text-no-wrap text-right">
              {{ hasWriteRights ? (hasDataChanges ? '(unsaved)' : '(saved)') : '(read-only)' }}
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>

    <v-row class="mb-4">
      <v-col cols="12">
        <v-card>
          <v-toolbar>
            <v-toolbar-title>Protocol file</v-toolbar-title>
          </v-toolbar>

          <v-card-text class="pa-8">
            <v-row class="align-center">
              <v-col>
                <v-text-field
                  v-model="edited.id"
                  readonly
                  persistent-hint
                  label="ID"
                  hint="This id is shown in the collected data"
                />
              </v-col>
            </v-row>

            <v-row class="align-center">
              <v-col class="d-flex align-center justify-end" cols="12">
                <v-combobox
                  class="managers"
                  chips
                  multiple
                  closable-chips
                  label="Managers (emails)"
                  hint="Managers have permission to edit this protocol file"
                  :model-value="[edited.createdBy].concat(edited.managerEmails || [])"
                  @update:model-value="updateManagers($event)"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-toolbar class="px-4">
            <v-toolbar-title>Activities</v-toolbar-title>
          </v-toolbar>

          <v-table>
            <template #default>
              <thead>
                <tr>
                  <th scope="col" class="text-center"><span>NO</span></th>
                  <th scope="col" class="text-left" style="width: 50%"><span>Activity ID</span></th>
                  <th scope="col" class="text-left" style="width: 50%"><span>Activity Name</span></th>
                  <th scope="col" class="text-left" />
                </tr>
              </thead>

              <tbody>
                <tr v-for="(activity, index) in edited.activities" :key="activity.id">
                  <td>
                    <div class="text-center px-5">{{ index + 1 }}</div>
                  </td>
                  <td>
                    <v-text-field v-model="activity.id" readonly />
                  </td>
                  <td>
                    <v-text-field v-model="activity.name" />
                  </td>
                  <td class="text-right">
                    <v-tooltip location="top">
                      <template #activator="{ props }">
                        <v-btn
                          v-bind="props"
                          class="text-grey"
                          icon="mdi-delete"
                          @click="edited.activities.splice(index, 1)"
                        />
                      </template>

                      Remove this annotation
                    </v-tooltip>
                  </td>
                </tr>

                <tr>
                  <td>
                    <div class="px-4">{{ edited.activities.length + 1 }}</div>
                  </td>
                  <td>
                    <v-text-field
                      v-model="activityId"
                      placeholder="New activity ID"
                      @blur="activityId = snakeCase(activityId)"
                    />
                  </td>
                  <td>
                    <v-text-field v-model="activityName" placeholder="New activity Name" />
                  </td>
                  <td class="text-right">
                    <v-tooltip location="top">
                      <template #activator="{ props }">
                        <v-btn
                          v-bind="props"
                          icon="mdi-plus-thick"
                          class="text-grey"
                          :disabled="
                            !activityId || !activityName || !!edited.activities.find((a: any) => a.id === activityId)
                          "
                          @click="addActivity()"
                        />
                      </template>

                      Add this annotation
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
  import { cloneDeep, isEqual, snakeCase, uniq } from 'lodash-es'

  import { Component, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { AppStore, ProtoStore } from '#stores'

  import { ProtoFile } from '#types'

  @Component
  class ProtoEditor extends Vue {
    @Prop() public fid!: string

    public activityId = ''
    public activityName = ''

    public edited: any = null
    public original: any = null

    public snakeCase = snakeCase

    private readonly appStore = new AppStore()
    private readonly protoStore = new ProtoStore()

    public get rights() {
      return this.appStore.rights
    }

    public get loading() {
      return this.protoStore.loading
    }

    public get protoFiles() {
      return this.protoStore.files
    }

    public get hasDataChanges() {
      return this.edited && !isEqual(this.original, this.edited)
    }

    public get hasWriteRights() {
      return (
        this.appStore.isResearchDataAdmin ||
        this.appStore.user?.email === this.original?.createdBy ||
        this.original?.managerEmails?.includes(this.appStore.user?.email)
      )
    }

    public get hasUniqueName() {
      return !this.protoFiles.find(
        (file: ProtoFile) => file.id !== this.original.id && file.name.toLowerCase() === this.edited.name.toLowerCase(),
      )
    }

    @Watch('protoFiles', { immediate: true })
    protected onTeamsChanged() {
      const file = this.protoFiles.find((t) => t.id === this.fid)

      if (file) {
        this.original = file

        this.edited = cloneDeep(this.original)

        this.edited.createdAt = this.original.createdAt
        this.edited.updatedAt = this.original.updatedAt
      }
    }

    public async saveChanges() {
      await this.protoStore.updateFile({ ...this.edited })
    }

    public addActivity() {
      this.edited.activities.push({
        id: this.activityId,
        name: this.activityName,
      })

      this.activityId = ''
      this.activityName = ''
    }

    public updateManagers(managers: string[]) {
      this.edited.managerEmails = uniq(
        managers.filter((m) => m !== this.original.createdBy && m.includes('@ouraring.com')).map((m) => m.trim()),
      )

      this.edited = { ...this.edited }
    }
  }

  export default toNative(ProtoEditor)
</script>

<style lang="scss" scoped>
  .managers {
    :deep(.v-input__append-inner) {
      display: none;
    }

    :deep(.v-chip:first-child) {
      button {
        display: none;
      }
    }
  }

  :deep(.v-data-table) {
    .v-data-table {
      background: transparent;
    }
  }
</style>
