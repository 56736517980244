<template>
  <v-dialog v-model="open" persistent width="800">
    <v-card>
      <v-card-title>{{ !data.uid ? 'Create sample list' : 'Edit sample list' }}</v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-text-field v-model="data.name" autofocus label="Name *" :rules="[requiredField]" />
          </v-col>

          <v-col cols="6">
            <v-select v-model="data.type" label="Type *" :items="filterTypes" :rules="[requiredField]" />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-textarea v-model="data.description" label="Description" />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="data.descriptionUrl"
              :rules="data.type.startsWith('serial') && data.tags?.includes('darwin') ? [requiredField] : []"
              :label="'More info URL' + (data.type.startsWith('serial') && data.tags?.includes('darwin') ? ' *' : '')"
              placeholder="More info about this list, required for lists used in Darwin!"
            />
          </v-col>
        </v-row>

        <v-row v-if="data.type.includes('serial')" class="mt-n8">
          <v-col cols="12">
            <v-checkbox
              value="darwin"
              label="Show in Darwin diagnostics if users current ring is included in this list"
              :model-value="data.tags"
              @update:model-value="data.tags = $event ? 'darwin' : ''"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea
              persistent-hint
              rows="8"
              :hint="hintNotes[data.type]"
              :rules="[requiredField]"
              :label="data.type ? (filterTypes.find((f) => f.value === data.type) || {}).title + '`s' : 'Sample items'"
              :model-value="(data.data || []).join('\n')"
              @update:model-value="data.data = $event.split('\n')"
              @update:focused="formatData()"
            >
              <template #message="{ message }">
                <!-- eslint-disable-next-line vue/no-v-html -->
                <span v-html="message" />
              </template>

              <template #append-inner>
                <v-tooltip location="top">
                  <template #activator="{ props }">
                    <v-icon v-bind="props" size="24" color="primary">mdi-information</v-icon>
                  </template>

                  <div class="text-break" style="max-width: 300px">
                    You can copy paste the samples as csv or text. Data should include only uuid's, serial numbers,
                    firmware versions, serial numbers with product code and bluetooth id (e.g. 2A392108002159
                    JZ70-0104-09 A038F8A3103F).
                  </div>
                </v-tooltip>
              </template>
            </v-textarea>
          </v-col>
        </v-row>

        <v-row v-if="errorText">
          <v-col cols="12">
            <v-alert type="error">{{ errorText }}</v-alert>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn text="Cancel" class="mr-2" @click="close()" />

        <v-btn
          text="Save"
          color="primary"
          :disabled="
            !data.name ||
            !data.type ||
            !data.data ||
            (data.type.startsWith('serial') && !!data.tags?.includes('darwin') && !data.descriptionUrl)
          "
          @click="save()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { filterTypes, hintNotes } from '#views/setup/constants'

  import { SetupStore } from '#stores'

  import { SampleFilter } from '#types'

  @Component
  class EditFilter extends Vue {
    @Prop() public env!: string
    @Prop() public type!: string
    @Prop() public open!: boolean

    @Prop() public data!: SampleFilter

    public errorText = ''

    public readonly hintNotes = hintNotes
    public readonly filterTypes = filterTypes

    private readonly setupStore = new SetupStore()

    @Emit('close')
    public close() {
      this.errorText = ''

      return null
    }

    public async save() {
      let response: any

      this.errorText = ''

      const data = {
        ...this.data,
        tags: this.data.tags || '',
        data: this.data.data || [],
        isArchived: false,
      }

      if (!this.data.uid) {
        response = await this.setupStore.createSampleFilter(this.env, data)
      } else {
        response = await this.setupStore.modifySampleFilter(this.env, data)
      }

      if (!response?.data || response?.message) {
        this.errorText = response.message || `Unknown error occurred when saving the sample filter, please report this!`
      } else {
        this.close()
      }
    }

    public formatData() {
      if (this.data?.data?.length) {
        this.data.data = this.data.data
          .map((line: string) =>
            line.split('\t').length
              ? line.split('\t')[0].trim()
              : line.split(';').length
                ? line.split(';')[0].trim()
                : line.split(',').length
                  ? line.split(',')[0].trim()
                  : line.trim(),
          )
          .filter(Boolean)
      }
    }

    public requiredField(value: string) {
      return !!value || '*This field is required.'
    }
  }

  export default toNative(EditFilter)
</script>
