<template>
  <v-dialog v-model="isOpen" persistent width="600">
    <v-card>
      <v-card-title>
        <template v-if="!isLoading && !allFactoryFiles.length">
          Are you sure you want to download all the tests?
        </template>
        <template v-else-if="isLoading">Fetching files to download...</template>
        <template v-else>Completed fetching the files</template>
      </v-card-title>

      <v-card-text>
        <p v-if="cancelAllFactoryFilePoll">The download all factory tests has been cancelled</p>

        <p
          v-if="allFactoryZipsCount && allFactoryZipsCount !== allFactoryFiles.length && isLoading"
          class="overline pl-12"
        >
          Creating {{ allFactoryZipsCount }} zip files.
        </p>

        <p v-if="allFactoryFiles.length && allFactoryFiles.length === allFactoryZipsCount" class="pl-10 mb-0">
          All {{ allFactoryZipsCount }} zip files are created successfully and ready to download
        </p>

        <v-list class="pa-6 pt-0">
          <div v-if="allFactoryFiles.length">
            <v-list-item
              v-for="file in allFactoryFiles"
              :key="file.key"
              link
              icon="mdi-cloud-download"
              @click="downloadFiles({ key: [file.key], bucket: 'TEMPORARY' })"
            >
              <template #append>
                <v-icon>mdi-cloud-download</v-icon>
              </template>

              <v-list-item-title>
                {{ file.key.split('/').pop() }}
              </v-list-item-title>

              <p class="text-no-wrap">{{ getSize(file.size) }}</p>
            </v-list-item>
          </div>

          <template v-if="isLoading">
            <v-list-item v-for="index in allFactoryZipsCount - allFactoryFiles.length" :key="index" link>
              <v-btn icon="mdi-cloud-download" :disabled="true" :loading="true" />

              <v-list-item-title class="text-grey-darken-1">Creating...</v-list-item-title>

              <div class="text-grey-darken-1 text-no-">0MB</div>
            </v-list-item>
          </template>
        </v-list>

        <div v-if="!allFactoryZipsCount && isLoading" class="mt-5">
          <v-progress-linear indeterminate />
        </div>

        <div v-if="allFactoryFilesError">
          <v-alert type="error">
            {{ allFactoryFilesError }}
          </v-alert>
        </div>

        <v-dialog v-model="cancelDownloadAllPolling" persistent width="500">
          <v-card>
            <v-card-title>Are you sure to cancel this downloading?</v-card-title>

            <v-card-actions>
              <v-spacer />

              <v-btn text="Close" @click="cancelDownloadAllPolling = false" />

              <v-btn text="Confirm" color="primary" @click="confirmCancelDownloadAllPolling()" />
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn v-if="isLoading" text="Cancel" @click="cancelDownloadAllPolling = true" />

        <v-btn v-else text="Close" @click="resetDownloadAll()" />

        <v-btn
          v-if="!allFactoryFiles.length && !cancelAllFactoryFilePoll"
          class="ml-2"
          color="primary"
          text="Confirm"
          :disabled="isLoading"
          @click="downloadAllFiles()"
        />

        <v-btn
          v-if="cancelAllFactoryFilePoll"
          class="ml-2"
          color="primary"
          text="Start again"
          :disabled="isLoading"
          @click="downloadAllFiles()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Model, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { TestsStore } from '#stores'

  @Component
  class DownloadAll extends Vue {
    @Prop() public fields: any

    @Prop() public tab!: string

    @Model() public isOpen!: boolean

    public cancelDownloadAllPolling = false

    private readonly testsStore = new TestsStore()

    public get isLoading() {
      return this.testsStore.loading
    }

    public get allFactoryFiles() {
      return this.testsStore.allFactoryFiles
    }

    public get allFactoryZipsCount() {
      return this.testsStore.allFactoryZipsCount
    }

    public get allFactoryFilesError() {
      return this.testsStore.allFactoryFilesError
    }

    public get cancelAllFactoryFilePoll() {
      return this.testsStore.cancelAllFactoryFilePoll
    }

    public getSize(size: number): string {
      return size < 1000 ? `${size} B` : `${(size / 1048576).toFixed(2)} MB`
    }

    public downloadFiles(files: { bucket: string; key: any }) {
      this.testsStore.downloadFile(files)
    }

    public confirmCancelDownloadAllPolling() {
      this.cancelDownloadAllPolling = false

      this.testsStore.setCancelAllFactoryFilePoll(true)
    }

    public resetDownloadAll() {
      this.isOpen = false

      this.cancelDownloadAllPolling = false

      this.testsStore.setAllFactoryFiles([])

      this.testsStore.setAllFactoryFilesError('')

      this.testsStore.setAllFactoryZips(0)

      this.testsStore.setCancelAllFactoryFilePoll(false)
    }

    public downloadAllFiles() {
      this.testsStore.setCancelAllFactoryFilePoll(false)

      let category = this.tab.slice(0, -1)
      let fields = this.fields

      if (category === 'file') {
        category = 'ring'
        fields = { serialNo: fields.serialNo }
      }

      this.testsStore.downloadAll({ fields, category })
    }
  }

  export default toNative(DownloadAll)
</script>
