<template>
  <v-row class="my-4">
    <v-data-table
      hide-default-header
      hide-default-footer
      no-data-text="No rules added yet"
      :headers="headers"
      :items="items"
    >
      <template #headers="{ headers }">
        <thead class="text-primary">
          <th
            v-for="head in headers[0]"
            :key="head.title"
            :class="head.title === 'Created at' ? 'text-left pl-3' : 'text-center'"
          >
            <span v-if="head.title === 'Actions'" />
            <span v-else>{{ head.title }}</span>
          </th>
        </thead>
      </template>

      <template #item.condition="{ item }">
        {{ item.rule.includes('Firmware') ? item.condition : `count per night ${item.condition} ${item.value}` }}
      </template>

      <template #item.action="{ item }">
        {{ (ruleStatuses.find((status) => status.value === item.action) || {}).title || '' }}
      </template>

      <template #item.createdAt="{ item }">
        {{ $dayjs(item.createdAt).format('D.M.YYYY') }}
      </template>

      <template #item.actions="{ item }">
        <div class="d-flex">
          <v-btn icon="mdi-pencil" @click="modifyRule(item)" />

          <v-btn icon="mdi-delete" @click="deleteRule(item)" />
        </div>
      </template>
    </v-data-table>

    <v-dialog v-if="selectedRule" v-model="editDialog" max-width="900px">
      <v-card>
        <v-card-title>Edit alert</v-card-title>

        <EditRule
          :edit="true"
          :rule="selectedRule"
          :fw="selectedRule!.rule.includes('Firmware')"
          @change-rule="saveRule($event)"
          @close="editDialog = false"
        />
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { ruleStatus, rulesHeaders } from '#views/setup/constants'

  import { SetupStore } from '#stores'

  import { ScheduleAlertRule } from '#types'

  @Component
  class RulesList extends Vue {
    @Prop() public env!: string
    @Prop() public uid!: string

    public editDialog = false
    public deleteDialog = false

    public selectedRule: ScheduleAlertRule | null = null

    public readonly headers = rulesHeaders
    public readonly ruleStatuses = ruleStatus

    private readonly setupStore = new SetupStore()

    public get items() {
      const alert = this.setupStore.activeScheduleAlert

      const ruleItemsFormat = alert?.rules?.map((data) => {
        const hasFw = data.rule?.includes('fw')

        const splitRule = data.rule?.split(' ') || []

        const nameInSingleQuote = (data.rule?.match(/'.*'/g) ?? [])[0]?.replace(/'/g, '') ?? ''

        return {
          ...data,
          condition: hasFw ? nameInSingleQuote : splitRule[splitRule.length - 2],
          rule: hasFw ? 'Firmware' : nameInSingleQuote,
          value: hasFw ? '' : Number(splitRule[splitRule.length - 1]) || 0,
        }
      })

      return ruleItemsFormat
    }

    public saveRule(rule: ScheduleAlertRule) {
      if (this.uid) {
        this.setupStore.editScheduleAlertRule(this.env, {
          ...rule,
          ruleSetUid: this.uid,
          uid: this.selectedRule?.uid,
        })

        this.editDialog = false
      }
    }

    public modifyRule(rule: ScheduleAlertRule) {
      this.selectedRule = rule

      this.editDialog = true
    }

    public deleteRule(rule: ScheduleAlertRule) {
      this.$confirm('Confirm deletion of this schedule alert rule?', rule.rule, {
        buttonTrueColor: 'error',
      }).then((confirmed) => {
        if (confirmed) {
          this.setupStore.deleteScheduleAlertRule(this.env, {
            ...rule,
            ruleSetUid: this.uid,
            uid: rule.uid!,
          })
        }
      })
    }
  }

  export default toNative(RulesList)
</script>
