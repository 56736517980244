import { TaskParameters } from '#types'

const fallbacks = {
  maxUsers: '1000',
  hwType: 'Any',
  osSplit: 'Android: 50%, iOS: 50%',
  inputDays: '3',
}

export function getParamValue(name: keyof TaskParameters, taskParameters: TaskParameters): string {
  let paramValue = ''

  if (name === 'osSplit') {
    paramValue = `Android: ${100 - Number((taskParameters?.[name] ?? fallbacks[name]).substring(4))}%, iOS: ${(
      taskParameters?.[name] ?? fallbacks[name]
    ).substring(4)}%`
  } else if (name === 'hwType' && taskParameters?.[name] === 'gen2x') {
    paramValue = 'gen3'
  } else if (Array.isArray(taskParameters?.[name])) {
    paramValue = (taskParameters?.[name] as string[]).join(', ') || 'Any'
  } else {
    paramValue = (taskParameters?.[name] ?? '').toString() || ''
  }

  return paramValue
}
