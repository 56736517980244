import * as Sentry from '@sentry/vue'

import { Pinia, Store } from 'pinia-class-component'

import {
  SAMLAuthProvider,
  Unsubscribe,
  getAuth,
  onAuthStateChanged,
  signInWithPopup,
  signInWithRedirect,
  signOut,
} from 'firebase/auth'

import { ProtoStore, RdataStore, TeamsStore } from '#stores'

let logoutTimeout: number | null = null

let authStateUnsubscribe: Unsubscribe | null = null

@Store
export class AppStore extends Pinia {
  public user: any = undefined

  public customClaims: any = {}

  public rights: string[] = []
  public overrides: string[] = []

  public sessionExpirationTime: null | number = null

  public get userEmail() {
    return this.user.email ?? undefined
  }

  public get userGroups() {
    return this.customClaims.groups || []
  }

  public get activeRights() {
    return this.rights.filter((right) => !this.overrides.includes(right))
  }

  public get isOtaRolloutsAdmin() {
    return this.overrides.includes('groupOtaRolloutsAdmins')
      ? false
      : this.customClaims?.aud === 'rings-analyzer-development' ||
          (this.customClaims?.groups || '').includes('Firmware admins')
  }

  public get isResearchDataAdmin() {
    return this.overrides.includes('groupResearchDataAdmins')
      ? false
      : this.customClaims?.aud === 'rings-analyzer-development' ||
          (this.customClaims?.groups || '').includes('Research admins')
  }

  public get hasDataDownloadRights() {
    return this.overrides.includes('allowDataDownloadAccess')
      ? false
      : this.customClaims?.aud === 'rings-analyzer-development' ||
          (this.rights || '').includes('allowDataDownloadAccess')
  }

  public auth() {
    return new Promise((resolve) => {
      if (authStateUnsubscribe) {
        authStateUnsubscribe()
      }

      authStateUnsubscribe = onAuthStateChanged(getAuth(), async (user) => {
        const currentUser = this.user

        if (user) {
          if (currentUser?.id !== user.uid) {
            await this.getCurrentUser(user)
          }
        } else {
          // Ensure user is logged out if auth state changes

          await this.logout(window.location.pathname !== '/')

          // Auto login if in none root route path

          if (window.location.pathname !== '/') {
            await this.login()
          }
        }

        resolve(user)
      })
    })
  }

  public async login() {
    const env = import.meta.env.VITE_APP_ENV

    const provider = new SAMLAuthProvider('saml.oura')

    if (env === 'release') {
      signInWithRedirect(getAuth(), provider)
    } else {
      try {
        await signInWithPopup(getAuth(), provider)

        window.location.reload()
      } catch (_error) {
        console.info('Popup closed or blocked, login interrupted')
      }
    }
  }

  public async logout(reLogin?: boolean) {
    // This initiates the firebase logout and resets state

    new TeamsStore().unsubscribeFromTeams()
    new RdataStore().unsubscribeFromRdata()
    new ProtoStore().unsubscribeFromProto()

    this.user = reLogin ? undefined : null

    if (!reLogin) {
      this.resetAppUrlRouteAndParams()
    }

    signOut(getAuth())
  }

  public toggleRole(role: string) {
    if (this.overrides.includes(role)) {
      sessionStorage.OuraOverrides = this.overrides.filter((override) => override !== role).join(' ')
    } else {
      sessionStorage.OuraOverrides = [...this.overrides, role].join(' ')
    }

    window.location.reload()
  }

  private async getCurrentUser(user: any) {
    if (sessionStorage.OuraOverrides) {
      this.overrides = sessionStorage.OuraOverrides.split(' ')
    }

    const response = await this.$axios.get(`/api/v1/me`)

    console.log('User info', response?.data)

    await user?.getIdTokenResult(true).then(async (result: any) => {
      console.log('Auth info', result?.claims)

      this.customClaims = result?.claims || {}

      if (result?.claims?.auth_time) {
        this.setSessionExpirationTime(+result.claims.auth_time * 1000)
      }
    })

    this.user = typeof response?.data === 'object' ? response?.data : null

    if (import.meta.env.VITE_LOCAL_RIGHTS && window.location.host.startsWith('localhost')) {
      this.rights = import.meta.env.VITE_LOCAL_RIGHTS.split(',')
    } else {
      this.rights = response?.data?.rights || []
    }

    Sentry.setUser({ email: response?.data?.email || '' })
  }

  private setSessionExpirationTime(authTime?: number) {
    // Expire session in 12 hours

    if (logoutTimeout) {
      window.clearTimeout(logoutTimeout)
    }

    const sessionDuration = 12 * 60 * 60 * 1000

    const millisecondsUntilExpiration = sessionDuration - (Date.now() - (authTime || 0))

    if (millisecondsUntilExpiration <= 0) {
      this.sessionExpirationTime = null

      this.logout()
    } else {
      this.sessionExpirationTime = new Date((authTime || 0) + sessionDuration).getTime()

      logoutTimeout = window.setTimeout(() => this.logout(), millisecondsUntilExpiration)
    }
  }

  private resetAppUrlRouteAndParams(forcePathReset?: boolean) {
    sessionStorage.removeItem('OuraRouteParams')

    if (forcePathReset || window.location.pathname !== '/' || window.location.search.length) {
      window.location.href = '/'
    }
  }
}
