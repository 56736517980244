<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title>PPG Monitoring</v-toolbar-title>
    </v-toolbar>

    <v-card-text class="pl-8">
      <v-row>
        <v-col
          v-for="feature in getFieldItems(sensor, schema, sensor.toLowerCase() + '_monitor_feature_mask').filter(
            (i) => !!i.value,
          )"
          :key="feature.value"
          cols="3"
        >
          <v-switch
            class="mr-5"
            density="compact"
            :disabled="disabled"
            :label="feature.title"
            :items="getFieldItems(sensor, schema, 'measurement_data_interval')"
            :model-value="
              getMonitoringEnabled(
                sensor,
                schema,
                config,
                sensor.toLowerCase() + '_monitor_feature_mask',
                feature.value,
              )
            "
            @update:model-value="
              setMonitoringEnabled(
                'PPG',
                schema,
                config,
                sensor.toLowerCase() + '_monitor_feature_mask',
                $event ? feature.value : -feature.value,
              )
            "
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { getFieldItems, getMonitoringEnabled, setMonitoringEnabled } from '#views/rdata/utilities'

  @Component
  class MonitoringMask extends Vue {
    @Prop() public config!: any
    @Prop() public schema!: any

    @Prop() public sensor!: string

    @Prop() public disabled!: boolean

    public readonly getFieldItems = getFieldItems

    public readonly getMonitoringEnabled = getMonitoringEnabled
    public readonly setMonitoringEnabled = setMonitoringEnabled
  }

  export default toNative(MonitoringMask)
</script>
