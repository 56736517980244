<template>
  <v-dialog v-model="open" width="600" @click:outside="closeDialog()">
    <v-card>
      <v-card-title>Create new config file</v-card-title>

      <v-card-text>
        <v-text-field v-model.trim="configName" autofocus label="File name" :rules="[validateConfigName]" />

        <v-select
          v-model="schema"
          return-object
          item-title="name"
          label="Select schema to use"
          :items="configSchemas"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn text="Cancel" class="mr-2" @click="closeDialog()" />

        <v-btn text="Create" color="primary" :disabled="!configName || !schema || !hasUniqueName" @click="create()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Emit, Model, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { RdataStore } from '#stores'

  import { RdataFile } from '#types'

  @Component
  class ConfigCreate extends Vue {
    @Model() public open!: boolean

    @Prop() public filters!: string[]

    @Emit('create')
    public create() {
      return {
        name: this.configName,
        schema: this.schema,
        isArchived: false,
      }
    }

    public configName = ''

    public schema: any = null

    private readonly rdataStore = new RdataStore()

    public get configFiles() {
      return this.rdataStore.files
    }

    public get configSchemas() {
      return this.rdataStore.schemas
    }

    public get hasUniqueName() {
      return !this.configFiles.some((file: RdataFile) => file.name.toLowerCase() === this.configName.toLowerCase())
    }

    @Watch('configSchemas', { immediate: true })
    protected onSchemasChanged() {
      if (!this.schema) {
        this.schema = this.configSchemas[0]
      }
    }

    public closeDialog() {
      // empty the name field when the dialog is closed
      this.configName = ''

      // close the dialog
      this.open = false
    }

    public validateConfigName(): string | boolean {
      return this.hasUniqueName || 'File name already exists'
    }
  }

  export default toNative(ConfigCreate)
</script>
