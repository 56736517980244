<template>
  <v-row>
    <v-col cols="9">
      <div class="text-h5 font-weight-light">Sample lists define manageable group of users</div>

      <div class="text-subtitle-2 text-grey-darken-2 font-weight-light">
        These lists can be used in various places like Task runs, OTA rollouts etc.
      </div>
    </v-col>

    <v-col cols="3" class="d-flex justify-end align-top">
      <v-btn text="Create new" color="primary" @click="createFilter()" />
    </v-col>
  </v-row>

  <v-row>
    <v-col cols="12">
      <FiltersList :env="env" :type="'sample'" :items="sampleFilters" />
    </v-col>
  </v-row>

  <EditFilter :env="env" :data="data" :open="createDialog" @close="createDialog = false" />
</template>

<script lang="ts">
  import { Component, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { SetupStore } from '#stores'

  import { SampleFilter } from '#types'

  @Component
  class FiltersView extends Vue {
    @Prop() public env!: string

    public createDialog = false

    public data: SampleFilter | null = null

    private readonly setupStore = new SetupStore()

    public get sampleFilters() {
      return this.setupStore.sampleFilters
    }

    public get includeArchived() {
      return this.setupStore.includeArchived
    }

    @Watch('env', { immediate: true })
    protected envChanged() {
      this.setupStore.listSampleFilters(this.env)
    }

    @Watch('includeArchived', { immediate: true })
    protected includeArchivedChanged() {
      this.setupStore.listSampleFilters(this.env)
    }

    public createFilter() {
      this.data = {
        name: '',
        type: '',
        data: [],
        tags: '',
        description: '',
        descriptionUrl: '',
        isArchived: false,
      }

      this.createDialog = true
    }
  }

  export default toNative(FiltersView)
</script>
