<template>
  <v-card>
    <v-toolbar :color="hasSelectedConfigs ? 'primary' : 'secondary'">
      <v-toolbar-title v-if="!hasSelectedConfigs">Config files</v-toolbar-title>
      <v-toolbar-title v-else class="text-white">{{ selectedFiles.length }} files selected</v-toolbar-title>

      <v-spacer />

      <v-toolbar-items v-if="hasSelectedConfigs" color="white">
        <v-btn color="white" :disabled="disabled" @click="removeRdataFiles()">
          Remove selected

          <template #append>
            <v-icon>mdi-delete</v-icon>
          </template>
        </v-btn>
      </v-toolbar-items>
      <v-toolbar-items v-else>
        <v-btn :disabled="disabled" @click="(selectedFiles = []), (selectDialog = true)">
          Add config file

          <template #append>
            <v-icon>mdi-plus-thick</v-icon>
          </template>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-data-table
      sort-desc
      show-expand
      single-expand
      expand-on-click
      :loading="isLoading"
      :headers="configHeaders"
      :expanded="expandedFiles"
      :items="studyConfigs"
      :items-per-page="20"
      :items-per-page-options="[
        { title: '20', value: 20 },
        { title: '100', value: 100 },
        { title: 'All', value: -1 },
      ]"
      @update:expanded="expandedFiles = $event.splice(-1)"
    >
      <template #header.actions>
        <v-checkbox
          :disabled="disabled || !config.studyFiles.length"
          :model-value="hasSelectedConfigs && selectedFiles.length === config.studyFiles.length"
          @update:model-value="
            $event ? (selectedFiles = config.studyFiles.map((u: any) => u.id)) : (selectedFiles = [])
          "
        />
      </template>

      <template #item.schedule>
        <v-icon>mdi-clock-outline</v-icon>
      </template>

      <template #item.actions="{ item }">
        <v-checkbox
          :disabled="disabled"
          :model-value="!!selectedFiles.find((s) => s === item.id)"
          @click.stop
          @update:model-value="
            $event ? selectedFiles.push(item.id) : (selectedFiles = selectedFiles.filter((s) => s !== item.id))
          "
        />
      </template>

      <template #item.name="{ item }">
        <span v-if="!isLoading">
          {{ item.name }}
          <v-btn icon="mdi-open-in-new" link :to="`/rdata/configs/${item.id}`" target="_blank" rounded />
        </span>
        <span v-else>Loading...</span>
      </template>

      <template #item.config="{ item }">
        {{ isLoading ? '' : getFileConfiguration(item) }}
      </template>

      <template #item.hw="{ item }">
        {{ isLoading ? '' : getHardwareType(item) }}
      </template>

      <template #item.estimation="{ item }">
        <template v-if="config.memoryLayout">
          {{ recordingTimeEstimate(item.schema, item.data, config.memoryLayout) }}
        </template>
        <template v-else>
          {{ recordingTimeEstimate(item.schema, item.data, memoryLayouts[0]) }} /
          {{ recordingTimeEstimate(item.schema, item.data, memoryLayouts[memoryLayouts.length - 1]) }}
        </template>
      </template>

      <template #expanded-row="{ item }">
        <td :colspan="configHeaders.length + 1">
          <div class="expanded-row py-2 mx-4" style="padding-left: 55px">
            <v-tabs v-model="fileTab">
              <v-tab>Scheduled for</v-tab>
            </v-tabs>

            <v-data-table
              no-data-text="Not scheduled for any user"
              :loading="isLoading"
              :headers="scheduleHeaders"
              :items="config.autoSchedules.filter((s: any) => s.file === item.id)"
              :items-per-page="5"
            >
              <template #item.time="{ item: schedule }">Every day at {{ schedule.time }}</template>

              <template #item.duration="{ item: schedule }">
                {{ schedule.duration ? 'Records for ' + schedule.duration + ' hours' : 'Until memory runs out' }}
              </template>
            </v-data-table>
          </div>
        </td>
      </template>
    </v-data-table>
  </v-card>

  <SelectConfigs :team="config" :open="selectDialog" @cancel="selectDialog = false" @confirm="addRdataFiles($event)" />
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { configHeaders, hwTypes, memoryLayouts, scheduleHeaders } from '#views/rdata/constants'

  import { recordingTimeEstimate } from '#views/rdata/utilities'

  import { AppStore, ProtoStore, RdataStore, TeamsStore } from '#stores'

  import { Study } from '#types'

  @Component
  class ManageConfigs extends Vue {
    @Prop() public config!: Study

    @Prop() public disabled!: boolean

    public fileTab = 0

    public selectDialog = false

    public expandedFiles: any[] = []
    public selectedFiles: any[] = []

    public readonly memoryLayouts = memoryLayouts

    public readonly configHeaders = configHeaders
    public readonly scheduleHeaders = scheduleHeaders

    public readonly recordingTimeEstimate = recordingTimeEstimate

    protected readonly appStore = new AppStore()

    protected readonly rdataStore = new RdataStore()
    protected readonly teamsStore = new TeamsStore()
    protected readonly protoStore = new ProtoStore()

    public get isLoading() {
      return this.rdataStore.loading || this.teamsStore.loading || this.protoStore.loading
    }

    public get configFiles() {
      return this.rdataStore.files
    }

    public get studyConfigs() {
      return this.configFiles.flatMap((configFile) => {
        const editedFile = this.config.studyFiles?.find((editedFile) => editedFile.id === configFile.id)
        if (editedFile) {
          // Add to list
          return [{ ...configFile, addedAt: editedFile.addedAt }]
        } else {
          // Don't add to list
          return []
        }
      })
    }

    public get hasSelectedConfigs() {
      return this.selectedFiles.length > 0
    }

    public addRdataFiles(files: string[]) {
      for (const fileId of files) {
        const file = this.configFiles.find((f) => f.id === fileId)

        this.config!.studyFiles.push({
          id: fileId,
          addedAt: Date.now(),
          collectionId: file.data.data_collections[0].id,
        })
      }

      this.selectDialog = false
    }

    public removeRdataFiles() {
      this.config!.studyFiles = [
        ...this.config!.studyFiles.filter((f: any) => !this.selectedFiles.find((s) => s === f.id)),
      ]

      this.config!.autoSchedules = this.config!.autoSchedules.filter((s: any) => !this.selectedFiles.includes(s.file))

      this.selectedFiles = []
    }

    public getHardwareType(file: any) {
      return (
        file?.data?.hardware_type &&
        hwTypes.find(
          (t) => t.value === file.data.hardware_type.replace('gen2x', 'gen3').replace(/^oreo$/, 'gen4_oreo'),
        )!.title
      )
    }

    public getFileConfiguration(file: any) {
      const slots: any[] = []

      const schema = JSON.parse(file.schema)

      const slotSchema = schema.definitions.measurement_slot_id
      const dataSchema = schema.definitions.measurement_channel_description_id

      for (const type of ['PPG', 'ACM', 'Gyro', 'Temperature']) {
        const count = file.data.data_collections[0].measurements.filter(
          (m: any) =>
            !!m.parameters.find(
              (p: any) =>
                p.length === slotSchema?.properties?.length.default &&
                p.class_id === slotSchema?.properties?.class_id.default &&
                p.param_id === slotSchema?.properties?.param_id.default,
            ) &&
            !!m.parameters.find(
              (p: any) =>
                p.length === dataSchema?.properties?.length.default &&
                p.class_id === dataSchema?.properties?.class_id.default &&
                p.param_id === dataSchema?.properties?.param_id.default &&
                dataSchema?.properties?.value?.oneOf
                  .filter((i: any) => i.title.startsWith(`${type}: `))
                  .map((i: any) => i.const)
                  .includes(p.value),
            ),
        ).length

        slots.push(`${count}x${type}`)
      }

      return slots.join(', ')
    }
  }

  export default toNative(ManageConfigs)
</script>
