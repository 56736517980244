<template>
  <v-dialog v-model="open" persistent width="1000">
    <v-card v-if="!!edited">
      <v-card-title>
        {{ !edited.uid ? 'Create new alert config' : 'Edit alert config' }}
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="edited.name" autofocus label="Name *" />

            <v-text-field v-model="edited.description" label="Description *" />
          </v-col>
        </v-row>

        <v-expand-transition v-if="!!edited.uid">
          <div>
            <p class="text-overline ml-1">Alert rules:</p>

            <RulesList :env="env" :uid="edited.uid" />

            <p class="text-overline ml-1">Add alert rules:</p>

            <div class="mt-3">
              <EditRule :fw="true" :rule="fw" @add-rule="addRule($event)" />

              <EditRule :rule="event" @add-rule="addRule($event)" />
            </div>
          </div>
        </v-expand-transition>

        <v-row v-if="saveErrorText" class="justify-center">
          <v-col cols="12">
            <v-alert type="error">{{ saveErrorText }}</v-alert>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn text="Cancel" class="mr-2" @click="close()" />

        <v-btn
          v-if="!!edited.uid"
          text="Save"
          color="primary"
          :disabled="isLoading || !hasChanges || !edited.name || !edited.description"
          @click="save()"
        />

        <v-btn v-else color="primary" :disabled="!edited.name || !edited.description" @click="save()">
          Create & Move to add rules

          <v-icon class="ml-2">mdi-arrow-right-thick</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { isEqual } from 'lodash-es'

  import { Component, Emit, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { SetupStore } from '#stores'

  import { ScheduleAlert, ScheduleAlertRule } from '#types'

  @Component
  class EditAlert extends Vue {
    @Prop() public env!: string
    @Prop() public open!: boolean

    @Prop() public data!: ScheduleAlert

    public fw = {
      rule: '',
      action: '',
    }

    public event = {
      rule: '',
      action: '',
    }

    public saveErrorText = ''

    public edited: ScheduleAlert | null = null
    public original: ScheduleAlert | null = null

    private readonly setupStore = new SetupStore()

    public get isLoading() {
      return this.setupStore.loading
    }

    public get hasChanges() {
      return !isEqual(this.edited, this.original)
    }

    @Emit('close')
    public close() {
      this.saveErrorText = ''

      return null
    }

    @Watch('data', { immediate: true })
    protected onDataChanged() {
      if (this.data) {
        this.original = { ...this.data }
      } else {
        this.original = {
          name: '',
          description: '',
        }
      }

      this.edited = { ...this.original }
    }

    public async save() {
      this.saveErrorText = ''

      if (!this.edited?.uid) {
        const createResponse = await this.setupStore.createScheduleAlert(this.env, this.edited!)

        if (createResponse?.data?.uid) {
          const detailsResponse = await this.setupStore.fetchScheduleAlert(this.env, createResponse.data.uid)

          if (detailsResponse?.data) {
            this.original = detailsResponse.data as ScheduleAlert

            this.edited = { ...this.original }
          } else {
            this.saveErrorText = 'Unknown error occurred when saving the schedule alert, please report this!'
          }
        } else {
          this.saveErrorText = 'Unknown error occurred when saving the schedule alert, please report this!'
        }
      } else {
        const modifyResponse = await this.setupStore.modifyScheduleAlert(this.env, this.edited!)

        if (!modifyResponse?.data?.uid) {
          this.saveErrorText = 'Unknown error occurred when saving the schedule alert, please report this!'
        } else {
          this.close()
        }
      }
    }

    public async addRule(rule: ScheduleAlertRule) {
      const response = await this.setupStore.addScheduleAlertRule(this.env, { ...rule, uid: this.edited!.uid! })

      if (!response?.data?.uid) {
        this.saveErrorText = 'Unknown error occured when saving the schedule alert, please report this!'
      }
    }
  }

  export default toNative(EditAlert)
</script>
