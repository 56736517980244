<template>
  <v-dialog v-if="open" width="1000" :model-value="open" @update:model-value="close()">
    <v-card style="min-height: 500px" :loading="fetching">
      <v-tabs v-model="tab" show-arrows :disabled="fetching">
        <v-tab @click="loadLogs(0)">Summary task</v-tab>

        <v-tab @click="loadLogs(1)">
          Analyze task

          <v-menu v-if="tasks && tasks.length > 2" offset-y bottom left>
            <template #activator="{ props }">
              <v-btn v-bind="props" class="align-self-center mr-n4">
                <v-icon :color="tab === 1 ? 'purple' : 'grey'">mdi-menu-down</v-icon>
              </v-btn>
            </template>

            <v-list v-model="job" class="grey-lighten-3" style="height: 80vh">
              <v-list-item
                v-for="(task, index) in tasks.slice(1)"
                :key="task.uid"
                :title="'Analyze task ' + (index + 1)"
                @click="loadLogs(tab + index)"
              />
            </v-list>
          </v-menu>
        </v-tab>
      </v-tabs>

      <v-card-text class="pa-0">
        <v-list v-if="logs.length > 0" class="pa-0" style="height: 80vh">
          <v-list-item
            v-if="more"
            class="justify-center"
            title="Load older logs"
            @click="loadLogs(tab ? tab + job : tab, true)"
          />

          <v-list-item v-for="(event, index) in logs" :key="index" style="border-top: 1px solid #cfcfcf">
            <v-row>
              <v-col style="max-width: 225px">
                <div>
                  <span v-if="event && event.timestamp">
                    {{ $dayjs(event.timestamp).format('DD.MM.YYYY - HH:mm:ss.SSS') }}
                  </span>
                </div>
              </v-col>

              <v-col>
                <div>{{ event.message }}</div>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>

        <div v-else class="text-center">No logs available</div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { TasksStore } from '#stores'

  import { BatchJob, Task } from '#types'

  @Component
  class TaskLogs extends Vue {
    @Prop() public task!: Task

    @Prop() public open!: boolean
    @Prop() public source!: string

    public job = 0
    public tab = 0

    private arrayJobSize = 0

    private readonly tasksStore = new TasksStore()

    public get logs() {
      return this.tasksStore.logs
    }

    public get more() {
      return this.tasksStore.more
    }

    public get fetching() {
      return this.tasksStore.fetching
    }

    public get activeTaskDetails() {
      return this.tasksStore.activeTaskDetails
    }

    public get tasks() {
      const tasks: { uid: string; text: string; type: string }[] = []

      const summaryJob = this?.activeTaskDetails?.batchJobs?.find(
        (bj: BatchJob) => bj.jobType === 'poirot-summary-analysis',
      )

      if (summaryJob) {
        tasks.push({
          uid: summaryJob?.uid,
          text: 'Summary Logs',
          type: summaryJob?.jobType,
        })

        if (summaryJob?.jobSettings?.dependsOn?.length > 0) {
          const analyzeJob = this?.activeTaskDetails?.batchJobs?.find(
            (bj: BatchJob) => bj.jobType !== 'poirot-summary-analysis',
          )

          if (analyzeJob) {
            if (!this.arrayJobSize) {
              tasks.push({
                uid: analyzeJob?.uid,
                text: `Analyze Logs`,
                type: analyzeJob?.jobType,
              })
            } else {
              for (let i = 0; i < this.arrayJobSize; i++) {
                tasks.push({
                  uid: analyzeJob?.uid + ':' + i,
                  text: `Analyze Logs ${i + 1}`,
                  type: analyzeJob?.jobType,
                })
              }
            }
          }
        }
      }
      return tasks
    }

    @Emit('close')
    public close() {
      return null
    }

    @Watch('open', { immediate: true })
    protected async openChanged() {
      if (this.open) {
        this.tasksStore.setActiveTask(this.task.uid)

        await this.tasksStore.getTaskDetails(this.source, {
          uid: this.task.uid,
          taskName: this.task.taskName,
        })

        this.loadLogs(this.tab)
      }
    }

    public async loadLogs(index: number, more?: boolean) {
      if (this.tasks) {
        const response = await this.tasksStore.loadLogs(
          this.source,
          {
            jobId: this.tasks[index].uid.split(':')[0],
            jobType: this.tasks[index].type,
            jobIndex: this.tasks[index].uid.split(':')[1],
          },
          more,
        )

        if (response?.arrayProperties?.size) {
          this.arrayJobSize = response?.arrayProperties?.size || 0

          this.loadLogs(index)
        }
      }
    }
  }

  export default toNative(TaskLogs)
</script>
