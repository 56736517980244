<template>
  <v-card ref="cardRef" variant="outlined" :elevation="active ? 1 : 0" style="background: rgba(255, 255, 255, 0.1)">
    <v-list-item class="title" lines="three" :class="{ active: active }" @click="setActive()">
      <div class="mt-n2">
        <div class="text-overline">Task {{ count - index }}</div>

        <v-tooltip v-if="task.info" location="bottom">
          <template #activator="{ props }">
            <div v-bind="props" class="text-truncate text-body-2 text-grey-darken-2" style="max-width: 200px">
              {{ task.info.name || 'Unnamed task' }}
            </div>
          </template>
          <span class="text-break" style="max-width: 200px">{{ task.info.description || 'No description' }}</span>
        </v-tooltip>

        <div class="text-caption text-grey mt-n1">
          {{ $dayjs(task.createdAt).format('HH:mm - D MMM YYYY') }}
        </div>
      </div>

      <template #append>
        <div class="mt-n3 mr-4">
          {{ duration }}
        </div>

        <v-progress-circular
          v-if="task.status !== 'FAILED' && task.status !== 'SUCCEEDED'"
          indeterminate
          size="24"
          class="mt-n3"
          :color="getStatusColor()"
        />

        <v-avatar v-else class="mt-n3" rounded size="24" :color="getStatusColor()" />
      </template>
    </v-list-item>

    <v-list-item lines="two">
      <v-list-item-title class="text-h5 text-black">
        {{ getParameterValue('fwVersions') }}
      </v-list-item-title>

      <v-list-item-subtitle v-if="source !== 'factory'">
        <v-tooltip location="top" :disabled="windowSize > 1500">
          <template #activator="{ props }">
            <span v-bind="props">
              HW: {{ getParameterValue('hwType') }},
              {{ getParameterValue('osSplit') }}

              <span v-if="task.parameters.maxUsers">, Users: {{ getParameterValue('maxUsers') }}</span>
              , Devices: {{ getParameterValue('deviceModels') }}
            </span>
          </template>

          <span>
            HW: {{ getParameterValue('hwType') }}, {{ getParameterValue('osSplit') }}

            <span v-if="task.parameters.maxUsers">, Users: {{ getParameterValue('maxUsers') }}</span>
            , Devices: {{ getParameterValue('deviceModels') }}
          </span>
        </v-tooltip>
      </v-list-item-subtitle>

      <v-list-item-subtitle v-else>
        <v-tooltip location="top" :disabled="windowSize > 1500">
          <template #activator="{ props }">
            <span v-bind="props">
              Factory: {{ getParameterValue('factory') }}, Test phase: {{ getParameterValue('testPhase') }}, Dates:
              {{ $dayjs(getParameterValue('timestampFrom')).format('DD MMM YYYY') }} -
              {{ $dayjs(getParameterValue('timestampTo')).format('DD MMM YYYY') }}
            </span>
          </template>

          <span>
            HW: {{ getParameterValue('hwType') }}, {{ getParameterValue('osSplit') }}

            <span v-if="task.parameters.maxUsers">, Users: {{ getParameterValue('maxUsers') }}</span>
            , Devices: {{ getParameterValue('deviceModels') }}
          </span>
        </v-tooltip>
      </v-list-item-subtitle>

      <v-list-item-subtitle>
        <v-tooltip v-if="getParameterValue('inputCommand') === 'tlog'" location="top" :disabled="windowSize > 1500">
          <template #activator="{ props }">
            <span v-bind="props">
              tlog &lt;jzlog&gt; {{ getParameterValue('inputOptions') }}, {{ fwVersionListName() }} (branch:
              {{ task.parameters.inputCommandBranch }})
            </span>
          </template>
          <span>
            tlog &lt;jzlog&gt; {{ getParameterValue('inputOptions') }}, {{ fwVersionListName() }}(branch:
            {{ task.parameters.inputCommandBranch }})
          </span>
        </v-tooltip>
      </v-list-item-subtitle>

      <v-list-item-subtitle>Sample type: {{ task.parameters.analyzeSampleMode }}</v-list-item-subtitle>
    </v-list-item>

    <v-card-actions class="justify-space-between px-4">
      <div class="text-caption text-trunk text-grey" style="max-width: 200">
        <HoverCopy :text="'UID: ' + task.uid" :data="task.uid" :message="'Copy UID to clipboard'" />
      </div>

      <v-menu location="top end" offset-y offset="12px">
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            class="ml-3"
            rounded="100"
            icon="mdi-dots-vertical"
            color="grey-darken-2"
            variant="outlined"
          />
        </template>

        <v-list>
          <v-list-item
            v-if="task.status !== 'PENDING'"
            link
            title="Show task runtime logs"
            prepend-icon="mdi-text"
            @click="showLogs = true"
          />

          <v-list-item
            v-if="task.status !== 'FAILED' && task.status !== 'SUCCEEDED'"
            link
            color="error"
            class="text-error"
            prepend-icon="mdi-cancel"
            title="Abort / cancel unfinished task"
            @click="cancelDialog = true"
          />

          <v-list-item
            v-if="task?.info?.preprocessingOutput?.generateUsersSample"
            link
            class="text-error"
            prepend-icon="mdi-replay"
            title="Rerun the task with same sample"
            @click="rerunTask()"
          />
        </v-list>
      </v-menu>
    </v-card-actions>
  </v-card>

  <v-dialog v-model="cancelDialog" max-width="500px">
    <v-card>
      <v-card-title class="text-h5">Are you sure you want to cancel this task?</v-card-title>

      <v-card-text v-if="cancelError">Cancel task occur an error!</v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />

        <v-btn text="Cancel" class="mr-2" @click="cancelDialog = false" />

        <v-btn text="Confirm" color="primary" @click="cancelTask()" />
      </v-card-actions>
    </v-card>
  </v-dialog>

  <TaskLogs :open="showLogs" :task="task" :source="source" @close="showLogs = false" />

  <CreateTask :open="confirmDialog" :mode="'rerun'" :task="taskRerun" :source="source" @close="confirmDialog = false" />
</template>

<script lang="ts">
  import { VCard } from 'vuetify/components'

  import { Component, Prop, Ref, Vue, toNative } from 'vue-facing-decorator'

  import { getParamValue } from '#views/tasks/utilities'

  import { SetupStore, TasksStore } from '#stores'

  import { Task, TaskParameters, TaskSettings } from '#types'

  @Component
  class TaskItem extends Vue {
    @Prop() public task!: Task
    @Prop() public index!: number
    @Prop() public source!: string
    @Prop() public active!: boolean

    @Ref('cardRef') public readonly cardRef!: VCard

    public showLogs = false
    public cancelError = false
    public cancelDialog = false
    public confirmDialog = false

    public windowSize = window.innerWidth

    public taskRerun: TaskSettings | null = null

    private readonly tasksStore = new TasksStore()
    private readonly setupStore = new SetupStore()

    public get count() {
      return this.tasksStore.count
    }

    public get sampleFilters() {
      return this.setupStore.sampleFilters
    }

    public get duration() {
      const d = this.$dayjs.duration(
        new Date(this.task.stoppedAt || Date.now()).getTime() - new Date(this.task.createdAt || Date.now()).getTime(),
      )

      return `${d.hours()}h ${d.minutes()}m`
    }

    public get daysLimitPrefs() {
      return parseInt(this.task.parameters.inputDays!)
    }

    public mounted() {
      if (this.active) {
        this.$nextTick(() => {
          this.cardRef.$el.scrollIntoView({ behavior: 'smooth', block: 'center' })
        })
      }
    }

    public setActive() {
      this.tasksStore.setActiveTask(this.task.uid)
    }

    public rerunTask() {
      this.tasksStore.setActiveTask(this.task.uid)

      const sample = this.task.info?.preprocessingOutput?.generateUsersSample

      const taskData: TaskSettings = {
        info: { ...this.task.info },
        parameters: { ...this.task.parameters, ...sample },
        overrideSteps: ['generateUsersSample'],
      }

      this.taskRerun = taskData

      this.confirmDialog = true
    }

    public async cancelTask() {
      const cancelRes = await this.tasksStore.cancelTask(this.source, this.task)

      if (!cancelRes.data) {
        this.cancelError = true
      } else {
        this.cancelDialog = false
      }
    }

    public getStatusColor() {
      return this.task?.status === 'SUCCEEDED'
        ? 'green-lighten-3'
        : this.task?.status === 'FAILED'
          ? 'red-lighten-3'
          : this.task?.startedAt
            ? 'green-lighten-3'
            : 'amber-lighten-3'
    }

    public fwVersionListName(): string | undefined {
      const fwVersionListId = this.getParameterValue('fwVersionListId')
      return this.sampleFilters.find((item) => item.uid === fwVersionListId)?.name
    }

    public getParameterValue(name: keyof TaskParameters) {
      return getParamValue(name, this.task?.parameters)
    }
  }

  export default toNative(TaskItem)
</script>

<style lang="scss" scoped>
  :deep(.v-list-item.title) {
    background: rgba(var(--v-theme-secondary), 0.3);
  }

  :deep(.v-list-item.active) {
    background: rgba(var(--v-theme-secondary), 0.6);
  }

  :deep(.v-list-item-subtitle) {
    -webkit-line-clamp: 1;
  }
</style>
