<template>
  <div>
    <v-row align="center" class="mb-n8">
      <v-col v-if="!['upload', 'scheduled'].includes(mode) && source !== 'factory'">
        <v-text-field
          persistent-placeholder
          label="Targeted firmwares"
          hint="Max 5, separated by comma"
          placeholder="Type FW versions..."
          :model-value="task.parameters.fwVersions ? task.parameters.fwVersions.trim() : ''"
          @update:model-value="
            task.parameters.fwVersions = $event.trim().replace(/\s/g, ',').replace(/;/g, ',').replace(/,+/g, ',')
          "
        />
      </v-col>

      <v-col v-if="mode === 'random'">
        <v-text-field
          persistent-placeholder
          label="Device models"
          placeholder="e.g. Google Pixel 6;..."
          hint="Separated by semicolon"
          :model-value="task.parameters.deviceModels ? task.parameters.deviceModels.join(';') : ''"
          @update:model-value="
            task.parameters.deviceModels = $event
              .split(';')
              .map((x: string) => x.trim())
              .filter(Boolean)
          "
        />
      </v-col>

      <v-col v-if="mode === 'select' && source !== 'factory'">
        <v-select
          v-model="task.parameters.whitelistedFilters"
          clearable
          persistent-placeholder
          item-title="name"
          item-value="uid"
          label="Include rings / users"
          placeholder="Select sample list..."
          :items="taskSampleFilters"
          :hint="
            task.parameters.whitelistedFilters
              ? 'type: ' + getSampleFilterName(task.parameters.whitelistedFilters, 'type')
              : ''
          "
          @click:clear="task.parameters.whitelistedFilters = ''"
        />
      </v-col>

      <template v-if="mode === 'select' && source === 'factory'">
        <v-col>
          <v-select
            v-model="task.parameters.factory"
            label="Factory"
            :items="factories.filter((f) => f.value !== '')"
          />
        </v-col>

        <v-col>
          <v-select v-model="task.parameters.testPhase" label="Test Phase" :items="testPhases" />
        </v-col>
      </template>

      <v-col v-if="!['rerun', 'upload', 'scheduled'].includes(mode) && source !== 'factory'">
        <v-select
          v-model="task.parameters.blacklistedFilters"
          clearable
          persistent-placeholder
          item-title="name"
          item-value="uid"
          label="Exclude rings / users"
          placeholder="Select sample lists..."
          :items="taskSampleFilters"
          @click:clear="delete task.parameters.blacklistedFilters"
        />
      </v-col>

      <template v-if="mode === 'upload'">
        <v-col cols="6">
          <v-file-input
            persistent-hint
            persistent-placeholder
            class="ml-2 mb-4"
            density="compact"
            label="File input (required)"
            placeholder="No file selected..."
            :accept="task.parameters.inputCommand === 'tlog' ? '.json,.csv' : '.csv'"
            :hint="
              task.parameters.inputCommand === 'tlog'
                ? 'File needs to be in CSV or JSON format'
                : 'File needs to be in CSV format'
            "
            :model-value="task.file ? [task.file] : undefined"
            @update:model-value="task.file = $event as File"
          />
        </v-col>

        <v-col cols="3">
          <v-tooltip location="bottom">
            <template #activator="{ props }">
              <div class="text-no-wrap mt-n8">
                <v-icon v-bind="props" color="primary">mdi-information</v-icon>
                JSON example
              </div>
            </template>

            <span>
              <pre>{{ jsonSample }}</pre>
            </span>
          </v-tooltip>
        </v-col>

        <v-col v-if="mode === 'upload'" cols="3">
          <v-tooltip location="bottom">
            <template #activator="{ props }">
              <div class="text-no-wrap mt-n8">
                <v-icon v-bind="props" color="primary" dark>mdi-information</v-icon>
                CSV example
              </div>
            </template>

            <span v-if="task.parameters.inputCommand === 'tlog'">
              439bd3e2-a5d6-4401-90b2-5b09061987ea,2.50.4,gen2,ios,production
            </span>
            <span v-else>
              <p class="mb-0 pb-0">user_id,start_t,end_t</p>
              <p>2eafb6f3-2254-4636-a1c0-272fe81c2813,2.2.2022,2.2.2022</p>
            </span>
          </v-tooltip>
        </v-col>
      </template>

      <v-col v-if="mode === 'scheduled'" class="d-flex">
        Sample settings / type is defined by the scheduled task configuration

        <v-spacer />

        <v-btn variant="outlined" color="primary" @click="$router.push(`/setup#schedules`)">
          <v-icon class="mr-2">mdi-cog</v-icon>

          Edit scheduled tasks configuration
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="mode === 'random'" class="mb-n4">
      <v-col>
        <v-select v-model="task.parameters.hwType" hide-details label="HW type" :items="hwTypes" />
      </v-col>

      <v-col>
        <v-select v-model="task.parameters.osSplit" hide-details label="OS type" :items="osSelections" />
      </v-col>

      <v-col>
        <v-combobox
          v-model="task.parameters.maxUsers"
          hide-details
          type="number"
          label="Max users"
          :items="countItems"
          :rules="[maxCount]"
        />
      </v-col>
    </v-row>

    <v-row v-if="mode === 'select' && source === 'factory'">
      <v-col>
        <v-menu
          v-model="startDateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template #activator="{ props }">
            <v-text-field
              v-model="startDateFormatted"
              label="Start date"
              prepend-icon="mdi-calendar"
              style="text-align: right"
              hide-details="auto"
              v-bind="props"
            />
          </template>
          <v-date-picker
            no-title
            hide-actions
            :model-value="task.parameters.timestampFrom ? new Date(task.parameters.timestampFrom) : undefined"
            @update:model-value="
              (task.parameters.timestampFrom = $dayjs($event).format('YYYY-MM-DDTHH:mm:ss+00:00')),
                (startDateMenu = false)
            "
          />
        </v-menu>
      </v-col>

      <v-col>
        <v-menu
          v-model="endDateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template #activator="{ props }">
            <v-text-field
              v-model="endDateFormatted"
              label="End date"
              prepend-icon="mdi-calendar"
              style="text-align: right"
              hide-details="auto"
              v-bind="props"
            />
          </template>
          <v-date-picker
            no-title
            hide-actions
            :model-value="task.parameters.timestampTo ? new Date(task.parameters.timestampTo) : undefined"
            @update:model-value="
              (task.parameters.timestampTo = $dayjs($event).format('YYYY-MM-DDTHH:mm:ss+00:00')), (endDateMenu = false)
            "
          />
        </v-menu>
      </v-col>
    </v-row>

    <v-row class="my-n2">
      <v-col v-if="source !== 'factory'">
        <v-checkbox
          hide-details
          :model-value="!!task.parameters.blacklistedLabels"
          @update:model-value="
            $event
              ? (task.parameters.blacklistedLabels = 'outo_custom:poirot_exclude')
              : delete task.parameters.blacklistedLabels
          "
        >
          <template #label>
            <div class="ml-2">Exclude users with testing labels</div>
          </template>
        </v-checkbox>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { TaskHelpers } from '#common/TaskHelpers.vue'

  import { hwTypes, jsonSample, osSelections, testPhases } from '#views/tasks/constants'
  import { factories } from '#views/tests/constants'

  import { SetupStore } from '#stores'

  import { TaskSettings } from '#types'

  @Component
  class TaskConfig extends mixins(TaskHelpers) {
    @Prop() public mode!: string
    @Prop() public source!: string

    @Prop() public task!: TaskSettings

    public endDateMenu = false
    public startDateMenu = false

    public endDateFormatted = ''
    public startDateFormatted = ''

    public hwTypes = hwTypes
    public jsonSample = jsonSample
    public osSelections = osSelections

    public readonly factories = factories
    public readonly testPhases = testPhases

    private readonly setupStore = new SetupStore()

    public get countItems() {
      return parseInt(this.task.parameters.maxUsers) <= 500 ? [100, 250, 500] : [100, 1000, 2500, 5000, 10000]
    }

    public get taskSampleFilters() {
      return this.setupStore.sampleFilters.filter((f: any) => f.type !== 'fw_version')
    }

    @Watch('mode', { immediate: true })
    protected modeChanged() {
      this.task.parameters.analyzeSampleMode = this.mode

      if (this.mode !== 'rerun') {
        if (this.mode !== 'random') {
          delete this.task.parameters.hwType
          delete this.task.parameters.osSplit
          delete this.task.parameters.deviceModels
        } else {
          if (!this.task.parameters.hwType) {
            this.task.parameters.hwType = 'gen2'
          }

          if (!this.task.parameters.osSplit) {
            this.task.parameters.osSplit = 'ios:50'
          }

          if (!this.task.parameters.deviceModels) {
            this.task.parameters.deviceModels = []
          }
        }

        if (this.mode !== 'select') {
          delete this.task.parameters.whitelistedFilters
        }

        if (this.mode === 'upload') {
          delete this.task.parameters.blacklistedFilters
        }
      }
    }

    @Watch('task.parameters.timestampTo', { immediate: true })
    protected timestampToChanged() {
      this.endDateFormatted = this.task.parameters.timestampTo
        ? this.$dayjs(this.task.parameters.timestampTo).format('YYYY-MM-DD')
        : ''
    }

    @Watch('task.parameters.timestampFrom', { immediate: true })
    protected timestampFromChanged() {
      this.startDateFormatted = this.task.parameters.timestampFrom
        ? this.$dayjs(this.task.parameters.timestampFrom).format('YYYY-MM-DD')
        : ''
    }

    public maxCount(value: number) {
      return (
        value <= parseInt(this.task.parameters.maxUsers) || `Excess max users limit (${this.task.parameters.maxUsers})`
      )
    }
  }

  export default toNative(TaskConfig)
</script>
