<template>
  <!-- eslint-disable vue/v-on-handler-style -->

  <v-container>
    <v-row>
      <v-col cols="9">
        <div class="text-h5 font-weight-light">Study groups for the research data collections</div>

        <div class="text-subtitle-2 text-grey-darken-2 font-weight-light">
          <template v-if="isResearchAdmin">
            Your research data admin rights allow you to view and edit all studies
          </template>
          <template v-else>You can view all studies, but only edit the ones you are manager of</template>
        </div>
      </v-col>

      <v-col cols="3" class="d-flex justify-end align-top">
        <v-btn text="Create new study" color="primary" @click="createDialog = true" />
      </v-col>
    </v-row>

    <v-row v-if="appFlavorFilters.length > 0">
      <v-col cols="12">
        <v-chip
          v-for="item in appFlavorFilters"
          :key="item.value"
          closable
          class="mr-2"
          @click:close="searchFilters = searchFilters.filter((f) => f !== item.value)"
        >
          {{ item.title }}
        </v-chip>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-sheet>
          <v-data-table
            :search="search"
            :loading="loading"
            :headers="studyHeaders"
            :items="filteredStudies"
            :items-per-page="100"
            :items-per-page-options="[
              { title: '100', value: 100 },
              { title: '500', value: 500 },
              { title: '1000', value: 1000 },
            ]"
            :custom-filter="filterStudyGroups"
            @click:row="(_event: any, row: any) => openEditor(row.item)"
          >
            <template #item.appFlavor="{ item }">
              <span>
                {{ appFlavors.find((f: any) => f.value === item.appFlavor).title }}
              </span>
            </template>

            <template #item.studyUsers="{ item }">
              <span>{{ item.studyUsers.length }}</span>
            </template>

            <template #item.studyFiles="{ item }">
              <span>{{ item.studyFiles.length }}</span>
            </template>

            <template #item.fwVersion="{ item }">
              <span>{{ item.fwVersion || 'Any' }}</span>
            </template>

            <template #item.ringSizes="{ item }">
              <span>{{ item.ringSizes.join(',') || 'Any' }}</span>
            </template>

            <template #item.createdAt="{ item }">
              <span>
                {{ item.createdAt ? $dayjs(item.createdAt.toDate()).format('DD MMM YYYY') : '' }}
              </span>
            </template>

            <template #item.updatedAt="{ item }">
              <span>
                {{ item.updatedAt ? $dayjs(item.updatedAt.toDate()).format('HH:mm - DD MMM YYYY') : '' }}
              </span>
            </template>

            <template #item.actions="{ item }">
              <v-menu left offset-y>
                <template #activator="{ props }">
                  <v-btn v-bind="props" class="mx-n2" icon="mdi-dots-vertical" @click.stop.prevent />
                </template>

                <v-list>
                  <v-list-item
                    class="text-error"
                    title="Delete study"
                    prepend-icon="mdi-delete"
                    :disabled="
                      !isResearchAdmin && item.createdBy !== userEmail && !item.managerEmails.includes(userEmail)
                    "
                    @click="deleteStudyGroup(item)"
                  />
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>

  <StudyCreate
    :open="createDialog"
    :create-label-error="createLabelError"
    @create="createStudyGroup($event)"
    @close="closeStudyCreateDialog()"
  />
</template>

<script lang="ts">
  import { kebabCase } from 'lodash-es'

  import { Component, Model, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { appFlavors, studyHeaders } from '#views/rdata/constants'

  import { AppStore, OtaStore, TeamsStore } from '#stores'

  import { Rollout, Study } from '#types'

  @Component
  class RdataView extends Vue {
    @Prop() public search!: string

    @Model({ name: 'filters' }) public searchFilters!: string[]

    public createDialog = false
    public createLabelError = ''

    public filteredStudies: Study[] = []

    public readonly appFlavors = appFlavors
    public readonly studyHeaders = studyHeaders

    private readonly appStore = new AppStore()
    private readonly teamsStore = new TeamsStore()
    private readonly otaStore = new OtaStore()

    public get loading() {
      return this.teamsStore.loading
    }

    public get userEmail() {
      return this.appStore.user.email
    }

    public get studyGroups() {
      return this.teamsStore.teams.filter((t: Study) => this.appFlavors.find((f: any) => f.value === t.appFlavor))
    }

    public get appFlavorFilters() {
      return this.appFlavors.filter((t: any) => this.searchFilters?.includes(t.value))
    }

    public get isResearchAdmin() {
      return this.appStore.isResearchDataAdmin
    }

    public get rollouts() {
      return this.otaStore.rollouts
    }

    @Watch('appFlavorFilters', { immediate: true })
    public appFlavorFiltersChanged() {
      this.updateStudyList()
    }

    @Watch('studyGroups', { immediate: true })
    public studyGroupsChanged() {
      this.updateStudyList()
    }

    @Watch('search', { immediate: true })
    public searchChanged() {
      this.updateStudyList()
    }

    public async mounted() {
      this.updateStudyList()

      await this.otaStore.listRollouts()
    }

    public updateStudyList() {
      let filteredStudies = this.studyGroups

      // filter by app flavor
      if (this.appFlavorFilters.length > 0) {
        filteredStudies = filteredStudies.filter((study: Study) =>
          this.appFlavorFilters.find((f: any) => f.value === study.appFlavor),
        )
      }

      // filter by search text
      if (this.search) {
        filteredStudies = filteredStudies.filter((study: Study) => {
          return this.filterStudyGroups('', this.search, { raw: study })
        })
      }

      this.filteredStudies = filteredStudies
    }

    public openEditor(row: any) {
      this.$router.push({ path: `/rdata/studies/${row.id}` }).catch(() => {})
    }

    public async createStudyGroup(config: { studyName: string; appFlavor: string }) {
      const id = kebabCase(config.studyName)

      const response = await this.teamsStore.createLabel(config.appFlavor, id)

      if (response.status === 200) {
        await this.teamsStore.createTeam({
          id,
          name: config.studyName,
          appFlavor: config.appFlavor,
          type: 'standard',
          fwVersion: '',
          ringSizes: [],
          studyFiles: [],
          studyUsers: [],
          autoSchedules: [],
        })

        this.$router.push({ path: `/rdata/studies/${id}` }).catch(() => {})
      } else if (response.status === 409) {
        this.createLabelError = 'A label with this name already exists. Please choose a different name'
      } else {
        this.createLabelError = 'An error occurred while creating the label. Please try again after a while'
      }
    }

    public closeStudyCreateDialog() {
      this.createDialog = false

      this.createLabelError = ''
    }

    public deleteStudyGroup(study: Study) {
      this.$confirm('Confirm study delete?', `${study.name}`, {
        buttonTrueColor: 'error',
      }).then(async (confirmed) => {
        if (confirmed) {
          const response = await this.teamsStore.deleteLabel(study.appFlavor, study.id)

          if (response.status === 204) {
            await this.archiveStudyRollouts(study)

            await this.teamsStore.deleteTeam(study)
          }
        }
      })
    }

    public async archiveStudyRollouts(study: Study) {
      const studyRollouts = study?.rollouts ?? {}

      if (Object.keys(studyRollouts).length > 0) {
        for (const hwType of Object.keys(studyRollouts)) {
          const rolloutsByHwType = this.rollouts[hwType] ?? []

          const rolloutsToArchive = rolloutsByHwType.filter((rollout: Rollout) => {
            return studyRollouts[hwType].includes(rollout.slug) && rollout.state !== 'archived'
          })

          if (rolloutsToArchive.length > 0) {
            for (const rollout of rolloutsToArchive) {
              await this.otaStore.changeState({ state: 'archived', rollout: rollout })
            }
          }
        }
      }
    }

    public filterStudyGroups(_value: string, search: string, item: any) {
      return (
        !!item.raw.managerEmails?.includes(search.toLowerCase()) ||
        !!item.raw.name?.toLowerCase().includes(search.toLowerCase()) ||
        !!item.raw.createdBy?.toLowerCase().includes(search.toLowerCase()) ||
        !!item.raw.studyFiles?.find((f: any) => f.id.includes(search.toLowerCase())) ||
        !!item.raw.studyUsers?.find((u: any) => u.id.includes(search.toLowerCase()))
      )
    }
  }

  export default toNative(RdataView)
</script>
