<template>
  <v-dialog :model-value="open" width="1000" @after-leave="close()">
    <v-card :loading="isLoading">
      <v-card-title>
        Compare factory tests (max 10 factory tests)

        <v-spacer />

        <v-btn icon="mdi-close" @click="close()" />
      </v-card-title>

      <span v-if="!isLoading && !compareTests.length">The selected compared tests were not found</span>

      <v-card-text v-if="compareTests.length" class="pt-0">
        <div style="min-width: 3200px">
          <v-data-table hide-default-footer class="compare" :headers="headers" :items="compareTests">
            <template #item.testStart="{ item }">
              <div v-if="item.testStart" class="text-no-wrap">
                {{ $dayjs(item.testStart).format('YYYY-MM-DD HH:mm') }}
              </div>
            </template>

            <template #item.testReady="{ item }">
              <div v-if="item.testReady" class="text-no-wrap">
                {{ $dayjs(item.testReady).format('YYYY-MM-DD HH:mm') }}
              </div>
            </template>

            <template #item.durationSeconds="{ item }">
              <div v-if="item.durationSeconds" class="text-no-wrap">
                {{ Math.round(item.durationSeconds) }}
              </div>
            </template>
          </v-data-table>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { testsHeaders } from '#views/tests/constants'

  import { TestsStore } from '#stores'

  @Component
  class CompareTests extends Vue {
    @Prop() public open!: boolean

    public index = 2

    public readonly headers = testsHeaders

    private readonly testsStore = new TestsStore()

    public get isLoading() {
      return this.testsStore.loading
    }

    public get compareTests() {
      return this.testsStore.compareTests
    }

    @Emit('close')
    public close() {
      return null
    }
  }

  export default toNative(CompareTests)
</script>

<style lang="scss">
  .compare thead,
  .compare tbody {
    float: left;
  }

  .compare table td {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 300px;
    max-width: 300px;
    overflow: auto;
    min-height: 56px;
    max-height: 56px;
  }

  .compare table th {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 200px;
    max-width: 200px;
  }

  .compare table tr {
    display: flex;
    flex-direction: column;
    float: left;
    overflow: auto;
  }

  .compare table thead tr th {
    justify-content: start !important;
  }

  /* fix for the missing border on last column*/
  .compare table tbody tr td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
</style>
