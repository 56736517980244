<template>
  <div ref="chartRef" class="view pa-3" style="height: 500px" />
</template>

<script lang="ts">
  import { isEqual } from 'lodash-es'

  import { Component, Prop, Ref, Vue, Watch, toNative } from 'vue-facing-decorator'

  import * as am4core from '@amcharts/amcharts4/core'

  import * as am4charts from '@amcharts/amcharts4/charts'

  import am4themes_animated from '@amcharts/amcharts4/themes/animated'

  @Component
  class WarrantyChart extends Vue {
    @Prop() public chartData!: any

    @Ref('menuRef') public readonly menuRef!: HTMLElement
    @Ref('chartRef') public readonly chartRef!: HTMLElement

    @Watch('chartData')
    protected chartDataChanged(val: any, oldVal: any) {
      if (val && !isEqual(val, oldVal)) {
        this.updateData()
      }
    }

    public mounted() {
      this.updateData()
    }

    private updateData() {
      am4core.options.autoDispose = true

      am4core.useTheme(am4themes_animated)

      let chart = am4core.create(this.chartRef, am4charts.XYChart)

      const xAxes = this.chartData.xAxes

      let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis())
      categoryAxis.renderer.grid.template.location = 0
      categoryAxis.dataFields.category = xAxes
      categoryAxis.renderer.minGridDistance = 1
      categoryAxis.renderer.inversed = true
      categoryAxis.renderer.grid.template.disabled = true

      let label = categoryAxis.renderer.labels.template
      label.truncate = true
      label.maxWidth = 350

      let valueAxis = chart.xAxes.push(new am4charts.ValueAxis())
      valueAxis.renderer.labels.template.disabled = true
      valueAxis.renderer.grid.template.disabled = true
      valueAxis.renderer.minGridDistance = 40

      let series = chart.series.push(new am4charts.ColumnSeries())
      series.dataFields.categoryY = xAxes
      series.dataFields.valueX = 'count'
      series.tooltipText = '{valueX.value}'
      series.columns.template.strokeOpacity = 0
      series.columns.template.column.cornerRadiusBottomRight = 5
      series.columns.template.column.cornerRadiusTopRight = 5

      let labelBullet = series.bullets.push(new am4charts.LabelBullet())
      labelBullet.label.horizontalCenter = 'left'
      labelBullet.label.dx = 10
      labelBullet.label.text = '{valueX.value}'
      labelBullet.locationX = 1

      series.columns.template.adapter.add('fill', function (fill, target: any) {
        return chart.colors.getIndex(target.dataItem.index)
      })

      categoryAxis.sortBySeries = series

      chart.cursor = new am4charts.XYCursor()

      chart.responsive.enabled = true

      chart.data = this.chartData.data
    }
  }

  export default toNative(WarrantyChart)
</script>

<style lang="scss">
  .view {
    width: 100%;
    min-height: 400px;
  }
</style>
