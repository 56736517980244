<template>
  <!-- eslint-disable vue/v-on-handler-style -->
  <v-container>
    <v-row>
      <v-col cols="9">
        <div class="text-h5 font-weight-light">Ring data collection configuration files for studies</div>

        <div class="text-subtitle-2 font-weight-light text-grey-darken-2">
          <template v-if="isResearchAdmin">
            Your research data admin rights allow you to view and edit all files
          </template>
          <template v-else>You can view all files, but only edit the ones you have created</template>
        </div>
      </v-col>

      <v-col class="d-flex justify-end align-top" cols="3">
        <v-btn text="Create new config" color="primary" @click="createDialog = true" />
      </v-col>
    </v-row>

    <v-row v-if="hwTypesFilters.length > 0">
      <v-col cols="12">
        <v-chip
          v-for="item in hwTypesFilters"
          :key="item.value"
          closable
          class="mr-2"
          @click:close="searchFilters = searchFilters.filter((f) => f !== item.value)"
        >
          {{ item.title }}
        </v-chip>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-sheet>
          <v-data-table
            :search="search"
            :loading="isLoading"
            :headers="fileHeaders"
            :items="filteredRdataFiles"
            :items-per-page="100"
            :items-per-page-options="[
              { title: '100', value: 100 },
              { title: '500', value: 500 },
              { title: '1000', value: 1000 },
            ]"
            :custom-filter="filterRdataFiles"
            @click:row="(_event: any, row: any) => openFileEditor(row.item)"
          >
            <template #item.name="{ item }">
              <span>{{ item.name || '(File has no name)' }}</span>
            </template>

            <template #item.data.hardware_type="{ item }">
              <span>
                {{
                  hwTypes.find(
                    (t) => t.value === item.data.hardware_type.replace('gen2x', 'gen3').replace(/^oreo$/, 'gen4_oreo'),
                  )?.title || 'Unknown'
                }}
              </span>
            </template>

            <template #item.createdAt="{ item }">
              <span>
                {{ item.createdAt ? $dayjs(item.createdAt.toDate()).format('DD MMM YYYY') : '' }}
              </span>
            </template>

            <template #item.updatedAt="{ item }">
              <span>
                {{ item.updatedAt ? $dayjs(item.updatedAt.toDate()).format('HH:mm - DD MMM YYYY') : '' }}
              </span>
            </template>

            <template #item.actions="{ item }">
              <v-menu left offset-y>
                <template #activator="{ props }">
                  <v-btn v-bind="props" class="mx-n2" icon="mdi-dots-vertical" @click.stop.prevent />
                </template>

                <v-list>
                  <v-list-item
                    class="text-info"
                    title="Duplicate file"
                    prepend-icon="mdi-content-copy"
                    @click="duplicateRdataFile(item)"
                  />

                  <v-list-item
                    v-if="item.isArchived !== true"
                    class="text-warning"
                    title="Archive file"
                    prepend-icon="mdi-archive"
                    @click="archiveRdataFile(item)"
                  />

                  <v-list-item
                    v-if="item.isArchived === true"
                    class="text-success"
                    title="Unarchive file"
                    prepend-icon="mdi-package-up"
                    @click="unArchiveRdataFile(item)"
                  />
                  <v-list-item
                    class="text-error"
                    title="Delete file"
                    prepend-icon="mdi-delete"
                    @click="deleteRdataFile(item)"
                  />
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>

    <v-dialog :model-value="!!errorText" width="500">
      <v-card>
        <v-card-title>{{ errorText }}</v-card-title>

        <v-card-text>
          <div class="mb-4">
            Because the file is being used in the following {{ studiesInUse.length > 1 ? 'studies:' : 'study:' }}
          </div>

          <div v-for="study in studiesInUse" :key="study.id">
            <v-list-item class="text-primary" :title="study.name" @click="openStudyEditor(study)" />
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn text="Close" class="mr-2" @click="errorText = ''" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>

  <ConfigCreate v-model="createDialog" :filters="searchFilters" @create="createRdataFile($event)" />
</template>

<script lang="ts">
  import { v4 as uuid } from 'uuid'

  import { cloneDeep, snakeCase } from 'lodash-es'

  import { Component, Model, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { Debounce } from '@jouzen/outo-apps-toolkit'

  import { fileHeaders, hwTypes } from '#views/rdata/constants'

  import { AppStore, RdataStore, TeamsStore } from '#stores'

  import { RdataFile } from '#types'

  @Component
  class RdataList extends Vue {
    @Prop() public search!: string
    @Prop() public includeArchived!: boolean

    @Model({ name: 'filters' }) public searchFilters!: string[]

    public errorText = ''

    public createDialog = false

    public studiesInUse: any[] = []
    public filteredRdataFiles: any[] = []

    public readonly hwTypes = hwTypes
    public readonly fileHeaders = fileHeaders

    protected readonly appStore = new AppStore()

    protected readonly rdataStore = new RdataStore()
    protected readonly teamsStore = new TeamsStore()

    public get isLoading() {
      return this.rdataStore.loading
    }

    public get rdataFiles() {
      return this.rdataStore.files
    }

    public get studyGroups() {
      return this.teamsStore.teams
    }

    public get hwTypesFilters() {
      return hwTypes.filter((t) => this.searchFilters.includes(t.value))
    }

    public get isResearchAdmin() {
      return this.appStore.isResearchDataAdmin
    }

    @Watch('search')
    protected searchChanged() {
      this.updateConfigList()
    }

    @Watch('rdataFiles')
    protected rdataFilesChanged() {
      this.updateConfigList()
    }

    @Watch('hwTypesFilters')
    protected hwTypesFiltersChanged() {
      this.updateConfigList()
    }

    @Watch('includeArchived')
    protected includeArchivedChanged() {
      this.updateConfigList()
    }

    public beforeMount() {
      this.updateConfigList()
    }

    public openFileEditor(row: any) {
      this.$router.push({ path: `/rdata/configs/${row.id}` }).catch(() => {})
    }

    public openStudyEditor(row: any) {
      // Handle navigation to the study editor
      this.$router.push({ path: `/rdata/studies/${row.id}` }).catch(() => {})
    }

    public createRdataFile(config: any) {
      const id = uuid()

      this.createDialog = false

      this.rdataStore.createFile({
        id,
        name: config.name,
        schema: JSON.stringify(config.schema),
        isArchived: false,
        data: {
          format: config.schema?.properties?.format?.default,
          date: this.$dayjs().format('YYYY-MM-DD'),
          hardware_type: config.schema?.properties?.hardware_type?.default,
          data_collections: [
            {
              id: snakeCase(config.name),
              name: config.name,
              description: '',
              measurements: [],
            },
          ],
        },
      })

      this.$router.push({ path: `/rdata/configs/${id}` }).catch(() => {})
    }

    public deleteRdataFile(file: any) {
      this.errorText = ''

      const studiesInUse = this.studyGroups.filter((study) =>
        study.studyFiles.some((studyFile: any) => studyFile.id === file.id),
      )

      if (studiesInUse.length > 0) {
        // File is used in multiple studies - show error
        this.errorText = 'Cannot delete file'

        this.studiesInUse = studiesInUse
      } else {
        this.$confirm('Confirm file delete?', 'Are you sure you want to delete this file?', {
          buttonTrueColor: 'error',
        }).then((confirmed) => {
          if (confirmed) {
            this.rdataStore.deleteFile(file)
          }
        })
      }
    }

    // Archive file only if it does not belong to any study groups
    public archiveRdataFile(file: any) {
      this.errorText = ''

      const studiesInUse = this.studyGroups.filter((study) =>
        study.studyFiles.some((studyFile: any) => studyFile.id === file.id),
      )

      if (studiesInUse.length > 0) {
        // File is used in multiple studies - show error
        this.errorText = 'Cannot archive file'

        this.studiesInUse = studiesInUse
      } else {
        const modifiedFile = {
          ...file,
          isArchived: true,
        }

        this.$confirm('Confirm file archive?', 'Are you sure you want to archive this file?', {
          buttonTrueColor: 'warning',
        }).then((confirmed) => {
          if (confirmed) {
            this.rdataStore.updateFile(modifiedFile)
          }
        })
      }
    }

    // Unarchive file
    public unArchiveRdataFile(file: any) {
      const modifiedFile = {
        ...file,
        isArchived: false,
      }

      this.$confirm('Confirm file unarchive?', 'Are you sure you want to unarchive this file?', {
        buttonTrueColor: 'success',
      }).then((confirmed) => {
        if (confirmed) {
          this.rdataStore.updateFile(modifiedFile)
        }
      })
    }

    public duplicateRdataFile(file: any) {
      const clonedFile = cloneDeep(file)

      clonedFile.id = uuid()
      clonedFile.name = `${file.name} (copy)`
      clonedFile.data.data_collections[0].id = `${file.data.data_collections[0].id}_copy`
      clonedFile.data.data_collections[0].name = `${file.data.data_collections[0].name} (copy)`

      this.rdataStore.createFile(clonedFile)

      this.$router.push({ path: `/rdata/configs/${clonedFile.id}` }).catch(() => {})
    }

    public filterRdataFiles(_value: any, search: string, item: any) {
      return (
        !!item.raw.name?.toLowerCase().includes(search.toLowerCase()) ||
        !!item.raw.createdBy?.toLowerCase().includes(search.toLowerCase())
      )
    }

    // Update the config list based on filters, search, and includeArchived
    @Debounce(100)
    private updateConfigList() {
      let filteredRdataFiles = this.rdataFiles

      if (this.hwTypesFilters.length) {
        filteredRdataFiles = filteredRdataFiles.filter((file) => {
          return this.hwTypesFilters.some((filter) => {
            return filter.value === file?.data?.hardware_type.replace('gen2x', 'gen3').replace(/^oreo$/, 'gen4_oreo')
          })
        })
      }
      if (this.search) {
        filteredRdataFiles = filteredRdataFiles.filter((file: RdataFile) => {
          return this.filterRdataFiles('', this.search, { raw: file })
        })
      }

      if (this.includeArchived === false) {
        filteredRdataFiles = filteredRdataFiles.filter((f) => f.isArchived !== true)
      }

      this.filteredRdataFiles = filteredRdataFiles
    }
  }

  export default toNative(RdataList)
</script>
