export const jobTypes = ['poirot-analyze-firmware', 'poirot-summary-analysis']

export const statuses = ['SUBMITTED', 'PENDING', 'RUNNABLE', 'STARTING', 'RUNNING', 'SUCCEEDED', 'FAILED', 'ALL']

export const environments = ['INPUT_DAYS', 'INPUT_COMMAND', 'INPUT_OPTIONS']

export const months: string[] = [
  'EVERY MONTH',
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JULY',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
]

export const weekDays: string[] = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']

export const cloudEnvs = [
  {
    title: 'Debug and demo apps',
    value: 'test',
  },
  {
    title: 'Staging and experimental apps',
    value: 'stage',
  },
  {
    title: 'Release and research apps',
    value: 'prod',
  },
]

export const filterTypes = [
  { title: 'Ring serial', value: 'serial' },
  { title: 'Account user ID', value: 'user_uuid' },
  { title: 'Firmware version', value: 'fw_version' },
  { title: 'Serial regex', value: 'serial_regex' },
]

export const scheduleStates = [
  { title: 'ENABLED', value: 'ENABLED' },
  { title: 'DISABLED', value: 'DISABLED' },
]

export const ruleEvents = [
  'CPU OFFLIMIT TEMP 0x7fff',
  'DIGITAL OFFLIMIT TEMP 0x7fff',
  'I1R 36 06 2',
  'NO_FLAG_RESET',
  'NTC OFFLIMIT TEMP 0x7fff',
  'NTC2 OFFLIMIT TEMP 0x7fff',
  'NTC3 OFFLIMIT TEMP 0x7fff',
  'NTC4 OFFLIMIT TEMP 0x7fff',
  'TIMESYNC_IND',
  'blebndS',
  'blebndS C',
  'bleind 3',
  'duplicate events',
  'flash:0',
  'not idle',
  'recovering...',
  'reinit motion',
]

export const ruleStatus = [
  {
    title: 'Reference only',
    value: 'ignore',
  },
  {
    title: 'Get alert',
    value: 'slack_alert',
  },
]

export const ruleCondition = [
  {
    title: 'Greater than >',
    value: '>',
  },
  {
    title: 'Smaller than <',
    value: '<',
  },
  {
    title: 'Equal with =',
    value: '=',
  },
]

export const alertHeaders: any = [
  {
    key: 'name',
    title: 'Name',
  },
  {
    key: 'description',
    title: 'Description',
  },
  {
    key: 'createdAt',
    title: 'Created',
    align: 'end',
  },
  {
    key: 'actions',
    title: '',
    align: 'end',
    sortable: false,
    width: 80,
  },
]

export const rulesHeaders: any = [
  {
    key: 'createdAt',
    title: 'Created at',
    align: 'left',
  },

  {
    key: 'rule',
    title: 'Rule',
    align: 'center',
  },
  {
    key: 'condition',
    title: 'Condition',
    align: 'center',
  },
  {
    key: 'action',
    title: 'Status',
    align: 'center',
  },
  {
    key: 'actions',
    title: 'Actions',
    align: 'end',
    sortable: false,
    width: 80,
  },
]

export const sampleHeaders: any = [
  {
    key: 'name',
    title: 'Name',
  },
  {
    key: 'description',
    title: 'Description',
  },
  {
    key: 'createdBy',
    title: 'Created by',
  },
  {
    key: 'type',
    title: 'Type',
  },
  {
    key: 'itemCount',
    title: 'Items',
  },
  {
    key: 'createdAt',
    title: 'Created',
    align: 'end',
  },
  {
    key: 'actions',
    title: '',
    align: 'end',
    sortable: false,
    width: 80,
  },
]

export const scheduleHeaders: any = [
  {
    key: 'description',
    title: 'Info',
  },
  {
    key: 'appFlavor',
    title: 'App flavor',
  },
  {
    key: 'scheduleExpression',
    title: 'Schedule',
  },
  {
    key: 'targets',
    title: 'Configuration',
  },
  {
    key: 'command',
    title: 'Command',
  },
  {
    key: 'createdBy',
    title: 'Creator',
  },
  {
    key: 'ruleName',
    title: 'Created',
  },
  {
    key: 'state',
    title: 'State',
    align: 'end',
  },
  {
    key: 'actions',
    title: '',
    align: 'end',
    sortable: false,
    width: 100,
  },
]

export const hintNotes: any = {
  serial: 'Ex: <br>2D462209005617 <br>2D462209005638 <br>2D462209005630',
  user_uuid: 'Ex: 9e197170-e128-4ea3-9a09-f3e7c122bfd4',
  fw_versions: 'Ex: <br>1.47.2<br>2.50.5<br>3.9.6<br>2.2.1',
  serial_regex:
    'The serial number need to be a regex. Ex: ^2[I,i]<br>Test out the regex: <a target="_blank" href="https://regex101.com/">https://regex101.com/</a>',
}
