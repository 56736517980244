export const appFlavors = [
  {
    title: 'Debug',
    value: 'debug',
  },
  {
    title: 'Staging',
    value: 'staging',
  },
  {
    title: 'Release',
    value: 'release',
  },

  {
    title: 'Research',
    value: 'research',
  },
  {
    title: 'Experimental',
    value: 'experimental',
  },
]
export const hardwareTypes = ['gen1', 'gen2', 'gen2m', 'gen2x', 'gen3', 'gen4', 'oreo']
