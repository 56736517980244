<template>
  <div @mouseover="hover = true" @mouseleave="hover = false">
    {{ text }}

    <v-tooltip location="top">
      <template #activator="{ props }">
        <v-btn v-bind="props" icon @click.stop="copyToClipboard()">
          <v-icon v-show="hover || !text">mdi-content-copy</v-icon>
        </v-btn>
      </template>
      {{ message }}
    </v-tooltip>

    <v-snackbar
      v-if="text"
      v-model="snackbar"
      location="bottom right"
      style="position: fixed; z-index: 10"
      :timeout="timeout"
    >
      Copied to clipboard

      <template #actions>
        <v-btn text="Close" @click="snackbar = false" />
      </template>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
  import { useClipboard } from '@vueuse/core'

  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  @Component
  class HoverCopy extends Vue {
    @Prop() public data!: string
    @Prop() public text!: string
    @Prop() public message!: string

    public timeout = 1500

    public hover = false
    public snackbar = false

    public copyToClipboard() {
      const { copy } = useClipboard()
      copy(this.data)
      this.snackbar = true
    }
  }

  export default toNative(HoverCopy)
</script>
