export const sizes = [
  {
    title: 'Any',
    value: '',
  },
  {
    title: '6',
    value: 6,
  },
  {
    title: '7',
    value: 7,
  },
  {
    title: '8',
    value: 8,
  },
  {
    title: '9',
    value: 9,
  },
  {
    title: '10',
    value: 10,
  },
  {
    title: '11',
    value: 11,
  },
  {
    title: '12',
    value: 12,
  },
  {
    title: '13',
    value: 13,
  },
]

export const models = [
  {
    title: 'Any',
    value: '',
  },
  {
    title: '1',
    value: 1,
  },
  {
    title: '2',
    value: 2,
  },
]

export const colors = [
  {
    title: 'Any',
    value: '',
  },
  {
    title: '1',
    value: 1,
  },
  {
    title: '2',
    value: 2,
  },
  {
    title: '3',
    value: 3,
  },
  {
    title: '4',
    value: 4,
  },
  {
    title: '5',
    value: 5,
  },
]

export const statuses = [
  {
    title: 'Any',
    value: '',
  },
  {
    title: 'Fail',
    value: 'FAIL',
  },
  {
    title: 'Pass',
    value: 'PASS',
  },
]
export const purposes = [
  {
    title: 'Any',
    value: '',
  },
  {
    title: 'Proto',
    value: 'proto',
  },
  {
    title: 'Research',
    value: 'research',
  },
  {
    title: 'Production',
    value: 'production',
  },
]

export const factories = [
  {
    title: 'Any',
    value: '',
  },
  {
    title: 'Leab',
    value: 'leab',
  },
  {
    title: 'Sanmina',
    value: 'sanmina',
  },
  {
    title: 'Oura',
    value: 'oura',
  },
  {
    title: 'Sanmina MX',
    value: 'sanmina-mx',
  },
]

export const testTypes = [
  {
    title: 'Any',
    value: '',
  },
  {
    title: 'Charger final test',
    value: 'charger final test',
  },
  {
    title: 'Charger panel test',
    value: 'charger panel test',
  },
  {
    title: 'Ring final retest',
    value: 'ring final retest',
  },
  {
    title: 'Ring final test',
    value: 'ring final test',
  },
  {
    title: 'Ring flex test',
    value: 'ring flex test',
  },
  {
    title: 'Ring premold test',
    value: 'ring premold test',
  },
  {
    title: 'Ring panel test',
    value: 'ring panel test',
  },
  {
    title: 'Care charger final test',
    value: 'care charger final test',
  },
  {
    title: 'Care ring final test',
    value: 'care ring final test',
  },
  {
    title: 'Premold',
    value: 'premold',
  },
  {
    title: 'Panel',
    value: 'panel',
  },
  {
    title: 'Final',
    value: 'final',
  },
  {
    title: 'PCB',
    value: 'pcb',
  },
  {
    title: 'Final retest',
    value: 'final-retest',
  },
  {
    title: 'Premold retest',
    value: 'premold-retest',
  },
  {
    title: 'Factory reset final phase',
    value: 'factory_reset_final_phase',
  },
  {
    title: 'Factory reset premold phase',
    value: 'factory_reset_premold_phase',
  },
  {
    title: 'Factory reset',
    value: 'factory_reset',
  },
  {
    title: 'Set final flag',
    value: 'set_final_flag',
  },
  {
    title: 'Set panel flag',
    value: 'set_panel_flag',
  },
]

export const leabProductTypes = [
  {
    title: 'Any',
    value: '',
  },
  {
    title: 'Gandalf Ring (Gen2)',
    value: 'gen2 ring',
  },
  {
    title: 'Gandalf Charger (Gen2)',
    value: 'gen2 charger',
  },
  {
    title: 'Gandalf Extra Memory Ring (Gen2M)',
    value: 'gen2m ring',
  },
  {
    title: 'Bilbo Ring (Gen2X/Gen3)',
    value: 'gen3 ring',
  },
  {
    title: 'Bilbo Charger (Gen2X/Gen3)',
    value: 'gen3 charger',
  },
  {
    title: 'Gollum Ring (LE1 / Gen3 Gucci)',
    value: 'gen3 ring (gucci)',
  },
  {
    title: 'Gollum Charger (LE1 / Gen3 Gucci)',
    value: 'gen3 charger (gucci)',
  },
  {
    title: 'Oreo Ring (Gen4)',
    value: 'gen4 ring',
  },
  {
    title: 'Oreo Charger (Gen4)',
    value: 'gen4 charger',
  },
]

export const sanminaProductTypes = [
  {
    title: 'Any',
    value: '',
  },
  {
    title: 'Gandalf Ring (Gen2)',
    value: 'Gen2 Ring',
  },
  {
    title: 'Gandalf Charger (Gen2)',
    value: 'Gen2 Charger',
  },
  {
    title: 'Bilbo Ring (Gen2X/Gen3)',
    value: 'gen3 ring',
  },
  {
    title: 'Oreo Ring (Gen4)',
    value: 'gen4 ring',
  },
  {
    title: 'Oreo Charger (Gen4)',
    value: 'gen4 charger',
  },
]

export const testsHeaders: any = [
  {
    key: 'serialNo',
    title: 'Serial',
  },
  {
    key: 'factory',
    title: 'Factory',
  },
  {
    key: 'productType',
    title: 'Product',
  },
  {
    key: 'testType',
    title: 'Test Type',
  },
  {
    key: 'overallTestResult',
    title: 'Test Result',
  },
  {
    key: 'testCount',
    title: 'Test Count',
  },
  {
    key: 'failedMeasNo',
    title: 'Fail Measurement Number',
  },
  {
    key: 'failedMeasName',
    title: 'Fail Measurement name',
  },
  {
    key: 'productionPhase',
    title: 'Production Phase',
  },
  {
    key: 'ringSize',
    title: 'Ring Size',
  },
  {
    key: 'ringModel',
    title: 'Ring Model',
  },
  {
    key: 'ringColor',
    title: 'Ring Color',
  },
  {
    key: 'purpose',
    title: 'Purpose',
  },
  {
    key: 'type',
    title: 'Type',
  },
  {
    key: 'durationSeconds',
    title: 'Test Duration (s)',
  },
  {
    key: 'testStart',
    title: 'Test started',
  },
  {
    key: 'testReady',
    title: 'Test ended',
  },
]

export const testsListHeaders: any = [
  {
    title: 'Serial',
    key: 'serialNo',
    sortable: false,
  },
  {
    title: 'Factory',
    key: 'factory',
    sortable: false,
  },
  {
    title: 'Product',
    key: 'productType',
    sortable: false,
  },
  {
    title: 'Test type',
    key: 'testType',
    sortable: false,
  },
  {
    title: 'Test result',
    key: 'overallTestResult',
    sortable: false,
  },
  {
    title: 'Failed Meas No',
    key: 'failedMeasNo',
    sortable: true,
  },
  {
    title: 'Duration (s)',
    key: 'durationSeconds',
    sortable: false,
  },
  {
    title: 'Start time',
    key: 'testStart',
    align: 'end',
    sortable: false,
  },
  {
    title: '',
    key: 'action',
    align: 'end',
    sortable: false,
  },
]

export const factoryFilesHeaders: any = [
  {
    key: 'serialNo',
    title: 'Serial',
    sortable: false,
  },
  {
    key: 'factory',
    title: 'Factory',
    sortable: false,
  },
  {
    key: 'testPhase',
    title: 'Test phase',
    sortable: false,
  },
  {
    key: 'uploadedAt',
    title: 'Uploaded at',
  },
  {
    key: 'path',
    title: 'File type',
  },
  {
    key: 'action',
    title: '',
    sortable: false,
    width: '80',
    align: 'end',
  },
]

export const factoryTestsTabs = {
  RINGS: 'rings',
  CHARGERS: 'chargers',
  FILES: 'files',
}
