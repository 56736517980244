<script lang="ts">
  import { Component, Vue, toNative } from 'vue-facing-decorator'

  import { SetupStore } from '#stores'

  @Component
  export class TaskHelpers extends Vue {
    public getOs(name: string, type: string) {
      if (type === 'ios') {
        return name?.includes('android')
          ? 100 - Number(name.substring(8))
          : name?.includes('ios')
            ? Number(name.substring(4))
            : name
      } else {
        return name?.includes('android')
          ? Number(name.substring(8))
          : name?.includes('ios')
            ? 100 - Number(name.substring(4))
            : 100 - +name
      }
    }

    public getHw(value: string | undefined, fallback: string) {
      return value ? value.replace('gen2x', 'gen3') : fallback
    }

    public getTlogOptions(inputOption: any, fwVersionList: any) {
      return inputOption || (fwVersionList ? '<listed_versions>' : '')
    }

    public getCronSchedule(exp: string) {
      const cron = exp?.split(' ').filter((cr: string) => !['*', '*)', '?'].includes(cr))

      if (cron?.length) {
        const minute = cron[0]?.substring(5) || '00'
        const hour = cron[1]?.replace(')', '') || '00'

        let str = `At ${hour.length === 1 ? '0' + hour : hour}:${minute.length === 1 ? '0' + minute : minute}`

        if (cron.length === 2) {
          str += ' every day'
        } else if (cron.length === 3) {
          str += ` every ${cron[2] !== 'L' ? cron[2] : 'last day of every month'}`
        } else {
          str += ` every ${cron[2] === 'L' ? 'last day' : cron[2]} day of ${cron[3] === '*' ? 'every' : cron[3]} month`
        }

        return str
      } else {
        return ''
      }
    }

    public getSampleFilterName(uid: string, type: string) {
      const setupStore = new SetupStore()

      const samples = setupStore.sampleFilters.filter((f: any) => f.type !== 'fw_version')

      const matchingSample: any = samples.find((sample: any) => sample.uid === uid)

      if (matchingSample) {
        return matchingSample[type] || ''
      } else {
        return ''
      }
    }
  }

  export default toNative(TaskHelpers)
</script>
