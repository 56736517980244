<template>
  <v-sheet>
    <v-data-table
      v-if="items"
      no-data-text="There are no schedule alerts to display"
      :loading="isLoading"
      :headers="alertHeaders"
      :items="items"
      :items-per-page="100"
      :items-per-page-options="[
        { title: '100', value: 100 },
        { title: '500', value: 500 },
        { title: '1000', value: 1000 },
      ]"
      :sort-by="[{ key: 'createdAt', order: 'desc' }]"
    >
      <template #item.createdAt="{ item }">
        {{ $dayjs(item.createdAt).format('D.M.YYYY') }}
      </template>

      <template #item.actions="{ item }">
        <v-menu>
          <template #activator="{ props }">
            <v-btn v-bind="props" icon="mdi-dots-vertical" />
          </template>

          <v-list>
            <v-list-item class="text-success" title="Edit alert" prepend-icon="mdi-pencil" @click="modifyAlert(item)" />

            <v-list-item class="text-error" title="Delete alert" prepend-icon="mdi-delete" @click="deleteAlert(item)" />
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </v-sheet>

  <EditAlert :env="env" :data="activeAlert" :open="editDialog" @close="editDialog = false" />
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { alertHeaders } from '#views/setup/constants'

  import { SetupStore } from '#stores'

  import { ScheduleAlert } from '#types'

  @Component
  class AlertsList extends Vue {
    @Prop() public env!: string

    @Prop() public items!: ScheduleAlert[]

    public editDialog = false

    public readonly alertHeaders = alertHeaders

    private readonly setupStore = new SetupStore()

    public get isLoading() {
      return this.setupStore.loading
    }

    public get activeAlert() {
      return this.setupStore.activeScheduleAlert
    }

    public set activeAlert(value: ScheduleAlert | null) {
      this.setupStore.activeScheduleAlert = value
    }

    public deleteAlert(alert: ScheduleAlert) {
      this.$confirm('Confirm deletion of this schedule alert?', alert.name, {
        buttonTrueColor: 'error',
      }).then((confirmed) => {
        if (confirmed) {
          this.setupStore.deleteScheduleAlert(this.env, alert.uid!)
        }
      })
    }

    public async modifyAlert(alert: ScheduleAlert) {
      await this.setupStore.fetchScheduleAlert(this.env, alert.uid!)

      this.editDialog = true
    }
  }

  export default toNative(AlertsList)
</script>
