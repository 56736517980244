<template>
  <v-app>
    <template v-if="user === undefined">
      <OuraSpinner />
    </template>

    <template v-if="user === null">
      <OuraAuth title="Poirot - Rings analyzer" @login="appStore.login()" />

      <v-dialog v-model="backendUpdate" width="600">
        <v-card>
          <v-card-title>Update in progress</v-card-title>

          <v-card-text>
            We are currently updating the system, this should normally take only 5-15 minutes. If this dialog does not
            go away in ~30 minutes please report this in #poirot Slack channel.
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>

    <template v-else-if="user !== undefined">
      <v-navigation-drawer
        :permanent="$vuetify.display.mdAndUp"
        :temporary="$vuetify.display.smAndDown"
        :rail="$vuetify.display.smAndDown ? false : miniNav"
        :model-value="$vuetify.display.smAndDown ? navDrawer : true"
      >
        <div class="d-flex flex-column fill-height">
          <v-list nav>
            <v-list-item
              link
              prepend-icon="mdi-menu"
              @click="$vuetify.display.smAndDown ? (navDrawer = !navDrawer) : (miniNav = !miniNav)"
            />

            <v-divider />

            <v-list-item link to="/" class="mb-0" title="Home" prepend-icon="mdi-home">
              <v-tooltip activator="parent" :disabled="!miniNav">Home</v-tooltip>
            </v-list-item>

            <v-divider />

            <v-list-item link to="/dashboard" title="Dashboard" prepend-icon="mdi-view-dashboard-variant">
              <v-tooltip activator="parent" :disabled="!miniNav">Dashboard</v-tooltip>
            </v-list-item>

            <v-list-item link to="/stats" class="mb-0" title="Statistics" prepend-icon="mdi-chart-bar">
              <v-tooltip activator="parent" :disabled="!miniNav">Statistics</v-tooltip>
            </v-list-item>

            <v-divider />

            <v-list-item link to="/ota" title="OTA Rollouts" prepend-icon="mdi-rocket-launch-outline">
              <v-tooltip activator="parent" :disabled="!miniNav">OTA Rollouts</v-tooltip>
            </v-list-item>

            <v-list-item
              link
              to="/tasks"
              class="mb-0"
              title="Analyzer Tasks"
              prepend-icon="mdi-text-box-search-outline"
            >
              <v-tooltip activator="parent" :disabled="!miniNav">Analyzer Tasks</v-tooltip>
            </v-list-item>

            <v-divider />

            <v-list-item link to="/rdata" title="Research Data" prepend-icon="mdi-flask">
              <v-tooltip activator="parent" :disabled="!miniNav">Research Data</v-tooltip>
            </v-list-item>

            <v-list-item link to="/tests" class="mb-0" title="Factory Tests" prepend-icon="mdi-factory">
              <v-tooltip activator="parent" :disabled="!miniNav">Factory Tests</v-tooltip>
            </v-list-item>

            <v-divider />

            <v-list-item link to="/setup" title="Configuration" prepend-icon="mdi-cogs">
              <v-tooltip activator="parent" :disabled="!miniNav">Configuration</v-tooltip>
            </v-list-item>
          </v-list>

          <v-spacer />

          <v-list nav>
            <v-list-item link title="Feedback" prepend-icon="mdi-bug" @click="$showJiraIssueCollectorDialog()">
              <v-tooltip activator="parent" :disabled="!miniNav">Feedback</v-tooltip>
            </v-list-item>

            <v-list-item link to="/logout" title="Logout" prepend-icon="mdi-logout">
              <v-tooltip activator="parent" :disabled="!miniNav">Logout</v-tooltip>
            </v-list-item>
          </v-list>
        </div>
      </v-navigation-drawer>

      <v-app-bar>
        <v-btn v-if="$vuetify.display.smAndDown" icon="mdi-menu" @click="navDrawer = !navDrawer" />

        <v-app-bar-nav-icon v-if="$vuetify.display.mdAndUp">
          <v-img width="32" height="32" src="/images/logo-white.png" />
        </v-app-bar-nav-icon>

        <v-app-bar-title :text="$vuetify.display.mdAndUp ? title : 'Poirot'" />

        <v-spacer />

        <v-btn icon="mdi-cog" class="mx-2" @click.stop="sideNav = !sideNav" />

        <v-menu v-if="user && user.email" bottom offset-y offset="12px">
          <template #activator="{ props }">
            <div v-bind="props" class="d-flex text-white mr-2 py-4" style="cursor: pointer">
              <span v-if="$vuetify.display.mdAndUp" class="subtitle-1">{{ user.email }}</span>
              <v-icon v-else color="white">mdi-account</v-icon>

              <v-icon v-if="$vuetify.display.mdAndUp" color="white">mdi-chevron-down</v-icon>
            </div>
          </template>

          <v-list>
            <v-list-item @click="toggleOverrideRole('groupOtaRolloutsAdmins')">
              <v-list-item-title>OTA rollouts admin</v-list-item-title>

              <template #append>
                <div :class="isOtaRolloutsAdmin ? 'text-success' : 'text-warning'">
                  {{ isOtaRolloutsAdmin ? 'YES' : 'NO' }}
                </div>
              </template>
            </v-list-item>

            <v-list-item @click="toggleOverrideRole('groupResearchDataAdmins')">
              <v-list-item-title>Research data admin</v-list-item-title>

              <template #append>
                <div :class="isResearchDataAdmin ? 'text-success' : 'text-warning'">
                  {{ isResearchDataAdmin ? 'YES' : 'NO' }}
                </div>
              </template>
            </v-list-item>

            <v-divider />

            <v-list-item @click="toggleOverrideRole('allowDataDownloadAccess')">
              <v-list-item-title>Data download rights</v-list-item-title>

              <template #append>
                <div :class="hasDataDownloadRights ? 'text-success' : 'text-warning'">
                  {{ hasDataDownloadRights ? 'YES' : 'NO' }}
                </div>
              </template>
            </v-list-item>
          </v-list>
        </v-menu>

        <OuraApps :env="env" />
      </v-app-bar>

      <v-navigation-drawer
        v-model="sideNav"
        disable-resize-watcher
        width="380"
        location="right"
        style="max-width: calc(100vw - 56px)"
        :temporary="$vuetify.display.mdAndDown"
      >
        <v-toolbar title="Quick settings">
          <v-spacer />

          <v-btn icon="mdi-window-close" @click="sideNav = !sideNav" />
        </v-toolbar>

        <v-divider />

        <TaskPrefs />

        <v-container>
          <div class="text-overline">User interface</div>

          <v-switch
            inset
            color="primary"
            label="Dark mode"
            class="reversed mt-2 mb-2 ml-4 mr-2"
            :model-value="theme?.global?.current?.value?.dark"
            @change="toggleTheme()"
          />
        </v-container>
      </v-navigation-drawer>

      <v-main>
        <div v-show="$route.name === 'dashboard'" style="padding: 0 !important; max-height: calc(100vh - 168px)">
          <Dashboard :active="$route.name === 'dashboard'" />
        </div>

        <router-view v-if="$route.name !== 'dashboard'" />
      </v-main>

      <v-footer
        v-if="env !== 'release'"
        :color="
          sessionNotice
            ? 'blue-lighten-1'
            : backendError || networkError
              ? 'red-lighten-1'
              : env === 'preview'
                ? 'black'
                : 'purple-lighten-1'
        "
      >
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-if="sessionNotice">
          Session expiring in
          <strong>{{ sessionExpirationInMinutes }}</strong>
          minutes. Please refresh your session by
          <a class="text-info font-weight-bold" href="#" @click="refreshAuthSession()">logging in</a>
          again.
        </div>

        <div v-else-if="backendError">
          Backend crash detected, data loading may have been interrupted and therefore not showing in the UI
        </div>

        <div v-else-if="networkError">
          Network connection error, data loading may have been interrupted and therefore not showing in the UI
        </div>

        <template v-else>
          <span v-if="$vuetify.display.mdAndUp">Environment:</span>
          <b class="ml-2">{{ env[0].toUpperCase() + env.slice(1) }}</b>

          <v-spacer />

          <span v-if="$vuetify.display.mdAndUp">Version:</span>
          <b class="ml-2">{{ version }} ({{ revision }})</b>
        </template>
      </v-footer>

      <v-snackbar :timeout="-1" :model-value="newConfig || serviceWorker!.needRefresh.value">
        New Update available

        <template #actions>
          <v-btn
            @click="serviceWorker!.needRefresh.value ? serviceWorker!.updateServiceWorker() : updateRemoteConfig()"
          >
            Refresh
          </v-btn>
        </template>
      </v-snackbar>
    </template>
  </v-app>
</template>

<script lang="ts">
  import { startCase } from 'lodash-es'

  import { useRegisterSW } from 'virtual:pwa-register/vue'

  import { ThemeInstance, useTheme } from 'vuetify'

  import { Component, Setup, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { setUserId, setUserProperties } from 'firebase/analytics'
  import { fetchAndActivate } from 'firebase/remote-config'

  import { OuraApps, OuraAuth, OuraSpinner } from '@jouzen/outo-apps-toolkit'

  import { AppStore } from '#stores'

  @Component({
    components: {
      OuraSpinner,
      OuraApps,
      OuraAuth,
    },
  })
  class App extends Vue {
    @Setup(() => useTheme())
    public readonly theme: ThemeInstance | undefined

    @Setup(() =>
      useRegisterSW({
        onRegistered(r) {
          r &&
            setInterval(
              () => {
                r.update()
              },
              15 * 60 * 1000,
            )
        },
      }),
    )
    public readonly serviceWorker: { needRefresh: { value: boolean }; updateServiceWorker: () => void } | undefined

    public title = 'Poirot - Rings analyzer'

    public miniNav = true
    public sideNav = false
    public navDrawer = false

    public newConfig = false

    public backendError = false
    public networkError = false
    public backendUpdate = false

    public sessionExpirationInterval = 0
    public sessionExpirationInMinutes = 100

    public readonly appStore = new AppStore()

    public readonly env = import.meta.env.VITE_APP_ENV

    public readonly version = process.env.APP_RELEASE_VERSION
    public readonly revision = process.env.APP_RELEASE_REVISION

    public get user() {
      return this.appStore.user
    }

    public get rights() {
      return this.appStore.activeRights
    }

    public get sessionNotice() {
      return this.sessionExpirationInMinutes <= 30
    }

    public get isOtaRolloutsAdmin() {
      return this.appStore.isOtaRolloutsAdmin
    }

    public get isResearchDataAdmin() {
      return this.appStore.isResearchDataAdmin
    }

    public get hasDataDownloadRights() {
      return this.appStore.hasDataDownloadRights
    }

    public get sessionExpirationTime() {
      return this.appStore.sessionExpirationTime
    }

    @Watch('user')
    protected userChanged() {
      if (this.user) {
        setUserId(this.$analytics, this.user.email)

        setUserProperties(this.$analytics, {
          rights: this.rights.join(','),
        })

        fetchAndActivate(this.$remoteConfig)
          .then((activated: boolean) => {
            if (activated) {
              this.newConfig = true
            }
          })
          .catch(() => {
            console.error('Failed to fetch firebase remote config')
          })

        this.setSessionExpiresInMinutes()

        if (this.sessionExpirationInterval) {
          clearInterval(this.sessionExpirationInterval)
        }

        this.sessionExpirationInterval = window.setInterval(() => {
          this.setSessionExpiresInMinutes()
        }, 1000 * 60)
      }

      this.$updateJiraIssueCollectorFields({
        email: this.user?.email || '',
        fullname: startCase((this.user?.email || '').replace('.', ' ').replace('@ouraring.com', '')),
      })
    }

    @Watch('miniNav')
    protected miniNavChanged() {
      localStorage.OuraMiniNav = this.miniNav
    }

    @Watch('$route', { immediate: true })
    protected routeMetaChanged() {
      const title = this.$route.meta.title as string

      const page = title ? ` / ${title}` : ''

      this.title = 'Poirot -  Rings analyzer' + page

      document.title = this.title
    }

    public async mounted() {
      if (localStorage.OuraMiniNav) {
        this.miniNav = localStorage.OuraMiniNav === 'true'
      }

      document.addEventListener('notify' as any, this.notifyNetworkStatus)

      this.theme!.global.name.value = localStorage.getItem('OuraDarkMode') === 'true' ? 'dark' : 'light'
    }

    public beforeUnmount() {
      if (this.sessionExpirationInterval) {
        clearInterval(this.sessionExpirationInterval)
      }

      document.removeEventListener('notify' as any, this.notifyNetworkStatus)
    }

    public toggleTheme() {
      this.theme!.global.name.value = this.theme!.global.current.value.dark ? 'light' : 'dark'

      localStorage.setItem('OuraDarkMode', this.theme!.global.current.value.dark.toString())
    }

    public updateRemoteConfig() {
      window.location.reload()
    }

    public refreshAuthSession() {
      this.appStore.logout(true)
    }

    public toggleOverrideRole(role: string) {
      if (this.env === 'preview' || this.env === 'testing') {
        this.appStore.toggleRole(role)
      }
    }

    private notifyNetworkStatus(event: any) {
      if (event.detail?.authError) {
        this.sessionExpirationInMinutes = 0
      }

      this.backendError = !!event.detail?.backendError
      this.backendUpdate = !!event.detail?.backendUpdate

      this.networkError = !!event.detail?.connectionError

      if (this.backendUpdate) {
        setTimeout(
          () => {
            window.location.reload()
          },
          1 * 60 * 1000,
        )
      }
    }

    public setSessionExpiresInMinutes() {
      /**
       * Calculates minutes left until the session is expired and sets expiration in minutes
       */

      if (this.sessionExpirationTime) {
        let hourDiff = this.sessionExpirationTime - Date.now()

        this.sessionExpirationInMinutes = Math.max(0, parseInt((hourDiff / 60 / 1000).toFixed(0)))
      }
    }
  }

  export default toNative(App)
</script>
