<template>
  <v-row>
    <v-col cols="9">
      <div class="text-h5 font-weight-light">Schedule alerts sends notifications from the scheduled task runs</div>

      <div class="text-subtitle-2 text-grey-darken-2 font-weight-light">
        You can define multiple alerts with different triggering rules and configuration
      </div>
    </v-col>

    <v-col cols="3" class="d-flex justify-end align-top">
      <v-btn text="Create new" color="primary" @click="createAlert()" />
    </v-col>
  </v-row>

  <v-row>
    <v-col cols="12">
      <AlertsList :env="env" :items="scheduleAlerts" />
    </v-col>
  </v-row>

  <EditAlert :env="env" :open="createDialog" @close="createDialog = false" />
</template>

<script lang="ts">
  import { Component, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { SetupStore } from '#stores'

  @Component
  class AlertsView extends Vue {
    @Prop() public env!: string

    public createDialog = false

    private readonly setupStore = new SetupStore()

    public get scheduleAlerts() {
      return this.setupStore.scheduleAlerts
    }

    @Watch('env', { immediate: true })
    protected envChanged() {
      this.setupStore.listScheduleAlerts(this.env)
    }

    public createAlert() {
      this.createDialog = true
    }
  }

  export default toNative(AlertsView)
</script>
