<template>
  <v-dialog v-model="open" persistent width="1000">
    <v-card v-if="task">
      <v-card-title>{{ !schedule ? 'Create' : 'Edit' }} schedule</v-card-title>

      <v-alert v-if="saveError" type="error">{{ saveError }}</v-alert>

      <v-card-text>
        <v-row align="center">
          <v-col cols="8">
            <v-text-field v-model="infoText" autofocus label="Schedule description" />
          </v-col>

          <v-col cols="4">
            <v-select v-model="jzlogSource" label="Target app flavor" :items="jzlogSources" />
          </v-col>
        </v-row>

        <div class="section-title my-4">
          <span class="">Schedule for task running</span>
        </div>

        <div class="section-border ml-3 mb-8 pl-4">
          <CronSettings v-model:cron-value="cron" />
        </div>

        <div class="section-title my-4">Configuration for the task</div>

        <div class="section-border d-flex ml-3 pl-4">
          <v-tabs v-model="selectedTab">
            <v-tab v-for="tab in taskTabs" :key="tab.value" :value="tab.value">{{ tab.title }}</v-tab>
          </v-tabs>
        </div>

        <div v-if="task.parameters.inputCommand" class="section-border ml-3 mb-8 pl-7">
          <div class="pt-8">
            <TaskConfig :mode="selectedTab" :task="task" class="mb-4" />

            <TaskBinary :mode="selectedTab" :task="task" />
          </div>
        </div>

        <p class="section-title my-4">Alert settings</p>

        <div class="section-border d-flex ml-3 pl-4">
          <v-select
            v-model="task.parameters.taskRuleSetId"
            class="mt-4"
            label="Select alert setting"
            item-title="name"
            item-value="uid"
            no-data-text="No alert settings available"
            :items="scheduleAlerts"
          />
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn text="Cancel" class="mr-2" @click="close()" />

        <v-btn
          text="Save"
          color="primary"
          :disabled="!cron || !infoText || !isValid || isLoading"
          @click="saveSchedule()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { TaskHelpers } from '#common/TaskHelpers.vue'

  import { createDefaultTask, jzlogSources, taskStarterTabs } from '#views/tasks/constants'

  import { AppStore, SetupStore, TasksStore } from '#stores'

  import { SampleFilter, ScheduleTask, TaskSettings } from '#types'

  @Component
  class EditSchedule extends mixins(TaskHelpers) {
    @Prop() public env!: string
    @Prop() public open!: boolean

    @Prop() public schedule!: ScheduleTask

    @Emit('close')
    public close() {
      this.infoText = ''
      this.saveError = ''

      this.selectedTab = 'random'

      return null
    }

    public infoText = ''
    public saveError = ''
    public jzlogSource = ''

    public selectedTab = 'random'

    public task: TaskSettings | null = null

    public cron = ['0', '1', '*', '*', '?', '*']

    public taskTabs = taskStarterTabs.slice(0, -1)

    private readonly appStore = new AppStore()
    private readonly tasksStore = new TasksStore()
    private readonly setupStore = new SetupStore()

    public get isValid() {
      const randomValidation =
        this.selectedTab === 'random' &&
        !!this.task &&
        !!this.task.parameters.hwType &&
        !!this.task.parameters.maxUsers &&
        !!this.task.parameters.fwVersions
      const selectValidation =
        this.selectedTab === 'select' &&
        !!this.task &&
        !!this.task.parameters.fwVersions &&
        !!this.task.parameters.whitelistedFilters
      const uploadValidation = this.selectedTab === 'upload' && !!this.task && !!this.task.file

      return randomValidation || selectValidation || uploadValidation
    }

    public get isLoading() {
      return this.tasksStore.loading || this.setupStore.loading
    }

    public get jzlogSources() {
      return jzlogSources.filter((s: any) => s.env === this.env)
    }

    public get scheduleAlerts() {
      return this.setupStore.scheduleAlerts || []
    }

    public get taskSampleFilters() {
      return this.setupStore.sampleFilters.filter((f: SampleFilter) => f.type !== 'fwVersion')
    }

    @Watch('open', { immediate: true })
    protected openChanged() {
      if (this.open) {
        this.scheduleChanged()

        this.setupStore.listSampleFilters(this.env)

        this.setupStore.listScheduleAlerts(this.env)
      }
    }

    @Watch('schedule', { immediate: true })
    protected scheduleChanged() {
      if (!this.schedule) {
        this.task = createDefaultTask()

        this.cron = ['0', '1', '*', '*', '?', '*']

        this.jzlogSource = this.jzlogSources[0].value
      } else {
        const { info, parameters } = this.schedule?.targets[0]?.input || {}

        this.selectedTab = parameters.samplePath ? 'upload' : !parameters.hwType ? 'select' : 'random'

        if (parameters) {
          this.infoText = this.schedule.description

          this.jzlogSource = parameters.appFlavor ?? this.jzlogSources[0].value

          this.cron = this.schedule.scheduleExpression.replace('cron(', '').replace(')', '').split(' ')

          this.task = {
            info,
            parameters,
          }
        } else {
          this.saveError = 'Schedule has invalid configuration!'
        }
      }
    }

    public async saveSchedule() {
      this.saveError = ''

      if (!this.schedule) {
        const data = {
          info: {
            name: 'Scheduled task',
            description: this.infoText,
            scheduleCreator: this.appStore.user.email,
          },
          scheduleDescription: this.infoText,
          cronSchedule: this.cron.join(' '),
          parameters: this.task!.parameters,
        }

        if (this.jzlogSource !== 'factory') {
          data.parameters.appFlavor = this.jzlogSource
        }

        data.parameters.analyzeSampleMode = 'scheduled'

        const result = await this.setupStore.createScheduleTask(this.env, data)

        if (!result?.data?.ruleName) {
          this.saveError = 'Unknown error occurred when creating the schedule, please report this!'
        } else {
          this.close()
        }
      } else {
        const { info } = this.schedule?.targets[0]?.input || {}

        const data = {
          scheduleDescription: this.infoText,
          cronSchedule: this.cron.join(' '),
          parameters: this.task!.parameters,
          info: { ...info, description: this.infoText },
        }

        if (this.jzlogSource !== 'factory') {
          data.parameters.appFlavor = this.jzlogSource
        }

        data.parameters.analyzeSampleMode = 'scheduled'

        const result = await this.setupStore.modifyScheduleTask(this.env, this.schedule.ruleName, data)

        if (!result?.data?.ruleName) {
          this.saveError = 'Unknown error occurred when saving the schedule, please report this!'
        } else {
          await this.setupStore.modifyScheduleTask(this.env, this.schedule.ruleName, { state: this.schedule.state })

          this.close()
        }
      }
    }
  }

  export default toNative(EditSchedule)
</script>

<style lang="scss">
  .section-title {
    color: #9c27b0;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 500;
  }

  .section-border {
    border-left: 2px solid #9c27b0;
  }
</style>
