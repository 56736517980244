<template>
  <template v-if="search">
    <v-row>
      <v-col v-if="!$route.path.includes('/warranty')">
        <v-select
          v-model="search.appFlavor"
          label="Application flavor"
          hint="Include only data from this app flavor"
          prepend-icon="mdi-account"
          :items="appFlavors"
          @update:model-value="updateSearch()"
        />
      </v-col>

      <v-col>
        <v-autocomplete
          v-if="listFilter === 'samples' || $route.path.includes('/warranty')"
          clearable
          persistent-placeholder
          item-title="name"
          item-value="uid"
          label="Serial sample list"
          placeholder="Select to filter..."
          no-data-text="No serial or serial_regex sample lists"
          :items="sampleFilters"
          :prepend-icon="search.sampleListId ? 'mdi-text-box-edit-outline' : 'mdi-text-box-search-outline'"
          :model-value="search.sampleListId || null"
          @click:clear="toggleReset()"
          @click:prepend="goTo('/setup/lists', search.sampleListId)"
          @update:model-value="(search.sampleListId = $event || ''), updateSearch()"
        />

        <v-autocomplete
          v-else
          v-model="search.labelListId"
          clearable
          persistent-placeholder
          item-title="name"
          item-value="lid"
          placeholder="Select to filter..."
          label="Research study group"
          no-data-text="No study groups for this app flavor"
          :items="studyFilters"
          :prepend-icon="search.labelListId ? 'mdi-text-box-edit-outline' : 'mdi-text-box-search-outline'"
          @click:clear="toggleReset()"
          @click:prepend="goTo('/rdata/studies', search.labelListId)"
          @update:model-value="updateSearch()"
        />
      </v-col>

      <v-col v-if="!$route.path.includes('/warranty')">
        <v-menu
          v-model="dataMenu"
          offset-y
          min-width="auto"
          transition="scale-transition"
          :close-on-content-click="false"
        >
          <template #activator="{ props }">
            <v-text-field
              v-bind="props"
              clearable
              persistent-placeholder
              placeholder="No dates picked..."
              label="Server data sync date"
              hint="Include only data between these dates"
              prepend-icon="mdi-calendar-arrow-right"
              :value="
                dataDates.length
                  ? $dayjs(search.dataStartDate).format('YYYY-MM-DD') +
                    ' - ' +
                    $dayjs(search.dataEndDate || Date.now()).format('YYYY-MM-DD')
                  : ''
              "
              @click:clear="(dataDates = []), updateSearch()"
            />
          </template>

          <v-date-picker
            range
            title="Select start and end date"
            @update:model-value="updateDateRange('data', $event)"
          />
        </v-menu>
      </v-col>

      <v-col v-if="!$route.path.includes('/warranty')">
        <v-menu
          v-model="userMenu"
          offset-y
          min-width="auto"
          transition="scale-transition"
          :close-on-content-click="false"
        >
          <template #activator="{ props }">
            <v-text-field
              v-bind="props"
              clearable
              persistent-placeholder
              placeholder="No dates picked..."
              label="User registered date"
              hint="Include only users between these dates"
              prepend-icon="mdi-calendar-account"
              :value="
                userDates.length
                  ? search.userStartDate + ' - ' + (search.userEndDate || $dayjs().format('YYYY-MM-DD'))
                  : ''
              "
              @click:clear="(userDates = []), updateSearch()"
            />
          </template>

          <v-date-picker
            range
            title="Select start and end date"
            @update:model-value="updateDateRange('user', $event)"
          />
        </v-menu>
      </v-col>
    </v-row>
  </template>
</template>

<script lang="ts">
  import { Component, Model, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { SetupStore, TeamsStore } from '#stores'

  import { SearchInput } from '#types'

  @Component
  class StatsFilter extends Vue {
    @Prop() public appFlavors!: any
    @Prop() public extraFilters!: boolean

    @Model({ name: 'searchInputs' }) public search!: SearchInput

    public dataMenu = false
    public userMenu = false

    public listFilter = 'samples'

    public userDates: string[] = []
    public dataDates: (string | undefined)[] = []

    private readonly setupStore = new SetupStore()
    private readonly teamsStore = new TeamsStore()

    public get studyFilters() {
      return this.teamsStore.teams
        .filter((t) => `${t.appFlavor}` === this.search.appFlavor)
        .map((f) => ({ ...f, lid: `research:id:${f.id}` }))
    }

    public get sampleFilters() {
      return this.setupStore.sampleFilters.filter((f) => ['serial', 'serial_regex'].includes(f.type))
    }

    public mounted() {
      this.dataDates =
        this.search.dataStartDate || this.search.dataEndDate
          ? [this.search.dataStartDate ?? '', this.search.dataEndDate ?? '']
          : []

      this.userDates =
        this.search.userStartDate || this.search.userEndDate
          ? [this.search.userStartDate ?? '', this.search.userEndDate ?? '']
          : []

      this.listFilter = this.search.labelListId ? 'studies' : 'samples'
    }

    public goTo(path: string, id: string | undefined) {
      if (id) {
        this.$router.push(`${path}/${id.replace('research:id:', '')}`)
      }
    }

    public toggleReset() {
      if (this.search.labelListId || this.$route.path.includes('/warranty')) {
        this.search.labelListId = ''
        this.updateSearch()
      } else if (this.search.sampleListId || this.$route.path.includes('/warranty')) {
        this.search.sampleListId = ''
        this.updateSearch()
      } else {
        this.listFilter = this.listFilter === 'samples' ? 'studies' : 'samples'
      }
    }

    public updateSearch() {
      this.search.dataEndDate = this.dataDates.filter(Boolean).sort()[1] ?? ''
      this.search.dataStartDate = this.dataDates.filter(Boolean).sort()[0] ?? ''

      this.search.userEndDate = this.userDates.filter(Boolean).sort()[1] ?? ''
      this.search.userStartDate = this.userDates.filter(Boolean).sort()[0] ?? ''

      return { ...this.search }
    }

    public updateDateRange(dates: string, value: any) {
      if (dates === 'data') {
        if (this.dataDates.length === 2) {
          this.dataDates = []
        }

        this.dataDates.push(this.$dayjs(value).format('YYYY-MM-DDT00:00:00.000Z'))

        if (this.dataDates.length === 2) {
          this.dataMenu = false
        }
      } else if (dates === 'user') {
        if (this.userDates.length === 2) {
          this.userDates = []
        }

        this.userDates.push(this.$dayjs(value).format('YYYY-MM-DD'))

        if (this.userDates.length === 2) {
          this.userMenu = false
        }
      }

      this.updateSearch()
    }
  }

  export default toNative(StatsFilter)
</script>
