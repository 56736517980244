<template>
  <v-container>
    <div class="text-overline">Analyzer tasks</div>

    <div class="pa-4">
      <v-select v-model="runCommandPrefs" label="Command to run in analyze task" :items="commands" />

      <v-select v-model="tlogBranchPrefs" label="Tlog branch / version" :items="tlogBranchesPrefs" />
    </div>

    <div class="text-overline">Filter Tasks</div>

    <div class="pa-2 pt-1">
      <v-checkbox
        v-for="(item, index) in filterItems"
        :key="index"
        v-model="filterTaskPrefs"
        :label="item.title"
        :value="item.value"
      />
    </div>
  </v-container>
</template>

<script lang="ts">
  import { Component, Vue, toNative } from 'vue-facing-decorator'

  import { filterTasks } from '#views/tasks/constants'

  import { PrefsStore, TasksStore } from '#stores'

  @Component
  class TaskPrefs extends Vue {
    public days = ['1', '2', '3']
    public commands = ['tlog']

    public readonly filterItems = filterTasks

    private readonly prefsStore = new PrefsStore()
    private readonly tasksStore = new TasksStore()

    public get daysLimitPrefs() {
      return this.prefsStore.daysLimit
    }

    public set daysLimitPrefs(value: string) {
      this.prefsStore.updatePrefs({ key: 'daysLimit', value })
    }

    public get runCommandPrefs() {
      return this.prefsStore.runCommand
    }

    public set runCommandPrefs(value: string) {
      this.prefsStore.updatePrefs({ key: 'runCommand', value })

      if (this.filterTaskPrefs.some((field: string) => field.includes('binary'))) {
        this.tasksStore.filterTasks({ filters: this.filterTaskPrefs })
      }
    }

    public get tlogBranchPrefs() {
      return this.prefsStore.tlogBranch
    }

    public set tlogBranchPrefs(value: string) {
      this.prefsStore.updatePrefs({ key: 'tlogBranch', value })
    }

    public get tlogBranchesPrefs() {
      return this.prefsStore.tlogBranches
    }

    public get filterTaskPrefs() {
      return this.prefsStore.taskFilters
    }

    public set filterTaskPrefs(value: string[]) {
      this.prefsStore.updatePrefs({ key: 'taskFilters', value })
    }

    public async mounted() {
      this.prefsStore.init()

      await this.prefsStore.listBranches('tlog')
    }
  }

  export default toNative(TaskPrefs)
</script>
