// sort-imports-ignore

import { createRouter, createWebHistory } from 'vue-router'

import Home from '#Home.vue'

import Dashboard from '#views/Dashboard.vue'

import StatsView from '#views/stats/StatsView.vue'

import SetupView from '#views/setup/SetupView.vue'
import TasksView from '#views/tasks/TasksView.vue'
import TestsView from '#views/tests/TestsView.vue'

import ProtoEditor from '#views/rdata/ProtoEditor.vue'
import StudyEditor from '#views/rdata/StudyEditor.vue'
import ConfigEditor from '#views/rdata/ConfigEditor.vue'

import RolloutsView from '#views/ota/RolloutsView.vue'
import ResearchView from '#views/rdata/ResearchView.vue'

import { AppStore, ProtoStore, RdataStore, TeamsStore } from '#stores'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      name: 'home',
      path: '/',
      component: Home,
    },

    {
      name: 'login',
      path: '/login',
      component: Home,

      meta: {
        title: 'Login',
      },

      beforeEnter(_to: any, _from: any, next: any) {
        const appStore = new AppStore()

        appStore.login()

        next({ path: '/' })
      },
    },
    {
      name: 'logout',
      path: '/logout',
      component: Home,

      meta: {
        title: 'Logout',
      },

      beforeEnter(_to: any, _from: any, next: any) {
        const appStore = new AppStore()

        appStore.logout()

        next({ path: '/' })
      },
    },

    {
      props: true,
      name: 'dashboard',
      path: '/dashboard/:id?',
      component: Dashboard,

      meta: {
        title: 'Dashboard',
      },
    },

    {
      path: '/ota',
      redirect: () => '/ota/rollouts',

      children: [
        {
          props: true,
          path: ':tab',
          component: RolloutsView,

          meta: {
            title: 'Rollouts',
            routeParams: {
              path: '/ota',
            },
          },
        },
      ],
    },

    {
      path: '/stats',
      redirect: () => '/stats/firmware',

      children: [
        {
          props: true,
          path: ':tab',
          component: StatsView,

          meta: {
            title: 'Statistics',
            routeParams: {
              path: '/stats',
            },
          },

          beforeEnter(_to: any, _from: any, next: any) {
            const appStore = new AppStore()

            if (appStore.user) {
              const teamsStore = new TeamsStore()

              teamsStore.subscribeToTeams()
            }

            next()
          },
        },
      ],
    },

    {
      path: '/tasks',
      redirect: () => '/tasks/random',

      children: [
        {
          props: true,
          path: ':tab/:task?/:uid?',
          component: TasksView,

          meta: {
            title: 'Analyzer Task',
            routeParams: {
              path: '/tasks',
            },
          },
        },
      ],
    },

    {
      path: '/rdata',
      redirect: () => '/rdata/configs',

      children: [
        {
          props: true,
          path: ':tab',
          component: ResearchView,

          meta: {
            title: 'Research Data',
          },

          beforeEnter(_to: any, _from: any, next: any) {
            const appStore = new AppStore()

            if (appStore.user) {
              const rdataStore = new RdataStore()
              const teamsStore = new TeamsStore()
              const protoStore = new ProtoStore()

              rdataStore.subscribeToRdata()
              teamsStore.subscribeToTeams()
              protoStore.subscribeToProto()
            }

            next()
          },
        },

        {
          props: true,
          path: 'configs/:fid',
          component: ConfigEditor,

          meta: {
            title: 'Research Data',
          },

          beforeEnter(_to: any, _from: any, next: any) {
            const appStore = new AppStore()

            if (appStore.user) {
              const rdataStore = new RdataStore()
              const teamsStore = new TeamsStore()

              rdataStore.subscribeToRdata()
              teamsStore.subscribeToTeams()
            }

            next()
          },
        },
        {
          props: true,
          path: 'studies/:tid',
          component: StudyEditor,

          meta: {
            title: 'Research Data',
          },

          beforeEnter(_to: any, _from: any, next: any) {
            const appStore = new AppStore()

            if (appStore.user) {
              const rdataStore = new RdataStore()
              const teamsStore = new TeamsStore()
              const protoStore = new ProtoStore()

              rdataStore.subscribeToRdata()
              teamsStore.subscribeToTeams()
              protoStore.subscribeToProto()
            }

            next()
          },
        },
        {
          props: true,
          path: 'protocols/:fid',
          component: ProtoEditor,

          meta: {
            title: 'Research Data',
          },

          beforeEnter(_to: any, _from: any, next: any) {
            const appStore = new AppStore()

            if (appStore.user) {
              const protoStore = new ProtoStore()

              protoStore.subscribeToProto()
            }

            next()
          },
        },
      ],
    },

    {
      path: '/tests',
      redirect: () => '/tests/rings',

      children: [
        {
          props: true,
          path: ':tab',
          component: TestsView,

          meta: {
            title: 'Factory Tests',
          },
        },
      ],
    },

    {
      path: '/setup',
      redirect: () => '/setup/lists',

      children: [
        {
          props: true,
          path: ':tab',
          component: SetupView,

          meta: {
            title: 'Configuration',
            routeParams: {
              path: '/setup',
            },
          },
        },
      ],
    },

    {
      path: '/:pathMatch(.*)*',
      redirect: () => `/`,
    },
  ],
})

router.beforeEach(async (_to, _from, next) => {
  const appStore = new AppStore()

  // Wait for the auth state so beforeEnter can be used to load data

  await appStore.auth()

  next()
})

export default router
