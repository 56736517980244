import { Pinia, Store } from 'pinia-class-component'

type Dashboard = { dashboardId: string; dashboardName: string; usedState?: boolean }

@Store
export class DashboardStore extends Pinia {
  public url = ''

  public loading = false

  public dashboards: Dashboard[] = []

  public async listDashboards() {
    this.loading = true

    const response = await this.$axios.get('/api/v1/embedded-dashboards')

    const dashboards = response?.data?.dashboards.map((db: any) => {
      return { ...db, usedState: false }
    })

    this.dashboards = dashboards || []

    this.loading = false
  }

  public async getDashboardUrl(dashboardId: string) {
    this.loading = true

    let id = ''

    this.url = ''

    const dashboard: any = this.dashboards.find((db: any) => db.dashboardId === dashboardId)

    if (dashboard?.usedState) {
      await this.listDashboards()

      id = this.dashboards.find((db: any) => db.dashboardName === dashboard.dashboardName)?.dashboardId || ''
    } else {
      const dashboards = this.dashboards.map((db: any) => {
        if (db.dashboardId === dashboardId) {
          db.usedState = true
        }

        return db
      })

      this.dashboards = dashboards

      id = dashboardId
    }

    const response = await this.$axios.get(`/api/v1/embedded-dashboards/url?dashboardId=${id}`)

    this.url = response.data?.url || ''

    this.loading = false

    return response
  }
}
