export const hwTypes = [
  {
    title: 'Gen 2',
    value: 'gen2',
  },
  {
    title: 'Gen 2M',
    value: 'gen2m',
  },
  {
    title: 'Gen 3',
    value: 'gen2x',
  },
  {
    title: 'Gen 4 Oreo',
    value: 'oreo',
  },
  {
    title: 'Gen 4 Kathrine',
    value: 'kathrine',
  },
]

export const appPlatforms = [
  {
    title: 'Any',
    value: '',
  },
  {
    title: 'Android',
    value: 'android',
  },
  {
    title: 'iOS',
    value: 'ios',
  },
]

export const rolloutStates = [
  {
    title: 'Any',
    value: '',
  },
  {
    title: 'Active',
    value: 'active',
  },
  {
    title: 'Pending',
    value: 'pending',
  },
  {
    title: 'Disabled',
    value: 'disabled',
  },
  {
    title: 'Finished',
    value: 'finished',
  },
]

export const otafileStates = [
  {
    title: 'Any',
    value: '',
  },
  {
    title: 'Verified',
    value: 'verified',
  },
  {
    title: 'Pending',
    value: 'pending',
  },
  {
    title: 'Invalid',
    value: 'invalid',
  },
  {
    title: 'Disabled',
    value: 'disabled',
  },
]

export const appFlavors = [
  {
    title: 'Debug',
    value: 'debug',
  },
  {
    title: 'Staging',
    value: 'staging',
  },
  {
    title: 'Release',
    value: 'release',
  },
  {
    title: 'Research',
    value: 'research',
  },
  {
    title: 'Experimental',
    value: 'experimental',
  },
]

export const createSchedules = [
  {
    title: 'Full rollout 100%',
    value: 'daily-1',
  },
  {
    title: 'Weekly 5% -> 100%',
    value: 'weekly-5:100',
  },
  {
    title: 'Weekly 30% -> 100%',
    value: 'weekly-30:100',
  },
  {
    title: 'Weekly 5% -> 30% -> 100%',
    value: 'weekly-5:30:100',
  },
  {
    title: 'Weekly 5% -> 50% -> 100%',
    value: 'weekly-5:50:100',
  },
  {
    title: 'Full rollout 100% (for 7 days)',
    value: 'daily-100:100:100:100:100:100:100:0',
  },
  {
    title: 'Manual with given percentage',
    value: 'manual',
  },
]

export const internalDomains = [
  {
    title: 'Both internal domains',
    value: '',
  },
  {
    title: 'ouraring.com',
    value: 'ouraring.com',
  },
  {
    title: 'ringtesting.com',
    value: 'ringtesting.com',
  },
]

export const createHeaders: any = [
  {
    key: 'uploaded_at',
    title: 'Uploaded at',
  },
  {
    key: 'version',
    title: 'Version',
  },
  {
    key: 'size',
    title: 'Size',
  },
  {
    key: 'filename',
    title: 'Filename',
    align: 'end',
  },
]

export const detailHeaders: any = [
  {
    key: 'apply_at',
    width: '50%',
    title: 'Date',
  },
  {
    key: 'percentile',
    title: 'Percentile',
  },
]

export const rolloutHeaders: any = [
  {
    key: 'value.version',
    title: 'Version',
    class: 'text-primary text-no-wrap',
  },
  {
    key: 'priority',
    title: 'Priority',
    class: 'text-primary text-no-wrap',
  },
  {
    key: 'filters',
    title: 'Filters',
    class: 'text-primary text-no-wrap',
  },
  {
    key: 'schedule.length',
    title: 'Steps',
    class: 'text-primary text-no-wrap',
  },
  {
    key: 'platform',
    title: 'Platform',
    class: 'text-primary text-no-wrap',
  },
  {
    key: 'created_at',
    title: 'Created',
    class: 'text-primary text-no-wrap',
    cellClass: 'text-no-wrap',
  },
  {
    key: 'state',
    title: 'State',
    class: 'text-primary',
    align: 'end',
  },
  {
    key: 'actions',
    width: 80,
    title: '',
    class: 'text-primary text-no-wrap',
    align: 'end',
    sortable: false,
  },
]

export const otafileHeaders: any = [
  {
    key: 'version',
    title: 'Version',
    class: 'text-primary text-no-wrap',
  },
  {
    key: 'filename',
    title: 'Filename',
    class: 'text-primary text-no-wrap ',
  },
  {
    key: 'size',
    title: 'Size',
    class: 'text-primary text-no-wrap',
  },
  {
    key: 'uploader',
    title: 'Uploader',
    class: 'text-primary text-no-wrap',
    cellClass: 'text-no-wrap',
  },
  {
    key: 'created_at',
    title: 'Created',
    class: 'text-primary text-no-wrap',
    cellClass: 'text-no-wrap',
  },
  {
    key: 'state',
    title: 'State',
    class: 'text-primary text-no-wrap',
    align: 'end',
  },
  {
    key: 'actions',
    width: 80,
    title: '',
    class: 'text-primary text-no-wrap',
    align: 'end',
    sortable: false,
  },
]
