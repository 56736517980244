<template>
  <div class="px-4">
    <v-list v-if="samples && !!samples.length && !showPreview" class="pa-6 pt-0">
      <div class="text-right">
        <v-btn
          variant="text"
          color="primary"
          text="Download all"
          :loading="downloadingFile === 'all'"
          :disabled="downloadingFile === 'all'"
          @click="downloadSamples(samples, 'all')"
        />
      </div>

      <v-list-item
        v-for="(sample, index) in samples"
        :key="index"
        link
        prepend-icon="mdi-eye"
        @click="previewSample(sample)"
      >
        <v-list-item-title>
          {{ sample.file }}
        </v-list-item-title>

        <template #append>
          <div class="mr-n2">
            <v-btn
              color="primary"
              icon="mdi-tray-arrow-down"
              :loading="downloadingFile === index"
              :disabled="downloadingFile === index"
              @click.stop="downloadSamples([sample], index)"
            />
          </div>
        </template>
      </v-list-item>
    </v-list>

    <v-row v-if="showPreview && preview.length" class="align-center text-overline ml-2">
      <v-col cols="2">
        <v-icon color="purple">mdi-filter</v-icon>
        <span class="overline">OS type:</span>
      </v-col>

      <v-col cols="10" class="d-flex">
        <v-spacer />

        <v-radio-group v-model="os" inline style="justify-items: end">
          <v-radio v-if="previewOsType === 'all'" class="mr-2" label="All" value="all" />
          <v-radio v-if="previewOsType === 'ios' || previewOsType === 'all'" class="mr-2" label="IOS" value="ios" />
          <v-radio
            v-if="previewOsType === 'android' || previewOsType === 'all'"
            class="mr-2"
            label="Android"
            value="android"
          />
        </v-radio-group>
      </v-col>
    </v-row>

    <v-list v-if="showPreview" class="px-6">
      <v-list-item v-for="(user, index) in previewFilter" :key="index" link @click="downloadJzlog(user.uuid)">
        <template #prepend>
          <v-icon>{{ user.os === 'android' ? 'mdi-android' : 'mdi-apple' }}</v-icon>
        </template>

        <v-list-item-title>
          {{ user.uuid }}
        </v-list-item-title>

        <template #append>
          <v-icon v-if="rights.includes('allowDataDownloadAccess')">mdi-cloud-download</v-icon>
        </template>
      </v-list-item>
    </v-list>
  </div>
</template>

<script lang="ts">
  import { Component, Model, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { AppStore, TasksStore } from '#stores'

  import { Preview, Sample } from '#types'

  @Component
  class Samples extends Vue {
    @Prop() public active!: string
    @Prop() public source!: string

    @Model() public showPreview!: boolean

    public os = 'all'

    public previewOsType = 'all'

    public previewFilter: Preview[] = []

    public downloadingFile: number | string | null = null

    private readonly appStore = new AppStore()
    private readonly tasksStore = new TasksStore()

    public get samples() {
      return this.tasksStore.samples
    }

    public get rights() {
      return this.appStore.activeRights
    }

    public get preview() {
      return this.tasksStore.preview
    }

    @Watch('os')
    protected osChanged(val: string) {
      this.previewFilter = this.os === 'all' ? this.preview : this.preview.filter((p: Preview) => p.os === val)
    }

    @Watch('preview')
    protected previewChanged(val: Preview[]) {
      this.previewFilter = this.preview

      const iosCount = this.preview.length - val.filter((p: Preview) => p.os === 'ios').length

      this.previewOsType = iosCount === 0 ? 'ios' : iosCount === this.preview.length ? 'android' : 'all'
      this.os = this.previewOsType
    }

    public async downloadSamples(samples: Sample[], index: number | string) {
      let file = ''

      this.downloadingFile = index

      if (samples?.length > 1) {
        const keys = samples.map((sample: Sample) => sample.path)

        const res = await this.tasksStore.getSamplesDownloadUrl({
          keys: keys,
          bucket: 'samples',
          saveAs: this.active + '.zip',
        })

        file = res?.fileUrl || ''
      } else {
        const res = await this.tasksStore.getDownloadUrl(this.source, { bucket: 'samples', prefix: samples[0].path })

        if (res?.fileUrl) {
          const jsonFile = await fetch(res.fileUrl)

          if (jsonFile.status === 200) {
            const json = await jsonFile.json()

            file = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(json))
          }
        }
      }

      if (file) {
        const link = document.createElement('a')

        link.setAttribute('download', samples?.length > 1 ? this.active : this.active + '.json')
        link.style.display = 'none'

        document.body.appendChild(link)

        link.setAttribute('href', file)
        link.setAttribute('target', '_blank')

        link.click()

        document.body.removeChild(link)
      }

      this.downloadingFile = null
    }

    public previewSample(sample: Sample) {
      this.tasksStore.previewSample(this.source, sample)

      this.showPreview = true
    }

    public downloadJzlog(uuid: string) {
      if (this.rights.includes('allowDataDownloadAccess')) {
        this.tasksStore
          .getFilesList(this.source, { bucket: 'ring-stream', prefix: `stream-full/${uuid}/JZLOG` })
          .then((files) => {
            this.tasksStore
              .getDownloadUrl(this.source, {
                bucket: 'ring-stream',
                prefix: files[0].key,
                saveAs: `${uuid}.jzlog`,
              })
              .then((file) => {
                if (file?.fileUrl) {
                  window.open(file.fileUrl)
                }
              })
          })
      }
    }
  }
  export default toNative(Samples)
</script>
