<template>
  <v-dialog v-if="open" v-model="open" persistent width="1000">
    <v-card>
      <v-card-title>Set auto schedule</v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              v-model="selectedFile"
              autofocus
              label="Rdata configuration file"
              :items="fileItems.filter((f) => team.studyFiles.find((sf: any) => sf.id === f.value))"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="scheduleTime"
              label="Schedule start time"
              placeholder="Schedule start time (hh:mm)"
              prepend-icon="mdi-clock-time-four-outline"
              :rules="[
                (value: string) =>
                  value?.split(':').filter((v: any) => !isNaN(v)).length === 2 ||
                  'Invalid time format, needs to be hh:mm',
              ]"
            />
          </v-col>

          <v-col cols="6">
            <v-text-field v-model.number="recordingTime" type="number" label="Recording time in hours" />
          </v-col>
        </v-row>

        <v-alert v-if="!recordingTime" type="error" class="my-6">
          No recording time set, recording will continue until memory runs out in the ring which might mean that new
          daily recording is not started at all, i.e. some days might be skipped
        </v-alert>

        <v-alert v-else-if="recordingTime > 15" type="warning" class="my-6">
          Long recording time probably causes recording to not stop before next daily recording should be started since
          stopping the recording currently requires user to open the app
        </v-alert>

        <v-alert v-else type="info" class="my-6">
          It is possible that some daily recordings are skipped since app needs to be opened by the user after recording
          time for it to stop, i.e. this is currently app controlled
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn text="Cancel" class="mr-2" @click="cancel()" />

        <v-btn text="Set" color="primary" :disabled="!selectedFile || !scheduleTime" @click="confirm()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { RdataStore } from '#stores'

  @Component
  class SetupSchedule extends Vue {
    @Prop() public open!: boolean

    @Prop() public team!: any

    @Emit('cancel')
    public cancel() {
      return null
    }

    @Emit('confirm')
    public confirm() {
      return { file: this.selectedFile, time: this.scheduleTime, duration: this.recordingTime }
    }

    public timeMenu = false

    public selectedFile = ''

    public scheduleTime: string | null = null
    public recordingTime: number | null = null

    private readonly rdataStore = new RdataStore()

    public get fileItems() {
      return this.rdataStore.files.map((f) => ({
        title: [f.name, this.getFileConfiguration(f), f.data.hardware_type.toUpperCase()].join(' - '),
        value: f.id,
      }))
    }

    public getFileConfiguration(file: any) {
      const slots: any[] = []

      const schema = JSON.parse(file.schema)

      const slotSchema = schema.definitions.measurement_slot_id
      const dataSchema = schema.definitions.measurement_channel_description_id

      for (const type of ['PPG', 'ACM', 'Gyro', 'Temperature']) {
        const count = file.data.data_collections[0].measurements.filter(
          (m: any) =>
            !!m.parameters.find(
              (p: any) =>
                p.length === slotSchema?.properties?.length.default &&
                p.class_id === slotSchema?.properties?.class_id.default &&
                p.param_id === slotSchema?.properties?.param_id.default,
            ) &&
            !!m.parameters.find(
              (p: any) =>
                p.length === dataSchema?.properties?.length.default &&
                p.class_id === dataSchema?.properties?.class_id.default &&
                p.param_id === dataSchema?.properties?.param_id.default &&
                dataSchema?.properties?.value?.oneOf
                  .filter((i: any) => i.title.startsWith(`${type}: `))
                  .map((i: any) => i.const)
                  .includes(p.value),
            ),
        ).length

        slots.push(`${count}x${type}`)
      }

      return slots.join(', ')
    }
  }

  export default toNative(SetupSchedule)
</script>
