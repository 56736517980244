<template>
  <v-dialog v-model="open" width="600" @click:outside="closeDialog()">
    <v-card>
      <v-card-title>Create new protocol file</v-card-title>

      <v-card-text>
        <v-text-field v-model.trim="protoName" autofocus label="Protocol name" :rules="[validateProtoName]" />
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn text="Cancel" class="mr-2" @click="closeDialog()" />

        <v-btn text="Create" color="primary" :disabled="!protoName || !hasUniqueName" @click="create()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { snakeCase } from 'lodash-es'

  import { Component, Emit, Model, Vue, toNative } from 'vue-facing-decorator'

  import { ProtoStore } from '#stores'

  import { ProtoFile } from '#types'

  @Component
  class CreateStudy extends Vue {
    @Model() public open!: boolean

    @Emit('create')
    public create() {
      return {
        name: this.protoName,
      }
    }

    public protoName = ''

    public snakeCase = snakeCase

    private readonly protoStore = new ProtoStore()

    public get protos() {
      return this.protoStore.files
    }

    public get hasUniqueName() {
      return !this.protos.some(
        (file: ProtoFile) =>
          file.id == snakeCase(this.protoName) || file.name.toLowerCase() === this.protoName.toLowerCase(),
      )
    }

    public closeDialog() {
      // empty the name field when the dialog is closed
      this.protoName = ''

      // close the dialog
      this.open = false
    }

    public validateProtoName(): string | boolean {
      return this.hasUniqueName || 'Protocol name already exists'
    }
  }

  export default toNative(CreateStudy)
</script>
