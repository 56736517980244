<template>
  <v-dialog v-if="open" v-model="open" persistent width="800">
    <v-card>
      <v-card-title>Add config files</v-card-title>

      <v-card-text>
        <v-autocomplete
          v-model="selectedFiles"
          chips
          multiple
          autofocus
          label="Rdata configuration files to add"
          :items="rdataFiles"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn text="Cancel" class="mr-2" @click="cancel()" />

        <v-btn text="Add" color="primary" :disabled="!selectedFiles.length" @click="confirm()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { RdataStore } from '#stores'

  import { RdataFile, StudyFile } from '#types'

  @Component
  class SelectConfigs extends Vue {
    @Prop() public open!: boolean

    @Prop() public team!: any

    @Emit('cancel')
    public cancel() {
      this.selectedFiles = []

      return null
    }

    @Emit('confirm')
    public confirm() {
      const files = this.selectedFiles

      this.selectedFiles = []

      return files
    }

    public selectedFiles: any[] = []

    private readonly rdataStore = new RdataStore()

    public get rdataFiles() {
      const studyFileIds = new Set(this.team.studyFiles.filter((studyFile: StudyFile) => studyFile.id))

      return this.rdataStore.files
        .filter((f: RdataFile) => !studyFileIds.has(f.id))
        .map((f: RdataFile) => ({
          title: [f.name, this.getFileConfiguration(f), f.data.hardware_type.toUpperCase()].join(' - '),
          value: f.id,
        }))
    }

    private getFileConfiguration(file: any) {
      const slots: any[] = []

      const schema = JSON.parse(file.schema)

      const slotSchema = schema.definitions.measurement_slot_id
      const dataSchema = schema.definitions.measurement_channel_description_id

      for (const type of ['PPG', 'ACM', 'Gyro', 'Temperature']) {
        const count = file.data.data_collections[0].measurements.filter(
          (m: any) =>
            !!m.parameters.find(
              (p: any) =>
                p.length === slotSchema?.properties?.length.default &&
                p.class_id === slotSchema?.properties?.class_id.default &&
                p.param_id === slotSchema?.properties?.param_id.default,
            ) &&
            !!m.parameters.find(
              (p: any) =>
                p.length === dataSchema?.properties?.length.default &&
                p.class_id === dataSchema?.properties?.class_id.default &&
                p.param_id === dataSchema?.properties?.param_id.default &&
                dataSchema?.properties?.value?.oneOf
                  .filter((i: any) => i.title.startsWith(`${type}: `))
                  .map((i: any) => i.const)
                  .includes(p.value),
            ),
        ).length

        slots.push(`${count}x${type}`)
      }

      return slots.join(', ')
    }
  }

  export default toNative(SelectConfigs)
</script>
