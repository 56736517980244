<template>
  <div :class="edit ? 'px-8' : ''">
    <v-row v-if="data" align="center">
      <v-col v-if="!fw" :cols="edit ? '4' : '3'">
        <v-combobox v-model="data.rule" label="Rule" :items="ruleEvents" />
      </v-col>

      <v-col v-if="!fw" cols="3">
        <v-select v-model="data.condition" label="Condition" :items="ruleConditions" />
      </v-col>

      <v-col v-if="!fw" cols="2">
        <v-text-field v-model.number="data.value" type="number" label="Per night value" />
      </v-col>

      <v-col v-if="fw" :cols="!edit ? '6' : '7'">
        <v-text-field
          v-model.trim="data.condition"
          label="Firmware versions"
          placeholder="Firmware versions (max 5)..."
        />
      </v-col>

      <v-col :cols="!fw ? '3' : '5'">
        <v-select v-model="data.action" label="Alert status" :items="ruleStatuses" />
      </v-col>

      <v-col v-if="!edit" cols="1">
        <v-btn
          class="mb-5 ml-n2"
          icon="mdi-plus-thick"
          color="primary"
          variant="text"
          :disabled="!isConfigurationValid"
          @click="save()"
        />
      </v-col>
    </v-row>
  </div>

  <v-card-actions v-if="edit">
    <v-spacer />

    <v-btn v-if="edit" text="Cancel" class="mr-2" @click="close()" />

    <v-btn v-if="edit" text="Save" color="primary" :disabled="!isConfigurationValid" @click="save()" />
  </v-card-actions>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { ruleCondition, ruleEvents, ruleStatus } from '#views/setup/constants'

  import { ScheduleAlertRule } from '#types'

  @Component
  class EditRule extends Vue {
    @Prop() public fw!: boolean
    @Prop() public edit!: boolean

    @Prop() public rule!: ScheduleAlertRule

    public data: ScheduleAlertRule | null = {
      rule: '',
      condition: '',
      value: 0,
      action: 'slack_alert',
    }

    public readonly ruleEvents = ruleEvents
    public readonly ruleStatuses = ruleStatus
    public readonly ruleConditions = ruleCondition

    public get isConfigurationValid() {
      if (!this.fw && this.data?.condition && this.data?.value) {
        return true
      } else if (this.fw && this.data?.condition && this.data?.action) {
        return true
      } else {
        return false
      }
    }

    @Emit('close')
    public close() {
      return null
    }

    @Emit('addRule')
    public addRule(rule: ScheduleAlertRule) {
      return rule
    }

    @Emit('changeRule')
    public changeRule(rule: ScheduleAlertRule) {
      return rule
    }

    @Watch('rule', { immediate: true })
    protected ruleChanged() {
      if (this.rule?.rule) {
        this.data = this.rule
      }
    }

    public save() {
      if (!this.edit) {
        this.addRule({
          rule: this.formatRule(),
          action: this.data!.action,
        })
      } else {
        this.changeRule({
          rule: this.formatRule(),
          action: this.data!.action,
        })
      }
    }

    private formatRule() {
      return this.fw
        ? `fw == '${this.data?.condition}'`
        : `event_name == '${this.data?.rule}' and count_per_night ${this.data?.condition} ${this.data?.value}`
    }
  }

  export default toNative(EditRule)
</script>
