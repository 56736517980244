<template>
  <v-dialog :model-value="open" width="600" @update:model-value="close()">
    <v-card>
      <v-card-title>Create new study group</v-card-title>

      <v-alert v-if="createLabelError" type="error" closable>{{ createLabelError }}</v-alert>

      <v-card-text>
        <v-text-field v-model.trim="studyName" autofocus label="Study name" :rules="[validateStudyName]" />

        <v-row>
          <v-col>
            <v-select v-model="appFlavor" label="App flavor" :items="appFlavors" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn text="Use Oura Teams" color="primary" :disabled="true" />

        <v-spacer />

        <v-btn text="Cancel" class="mr-2" @click="close()" />

        <v-btn text="Create" color="primary" :disabled="isLoading || !studyName || !hasUniqueName" @click="create()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { kebabCase } from 'lodash-es'

  import { Component, Emit, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { appFlavors } from '#views/rdata/constants'

  import { TeamsStore } from '#stores'

  import { Study } from '#types/common'

  @Component
  class CreateStudy extends Vue {
    @Prop() public open!: boolean

    @Prop() public createLabelError!: string

    @Emit('create')
    public create() {
      return {
        studyName: this.studyName,
        appFlavor: this.appFlavor,
      }
    }

    @Emit('close')
    public close() {
      this.studyName = ''
    }

    public studyName = ''

    public appFlavor = 'experimental'

    public readonly kebabCase = kebabCase

    public readonly appFlavors = appFlavors

    protected readonly teamsStore = new TeamsStore()

    public get isLoading() {
      return this.teamsStore.loading
    }

    public get studyGroups() {
      return this.teamsStore.teams
    }

    public get hasUniqueName() {
      return !this.studyGroups.some(
        (study: Study) =>
          study.id == kebabCase(this.studyName) || study.name.toLowerCase() === this.studyName.toLowerCase(),
      )
    }

    public validateStudyName(): string | boolean {
      return this.hasUniqueName || 'Study name already exists'
    }
  }

  export default toNative(CreateStudy)
</script>
